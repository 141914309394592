import React from 'react';


const NewTag = () => {
    return (
        <span className="newLink">
        New
        </span>
    );
};

const BetaTag = () => {
    return (
        <span className="newLink">
        Beta
        </span>
    );
};

export { NewTag, BetaTag };
