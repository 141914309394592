/* eslint-disable no-restricted-globals */
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import React, { useRef, useState } from "react";
import RecoveredStatus from "../components/RecoveredStatus";
import Text from "../components/TextArea";
import { useEffect } from "react";
import {
	AbandonedCheckoutSVG,
	RecoveredSaleSVG,
	ClickedLinkSVG,
} from "resources/Icons/Inbox/Index";
import { txtCartFetch } from "utilifyFunctions";
import SecondaryButton from "components/Buttons/SecondaryButton";
import { motion, AnimatePresence } from "framer-motion";

/**
 * ChatSection - A component to render the chat section in the Inbox template.
 * It displays the chat messages, allows the user to input new messages, and
 * provides a button to override the AI conversation.
 *
 * Props:
 * - activeTab: The active tab in the Inbox template.
 * - isActive: The active chat in the Inbox template.
 * - setIsActive: A function to set the active chat.
 *
 * State:
 * - messages: An array of messages in the chat.
 * - inputHeight: The height of the input box.
 * - text: The text input by the user.
 *
 * Effects:
 * - Fetches the messages when the component mounts and when the active chat changes.
 * - Sets the input height based on the height of the input box.
 * - Scrolls the chat container to the bottom when the input box height changes.
 *
 * Components:
 * - Header: A component to render the header of the chat section.
 * - ActionCard: A component to render an action card in the chat.
 * - MessageBox: A component to render a message box in the chat.
 * - ReplyBox: A component to render a reply box in the chat.
 * - Text: A component to render the text input box.
 */
export default function ChatSection({ activeTab, isActive, setIsActive, setInboxContacts, setConversationFilteredCount }) {
	const [messages, setMessages] = useState([]);
	const [refreshChat, setRefreshChat] = useState(false);
	const [key, setKey] = useState(0);
    const [isClosing, setIsClosing] = useState(false);
	const chatEndRef = useRef(null);
	const [inputHeight, setInputHeight] = useState(0);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [text, setText] = useState("");
	const ref = useRef(null);
	const chatContainerRef = useRef(null);

	useEffect(() => {
		if (messages.length > 0) {
			scrollToBottom();
		}
	}, [messages]);

	const handleCloseConversation = async () => {
		setIsClosing(true);
		setKey(isActive?.id)
		let requestBody = {
			chatId: isActive?.id,
			type: isActive?.type
		}
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/inbox/conversation/close", requestBody, true);
		if(response){
			setIsActive({ ...isActive, closed: response?.success});
			setInboxContacts(prevContacts =>  
				prevContacts.map(contact => 
					contact.id === isActive?.id 
						? { ...contact, closed: true } 
						: contact
				)
			);
			setConversationFilteredCount(prevCount => ({
				...prevCount,
				open: prevCount.open - 1,
				closed: prevCount.closed + 1,
			}));
			setTimeout(() => setIsClosing(false), 1000);
		}
	}

	const scrollToBottom = () => {
		chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const handleGetMessages = async function(chatId){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/inbox/messages/"+isActive?.type+`/`+chatId);
		let messages = []
		if(response && Array.isArray(response?.data)) {
			messages = response?.data
			if (response?.unsubscribed !== isActive?.unsubscribed) {
				setIsActive((prevState) => ({
					...prevState,
					unsubscribed: response?.unsubscribed,
				}));
			}
		}
		return messages;
	}

	const markMessagesAsRead = async (messageIndices) => {
		if (messageIndices?.length > 0) {
			try {
				let requestBody = {
					messageIndices: messageIndices,
					chatId: isActive?.id,
					type: isActive?.type
				};
				await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/inbox/conversation/read", requestBody, true);
			} catch (error) {
				console.error("Failed to mark messages as read:", error);
			}
		}
	};

	const fetchMessages = async () => {
		setMessages([]);
		const fetchedMessages = await handleGetMessages(isActive?.id);
		setTimeout(() => {
			setMessages(fetchedMessages);
			const unreadMessageIndices = fetchedMessages.map((message, index) => (!message.read ? index : null)).filter(index => index !== null && index !== undefined);
			markMessagesAsRead(unreadMessageIndices);
			setText("");
		}, 1000)
	};

	useEffect(() => {
		if (isActive?.id) {	
			fetchMessages();
		}
	}, [isActive?.id]);

	useEffect(() => {
		if (refreshChat) {
			fetchMessages();
			setRefreshChat(false);
		}
	}, [refreshChat]);

	const handleOverrideConversation = async function(){
		let requestBody = {
			chatId: isActive?.id,
			type: isActive?.type
		}
		fetchMessages();
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/inbox/conversation/override", requestBody, true);
		if(response){
			setIsActive({ ...isActive, override: response?.success});
			setConversationFilteredCount(prevCount => ({
				...prevCount,
				overridden: prevCount.overridden + 1,
			}));
		}
	}

	useEffect(() => {
		if (ref?.current?.clientHeight) {
			setInputHeight(ref.current.clientHeight - 110);
			if (chatContainerRef.current) {
				chatContainerRef.current.scrollTop =
					chatContainerRef.current.scrollHeight;
			}
		}
	}, [text]);

	return (
		<Box
			width={isMobile ? "100%" : "50%"}
			position={"relative"}
			overflow={"hidden"}
			display={isMobile ? (activeTab === "Chat" ? "flex" : "none") : "flex"}
			bgcolor={theme?.palette?.card?.background}
			flexDirection={"column"}
		>
			{!isMobile && <Header isActive={isActive} setIsActive={setIsActive} setInboxContacts={setInboxContacts} setConversationFilteredCount={setConversationFilteredCount} handleCloseConversation={handleCloseConversation} />}
			<Box
				ref={chatContainerRef}
				flex={"1"}
				width={"100%"}
				p={"0 24px"}
				marginTop={"16px"}
				sx={{
					// display:'flex',
					// flexDirection:'column',
					// justifyContent:'end',
					overflow: "hidden",
					overflowY: "scroll",
					maxHeight: `calc(100vh - ${
						isMobile ? "200px" : "240px"
					} - ${inputHeight}px - 50px)`,
					minHeight: `calc(100vh - ${
						isMobile ? "200px" : "240px"
					} - ${inputHeight}px - 50px)`,
				}}
				className="no-scrollbar"
			>
				<Box display={"flex"} flexDirection={"column"}>
					<AnimatePresence>
						{messages?.map((item, index) => {
						return (
							<motion.div
							key={`message-${index}-${key}`}
							layout
							initial={isClosing ? { opacity: 0, y: 20 }: null}
							animate={isClosing ? { opacity: 1, y: 0 }: null}
							exit={isClosing ? { opacity: 0, scale: 0.8 }: null}
							transition={isClosing? { duration: 1 }: null}>
							{item?.type === "action" ? (
								<ActionCard
								nextAction={
									messages[index + 1]?.type === "message" &&
									messages[index + 1]?.sentBy === "user"
									? true
									: false
								}
								action={item?.action}
								/>
							) : item?.sentBy === "user" ? (
								<MessageBox
								isActive={isActive}
								number={index}
								content={item?.content}
								sentAt={item?.sentAt}
								isLast={messages[index + 1]?.sentBy === "txtCart"}
								showUserLogo={messages[index + 1]?.sentBy !== "user"}
								/>
							) : (
								<ReplyBox
								content={item?.content}
								sentAt={item?.sentAt}
								isLast={messages[index + 1]?.sentBy === "user"}
								showUserLogo={messages[index + 1]?.sentBy !== "txtCart"}
								/>
							)}
							</motion.div>
						);
						})}
						{/* Ref to the bottom of the chat */}
						<div ref={chatEndRef} />
					</AnimatePresence>
				</Box>
			</Box>
			<Box
				id="input-box"
				width={isMobile ? "100%" : "94%"}
				m={isMobile ? "0" : "0 24px"}
				position={isMobile ? "static" : "absolute"}
				ref={ref}
				bottom={"20px"}
			>
				{messages?.length > 0 && <Box position={"relative"}>
				{(!isActive?.override || isActive?.unsubscribed) && (
					<Box display={"grid"} justifyContent={"center"}
						sx={{
							position: "absolute",
							zIndex: "800",
							width: "100%",
							height: "100%",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							background: 'rgba(237, 239, 245, 0.74)',
							alignContent: "center",
						}}
					>
						{/* Display messages based on unsubscribed status */}
						{(isActive?.unsubscribed) ? (
							// Show message if the conversation is unsubscribed
							<Typography
								sx={{
									textAlign: "center",
									fontSize: "14px",
									maxWidth: "521px",
									fontWeight: "400",
									lineHeight: "20px",
									mb: "15px",
									color: "#000D31",
								}}
							>
								{isActive?.unsubscribed ? 
								'This customer is unsubscribed from SMS Marketing. You cannot reply or text this customer.' : 
								'You cannot reply to this conversation. '}
							</Typography>
						) : (
							// Show override message only if override is false
							(!isActive?.override && (
								<Typography justifyContent={"center"}
									sx={{
										textAlign: "center",
										maxWidth: "521px",
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "20px",
										mb: "15px",
										color: "#000D31",
										margin: "0px 0px 15px",
									}}
								>
									Want to add the human touch? You can take over the conversation
									by overriding our AI. Note: AI will no longer respond once overridden.
								</Typography>
							))
						)}

						{/* Show the "Override Conversation" button only if conversation is not unsubscribed and override is false */}
						{!isActive?.unsubscribed && !isActive?.override && (
							<Box display={"flex"} justifyContent={"center"}>
								<SecondaryButton
									onClick={() => {
										handleOverrideConversation();
									}}
								>
									Override Conversation
								</SecondaryButton>
							</Box>
						)}
					</Box>
				)}

				{/* Content that should be blurred or disabled when not overridden */}
				<Box
					sx={{
						filter: (isActive?.unsubscribed || !isActive?.override) ? "blur(2px)" : "none",
						pointerEvents: (isActive?.unsubscribed || !isActive?.override) ? "none" : "auto",
					}}
				>
					<Text
						setText={setText}
						isActive={isActive}
						disabled={isActive?.unsubscribed}
						setRefreshChat={setRefreshChat}
						setMessages={setMessages}
					/>
				</Box> 
				</Box>
				}
			</Box>
		</Box>
	);
}

const Header = ({ isActive, setIsActive, setInboxContacts, setConversationFilteredCount, handleCloseConversation }) => {

	const theme = useTheme();
	return (
		<Box
			bgcolor={theme?.palette?.card?.background}
			p={"9px"}
			display={"flex"}
			justifyContent={"space-between"}
			borderBottom={`1px solid ${theme?.palette?.card?.border}`}
			alignItems={"center"}
		>
			<Box display={"flex"} gap={"16px"}>
				<Typography
					fontWeight={600}
					fontSize={20}
					color={theme?.palette?.pageHeading}
					lineHeight={"normal"}
					ml={"7px"}
				>
					{isActive?.name}
				</Typography>
				{isActive?.isRecovered ? <RecoveredStatus />: <></>}
			</Box>
			{(isActive?.override && !isActive?.closed) && (
				<Box>
					<PrimaryButton onClick={() => { handleCloseConversation(); }}>Close Conversation</PrimaryButton>
				</Box>
			)}
			{(isActive?.override && isActive?.closed) && (
				<span class="conversation_status Closed"
					
				>
					Closed conversation
				</span>
			)}
		</Box>
	);
};

const MessageBox = ({
	content,
	sentAt,
	isLast,
	showUserLogo,
	isActive,
	number,
	isRead,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	let userInitial = isActive?.name ? isActive?.name[0] : "U";
	useEffect(() => {
		
	},[isActive])
	const messageWidth = isMobile ? "100%" : "80%";
	const dynamicWidth = content.length < 38 ? "fit-content" : messageWidth;
	return (
		<Box
			display={"flex"}
			gap={"8px"}
			mb={isLast ? "24px" : "6px"}
			mt={number === 0 && "20px"}
		>
			<Box display={"flex"} alignItems={"end"}>
				<Box
					sx={{
						background: "rgb(231, 243, 255)",
						borderRadius: "50%",
						width: "32px",
						height: "32px",
						border: "1px solid rgba(0, 129, 255, 0.15)",
						visibility: showUserLogo ? "visible" : "hidden",
						marginRight: showUserLogo ? "0px" : "2px",
					}}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					webkitBoxPack={"center"}
					webkitBoxAlign={"center"}
				>
					<Typography color={"#0081FF"} fontSize={14} fontWeight={600} lineHeight={"normal"}>
						{userInitial}
					</Typography>
					</Box>
				</Box>
				<Box display={"flex"} justifyContent={"flex-start"}>
					<Box
					p={"16px"}
					sx={{
						background: lightMode ? "rgba(0, 13, 49, 0.05)" : "#1E202D",
						borderRadius: "12px 12px 12px 0px",
					}}
					maxWidth={messageWidth}
					minWidth={dynamicWidth}
					>
					<Box mb={"4px"}>
						<Typography
							color={theme?.pageHeading?.pageHeading}
							fontSize={14}
							fontWeight={400}
							lineHeight={"20px"}
							sx={{
								whiteSpace: "pre-wrap",
								wordWrap: "break-word",
							}}
						>
							{content}
						</Typography>
					</Box>
					<Box
						display={"flex"}
						justifyContent={"end"}
						sx={{
							maxWidth: "100%",
						}}
					>
					{/* {isRead && ( <Box display={"flex"} alignItems={"center"} mr={"4px"}>
							<DoneAllIcon fontSize="small" sx={{ color: "green" }} />
							</Box>
						)} */}
						{/* {sentAt && <Typography
							color={"#808698"}
							fontSize={14}
							fontWeight={500}
							lineHeight={"16px"}
						>
							{formatTimeFromHours(sentAt)}
						</Typography>} */}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
const ReplyBox = ({ content, sentAt, isLast, showUserLogo }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const messageWidth = isMobile ? "100%" : "80%";
	const dynamicWidth = content.length < 38 ? "fit-content" : messageWidth;
	const lightMode = mode === "light" ? true : false;
	return (
		<Box
			display={"flex"}
			gap={"8px"}
			mb={isLast ? "24px" : "6px"}
			justifyContent={"end"}
		>
			<Box display={"flex"} justifyContent={"end"}>
				<Box
					p={"16px"}
					sx={{
						background: lightMode
							? "rgba(12, 132, 254, 0.10)"
							: "rgba(12, 132, 254, 0.10)",
						borderRadius: "12px 12px 0px 12px",
						boxSizing: "initial",
					}}
					maxWidth={messageWidth}
					minWidth={dynamicWidth}
				>
					<Box mb={"4px"}>
						<Typography
							color={theme?.pageHeading?.pageHeading}
							fontSize={14}
							fontWeight={400}
							lineHeight={"20px"}
							sx={{
								whiteSpace: "pre-wrap",
								wordWrap: "break-word",
								overflowWrap: "break-word",
							}}
						>
							{content}
						</Typography>
					</Box>
					{/* {sentAt && <Box display={"flex"} justifyContent={"end"}>
						<Typography
							color={"#808698"}
							fontSize={14}
							fontWeight={500}
							lineHeight={"16px"}
						>
							Sent: {formatTimeFromHours(sentAt)}
						</Typography>
					</Box>} */}
				</Box>
			</Box>
			<Box display={"flex"} alignItems={"end"}>
				<Box
					sx={{
						background: "#E7F3FF",
						borderRadius: "32px",
						width: "32px",
						height: "32px",
						border: " 1px solid rgba(0, 129, 255, 0.15)",
						visibility: showUserLogo ? "visible" : "hidden",
                    }}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
				>
					<img src="/icons/txt-cart-chat-icon.svg" alt="Icon" />
				</Box>
			</Box>
		</Box>
	);
};

const ActionCard = ({ action, nextAction }) => {
	const imgMap = {
		"Abandoned Checkout": (
			<Box mr={"14px"}>
				{" "}
				<AbandonedCheckoutSVG />
			</Box>
		),
		"Clicked Link": (
			<Box mr={"11px"}>
				<ClickedLinkSVG />
			</Box>
		),
		"Recovered Sale": (
			<Box mr={"8px"}>
				<RecoveredSaleSVG />
			</Box>
		),
	};
	const titleColorMap = {
		"Abandoned Checkout": "#FF465C",
		"Clicked Link": "#0081FF",
		"Recovered Sale": "#00BE5B",
	};

	return (
		<Box
			display={"flex"}
			// gap={"14px"}
			alignItems={"end"}
			mb={nextAction ? "14px":'8px'}
			ml={"40px"}
			mt="23px"
		>
			{imgMap[action]}
			<Box>
				<Typography
					fontSize={14}
					fontWeight={600}
					lineHeight={"normal"}
					mb={"4px"}
					color={titleColorMap[action]}
				>
					{action}
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					lineHeight={"16px"}
					color={"#808698"}
				>
					Dec 11, 2023 11:53am
				</Typography>
			</Box>
		</Box>
	);
};
