/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { alpha, useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Container from "../../components/Container";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/Sidebar";
import { pages } from "../../resources/constant";
import { Grid, Paper } from "@mui/material";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/Banner";
import Billing from "../../components/Billing";
import Loading from "../../components/Loading";
import { DataContext } from "../../context/DataContext";
import LockedModal from "../../components/Modal/LockedModal";
import Onboarding from "../../components/Onboarding";
import VideoModal from "../../components/VideoModal";
import Loader from "components/Loading";
import Confetti from "react-confetti";
import ConfettiExplosion from "react-confetti-explosion";
import { txtCartFetch } from "utilifyFunctions";
import WarningBanner from "components/Banner/WarningBanner";
import AlertBanner from "components/Banner/AlertBanner";
import { useIntercom } from 'react-use-intercom';
import { BUTTONS } from "constants/mixPanelEvents";

export default function Layout() {
	const navigate = useNavigate();
	const [passwordEnabledBanner, setPasswordEnabledBanner] = useState(false);
	const [consentDisabledBanner, setConsentDisabledBanner] = useState(false);
	const [failedTFNBanner, setFailedTFNBanneer] = useState(false);
	const [rejectedTFNBanner, setRejectedTFNBanner] = useState(false);
	const [pendingTFNBanner, setPendingTFNBanner] = useState(false);
	const [freePlanBanner, setFreePlanBanner] = useState(false);
	const [legacyUKBanner, setLegacyUKBanner] = useState(false);
	const [rejectedUKBanner, setRejectedUKBanner] = useState(false);
	const [showBanner, setShowBanner] = useState(false);
	const [currentSKU, setCurrentSKU] = useState({});
	const [currentCycle,setCurrentCycle] = useState("");
	const { showMessages } = useIntercom(); 
	const { showNewMessage } = useIntercom();
	const [unreadMessages, setUnreadMessages] = useState(0);

	const {
		showLoading,
		setShowLoading,
		showVideoModal,
		setShowVideoModal,
		setShowBillingModal,
		showBillingModal,
	} = useContext(DataContext);
	const [isPageLocked, setIsPageLocked] = useState(false);

	const getNotificationCount = async () => {
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/inbox/notification/count");
		setUnreadMessages(response?.count)
	}

	const updatedPages = pages.map((page) => {
		if (page.title === "Inbox") {
			return {
			...page,
			unreadMessages: unreadMessages > 0 ? unreadMessages : 0,
			};
		}
		return page;
	});	  

	async function getPlan() {
		const planResponse = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/plan");
		if(planResponse){
			setCurrentSKU(planResponse.sku);
			setCurrentCycle(planResponse.activeCycle);
			return planResponse; 
		}
	}

	async function getCompliance(){
		const complianceResponse = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/compliance");
		console.log(complianceResponse);
		return complianceResponse; 
	}

	function checkHomePageBanners(link, planType, tfnStatus, tfnFailed, password_enabled, marketing_sms_consent_enabled_at_checkout,isLegacyUKNumber,UK_Verification_Status){
		if(!showLoading && link === "/"){
			if(password_enabled){
				setPasswordEnabledBanner(true);
				setShowBanner(true);
			}
			else if(!marketing_sms_consent_enabled_at_checkout){
				setConsentDisabledBanner(true);
				setShowBanner(true);
			}
			else if(tfnFailed){
				setFailedTFNBanneer(true);
				setShowBanner(true);
			}
			else if(tfnStatus === "PENDING_REVIEW" || tfnStatus === "IN_REVIEW"){
				setPendingTFNBanner(true);
				setShowBanner(true);
			}
			else if(tfnStatus === "TWILIO_REJECTED"){
				setRejectedTFNBanner(true);
				setShowBanner(true);
			}
			else if(isLegacyUKNumber && UK_Verification_Status === 'Disabled'){
				setLegacyUKBanner(true);
				setShowBanner(true);
			}
			else if(UK_Verification_Status === "Rejected"){
				setRejectedUKBanner(true);
				setShowBanner(true);
			}
			else if(planType === "Basic"){
				setFreePlanBanner(planType === "Basic");
				setShowBanner(true);
			}
		} else{
			handleRemoveBanner()
		}
	}

	function handleRemoveBanner(){
		setShowBanner(false);
		setPasswordEnabledBanner(false);
		setConsentDisabledBanner(false);
		setPendingTFNBanner(false);
		setRejectedTFNBanner(false);
		setFailedTFNBanneer(false);
		setFreePlanBanner(false);
		setLegacyUKBanner(false);
		setRejectedUKBanner(false);
	}

	async function handleBanners(link){
		const planResponse = await getPlan();
		const complianceResponse = await getCompliance();
		if(planResponse && complianceResponse){
			checkHomePageBanners(link, 
				planResponse.planType, 
				complianceResponse.TFN_Status, 
				complianceResponse.TFN_Failed, 
				complianceResponse.password_enabled, 
				complianceResponse.marketing_sms_consent_enabled_at_checkout, 
				complianceResponse.isLegacyUKNumber,
				complianceResponse.UK_Verification_Status);
		}
	}

	const theme = useTheme();
	const location = useLocation();
	const [activeLink, setActiveLink] = React.useState(null);
	const isMd = useMediaQuery(theme.breakpoints.up("md"), {
		defaultMatches: true,
	});
	const belowMd = useMediaQuery(theme.breakpoints.down("md"), {
		defaultMatches: true,
	});

	const [openSidebar, setOpenSidebar] = useState(false);

	const handleSidebarOpen = () => {
		setOpenSidebar(true);
	};

	const handleSidebarClose = () => {
		setOpenSidebar(false);
	};
	const { mode } = theme.palette;
	const openSide = isMd ? false : openSidebar;
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const checkIsPageLocked = (link) => {
		// console.info(link);
		const lockedPages = [];
		let isLocked = lockedPages?.indexOf(link);
		// console.log(isLocked, "new", link);
		if (isLocked > -1) {
			setIsPageLocked(true);
			navigate(`${link}-locked`);
		} else {
			setIsPageLocked(false);
		}
	};
	const upTablet = useMediaQuery(theme.breakpoints.up("xl"));

	React.useEffect(() => {
		let link = location && location.pathname ? location.pathname : "";
		setActiveLink(link);
		handleBanners(link);
		checkIsPageLocked(link);
		window.scrollTo(0, 0);
		getNotificationCount();
	}, [location]);

	function openConsentPage(){
		window.open("https://www.help.txtcartapp.com/en/articles/3377200-how-do-i-set-up-my-checkout-for-sms","_blank");
	}

	function contactSupportMissingData(){
		showMessages();
		showNewMessage("My Toll-Free number could not be submitted due to missing data. Can you help?");
	}

	function contactSupportRejected(){
		showMessages();
		showNewMessage("My Toll-Free number was rejected. Can you help?");
	}

	function contactSupportLegacyUK(){
		showMessages();
		showNewMessage("My UK Number requires additional compliance data. How can I submit that?");
	}

	function contactSupportRejectUK(){
		showMessages();
		showNewMessage("My UK number was rejected. Can you help?");
	}

	return (
		<Box
			id="unique-identifier"
			// sx={{
			// 	display: "flex",
			// }}
		>
			<Box bgcolor={theme.palette?.sidebar} id="main-sidebar">
				<Sidebar
					onClose={handleSidebarClose}
					open={openSide}
					variant={isMd ? "permanent" : "temporary"}
					pages={updatedPages}
					isMd={isMd}
					handleOpen={handleOpen}
				/>
			</Box>

			<Box>
				<AppBar
					sx={{
						backgroundColor: mode === "light" ? "#ffff" : "#0F111B",
						borderBottom:
							mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
					}}
					elevation={0}
					id="txtCart-header"
				>
					<Container
						maxWidth={1}
						paddingY={{ xs: 0, sm: 0 }}
						paddingX={{ xs: 0, sm: 0 }}
					>
						<Topbar onSidebarOpen={handleSidebarOpen} />
						{passwordEnabledBanner && (
							<AlertBanner handleRemoveBanner={handleRemoveBanner} topText="Your online store is password protected. Password protected stores cannot be approved by carriers, resulting in delayed or rejected brand verification."/>
						)}
						{consentDisabledBanner && (
							<AlertBanner handleRemoveBanner={handleRemoveBanner} topText="Our system has identified your store has not properly enabled collection of SMS consent. Failure to enable consent can result in delayed or rejected brand verification." buttonText="Update" buttonAction={openConsentPage}/>
						)}
						{failedTFNBanner && (
							<AlertBanner handleRemoveBanner={handleRemoveBanner} 
							topText="[ACTION REQUIRED] Your Toll-Free number (TFN) has failed to submit for verification." 
							buttonText="Retry" 
							bottomText="There was a problem submitting your TFN for verification due to invalid or missing information. Before you can collect subscribers or send texts, carriers require this approval." 
							buttonName={BUTTONS.RETRY_TFN}
							buttonAction={contactSupportMissingData}/>
						)}
						{rejectedTFNBanner && (
							<AlertBanner handleRemoveBanner={handleRemoveBanner}
							topText="[ACTION REQUIRED] Your Toll-Free number (TFN) has been rejected for verification." 
							buttonText="Retry" 
							bottomText="There was a problem submitting your TFN for verification due to invalid or missing information. Before you can collect subscribers or send texts, carriers require this approval." 
							buttonAction={contactSupportRejected}/>
						)}
						{pendingTFNBanner && (
							<WarningBanner 
								handleRemoveBanner={handleRemoveBanner} 
								topText="Your Toll-Free number (TFN) was submitted for verification and is under review." 
								bottomText="Before you can start collecting subscribers or sending texts, US mobile carriers require verification of your TFN. This typically takes 48 hours."
							/>
						)}
						{freePlanBanner && (
							<Banner handleRemoveBanner={handleRemoveBanner} topText="You're currently on the free plan of TxtCart, which is limited to cart recovery" bottomText="Upgrade to unlock Campaigns, Inbox, Campaign Planner, Flows, Pop-ups, Analytics and tons more."/>
						)}
						{legacyUKBanner && (
							<AlertBanner handleRemoveBanner={handleRemoveBanner}
							topText="[ACTION REQUIRED] Your UK Traffic is blocked from sending and receiving SMS Messages" 
							buttonText="Submit" 
							bottomText="Due to recent regulatory changes, you are now required to provide additional information to text subscribers in the UK." 
							buttonAction={contactSupportLegacyUK}/>
						)}
						{rejectedUKBanner && (
							<AlertBanner handleRemoveBanner={handleRemoveBanner}
							topText="[ACTION REQUIRED] Your UK Number has been rejected for verification." 
							buttonText="Retry" 
							bottomText="Before you can start collecting subscribers or sending texts, UK mobile carriers require verification of your of your Mobile Number." 
							buttonAction={contactSupportRejectUK}/>
						)}
					</Container>
				</AppBar>

				<Box
					mt={
						activeLink === "/" ? showLoading === true ? "50px" : isSmall === true ? "230px" : showBanner ? "140px" : "50px" : "50px"
					}
					paddingLeft={{ md: "249px" }}
					// position={"relative"}
					id="Main-container"
					minHeight={isSmall ? "100%" : "80vh"}
					sx={{
						"::-webkit-scrollbar": {
							width: "0 !important",
						},
					}}
				>
					<Loader show={true} />
					{checkLayout(activeLink) ? <WithoutLayout /> : <OtherLayout />}
				</Box>
				<Billing
					open={showBillingModal}
					handleClose={() => setShowBillingModal(false)}
					currentPlan={currentSKU}
					currentCycle={currentCycle}
				/>
				{/* <VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
				/> */}
			</Box>
		</Box>
	);
}

const WithoutLayout = () => {
	return (
		<Box paddingX={{ lg: 0, md: 0, xs: 0 }}>
			<Outlet />
		</Box>
	);
};
const OtherLayout = () => {
	const { showFullPageConteffi } = useContext(DataContext);
	return (
		<Container maxWidth="1398px">
			{showFullPageConteffi && (
				<Box
					width={"100%"}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Confetti
					// particleSize={10}
					// particleCount={500}
					// force={1}
					// width={1000}
					// height={"100vh"}
					// zIndex="9999999"
					/>
				</Box>
			)}
			<Box paddingX={{ lg: 5, md: 8, xs: 0 }}>
				<Outlet />
			</Box>
		</Container>
	);
};


const checkLayout=(route)=>{
	let withoutLayoutPages=['/inbox','/flows']
	let index=withoutLayoutPages?.indexOf(route)
	if(index>-1){
		return true
	}
	return false
}