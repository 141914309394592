import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export default function ShareLinkSection() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box
			sx={{
				borderRadius: "12px",
				padding: "20px 25px",
				border: `1px solid ${theme.palette?.card?.border}`,
				background: theme.palette?.card?.background,
			}}
			mb={"24px"}
		>
			<Box>
				<Box
					mb={"14px"}
					sx={{
						padding: "0px 18px",
						border: `1px solid ${theme.palette?.card?.border}`,
						display: "flex",
						flexDirection: belowSmall ? "column" : "row",
						wordBreak: "break-word",
						justifyContent: "space-between",
					}}
				>
					<Typography
						fontSize={16}
						fontWeight={400}
						color={theme.palette?.card?.color}
						padding={"10px 0px"}
						sx={{
							opacity: "0.5",
						}}
					>
						https://txtcartapp.com/shopify/install?r=UjZLd1pT0Hdam
					</Typography>
					<Box
						sx={{
							borderLeft: belowSmall
								? "none"
								: `1px solid ${theme.palette?.card?.border}`,
							borderTop: belowSmall
								? `1px solid ${theme.palette?.card?.border}`
								: `none`,
							display: "flex",
							gap: "8px",
							padding: "10px 0px 10px 13px",
						}}
						id={"pointerCursor"}
					>
						<img src="/icons/copy.svg" alt="copy" />
						<Typography
							color={mode === "light" ? "#323232" : "rgba(255, 255, 255, 0.50)"}
							fontWeight={600}
						>
							Copy Link
						</Typography>
					</Box>
				</Box>
				<Box>
					<Typography
						fontSize={14}
						fontWeight={500}
						color={theme.palette?.card?.color}
						lineHeight={"20px"}
						sx={{
							opacity: "0.5",
						}}
					>
						Your commission payout is equal to 20% of the plan value your
						referral chooses when signing up for TxtCart.
					</Typography>
					<Typography
						fontSize={14}
						fontWeight={500}
						color={theme.palette?.card?.color}
						lineHeight={"20px"}
						sx={{
							opacity: "0.5",
						}}
					>
						TxtCart reserves the right to chance the terms and conditions of the
						affiliate program.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
