import { Box, Popover, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { TimeSelection } from "resources/constant";

export default function GraphFilter({
    graphRange,
    handleClick,
    anchorEl,
    handleClose,
    handleGraphRange
}) {
    const theme = useTheme();
	const { mode } = theme.palette;
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
	return (
		<React.Fragment>
			<Box
				display={"flex"}
				height={"37px"}
				width={isSmall ? "100%" : "157px"}
				mb={isSmall ? "10px" : "0px"}
				mt={isSmall ? "10px" : "0"}
				id="pointerCursor"
				sx={{
					borderRadius: "5px",
					padding: "10px",
					border: mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
					background: theme.palette?.card?.background,
					position: "relative",
				}}
				onClick={handleClick}
			>
				<Box paddingRight={"5px"}>
					<img src="/icons/calender.svg" width={"80%"} alt="calender"/>
				</Box>
				<Typography
					fontWeight={"500"}
					fontSize={"14px"}
					paddingRight={"10px"}
					display={"flex"}
					alignItems={"center"}
					mt={"3px"}
				>
					{graphRange}
				</Typography>
				<Box
					display={"flex"}
					alignItems={"center"}
					sx={{
						position: "absolute",
						top: "16px",
						right: "14px",
					}}
				>
					<img src="/topbar/down.svg" width={"100%"} alt="down"/>
				</Box>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box
					width={"157px"}
					sx={{
						borderRadius: "5px",
						border:
							mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
						background: theme.palette?.card?.background,
						color: "#808698",
						fontSize: "12px",
					}}
				>
					{TimeSelection?.map((item, index) => {
						return (
							<Typography
								key={index}
								sx={{
									padding: "8px 15px",
									":hover": {
										background: "#cccaca3b",
									},
								}}
								fontSize={"14px"}
								id="pointerCursor"
								onClick={() => handleGraphRange(item?.label)}
							>
								{item?.label}
							</Typography>
						);
					})}
				</Box>
			</Popover>
		</React.Fragment>
	);
}
