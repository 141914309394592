import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Templates from "../Campaigns/components/Templates";
import GraphSection from "../Campaigns/components/GraphSection";
import Analytics from "../Campaigns/components/Analytics";
import CampaignsTable from "../../components/Table/CampaignsTable";
import { campaignsDataMock } from "resources/constant";
import LockedModal from "components/Modal/LockedPage";
import VideoModal from "components/VideoModal";

export default function Campaigns() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const [showVideoModal, setShowVideoModal] = useState(false);

	useEffect(() => {
		document.body.style.overflow = "hidden";
		const elementsToDisableHover = document.querySelectorAll(".disableSection");
		const disableHoverClass = "disable-hover";
		elementsToDisableHover.forEach((element) => {
			element.classList.add(disableHoverClass);
		});
	}, []);
	return (
		<Box>
			<Box
				className="disableSection"
				sx={{
					position: "relative",
					height: "100vh",
					WebkitFilter: "blur(4px)",
					minHeight: "100vh",
					overflowY: "auto",
					px: { xs: 2, sm: 3, md: 4 },
					backgroundColor: theme.palette.background.default,
				}}
			>
				<Grid container spacing={2} alignItems={"center"}>
					
					<Grid item xs={12}>
						<Typography
							fontWeight={"600"}
							fontSize={"20px"}
							lineHeight={"normal"}
							mb={3}
							color={theme.palette?.pageHeading}
						>
							Recommended Templates
						</Typography>
						<Templates />
					</Grid>
					<Grid item xs={12}>
						<GraphSection />
					</Grid>
					<Grid item xs={12}>
						<Analytics />
					</Grid>
					<Grid item xs={12}>
						<CampaignsTable rows={campaignsDataMock} mode={mode} />
					</Grid>
				</Grid>
			</Box>
			<LockedModal
				setShowVideoModal={setShowVideoModal}
				message="Send customized one-time SMS/MMS blasts to your subscriber list and drive more revenue with SMS Campaigns"
				showWatchVideo={true}
				sx={{
					width: { xs: "90%", sm: "70%", md: "50%" },
					margin: "0 auto",
					p: 2,
				}}
				/>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={"https://www.youtube.com/watch?v=sYmTnWyolWU"}
					sx={{
						maxWidth: "100%",
						height: { xs: "80vh", sm: "70vh" },
					}}
				/>
		</Box>
	);
}
