import { Box, Button, Popover, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import CampaignsGraph from "../../../../components/Chart/Campaigns";
import { campaignGraphData } from "resources/constant";
import { TimeSelection } from "resources/constant";
import { useNavigate } from "react-router-dom";
import {
	checkEmptyState,
	updateDataIfEmpty,
} from "resources/helpers/checkEmptyState";
import Select from "components/Select/Select";
import { txtCartFetch } from "utilifyFunctions";
import { parseDateFilter } from "utilifyFunctions";
import { addDays } from "utilifyFunctions";
import { getDateChartTick } from "utilifyFunctions";

export default function GraphSection({handleDateChange, filter}) {
	const navigate = useNavigate();
	const theme = useTheme();
	const [graphData, setGraphData] = useState([]);
	const [textMax, setTextMax] = useState(0);
	const [revenueMax, setRevenueMax] = useState(0);
	const [graphView, setGraphView] = useState("Revenue");
	const [graphTotal, setGraphTotal] = useState({});
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const changeGraphView = (value) => {
		setGraphView(value);
	};

	const getCampaignGraph = async function(dateRange, filter){
		console.log("getCampaignGraph");
		let url = process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/campaign/graph";
		if(dateRange){
			url += dateRange + "&dateFilter="+filter;
		}
		console.log(url);
		const response = await txtCartFetch("GET", url);
		if(response){
			for(let i = 0; i < response.dataPoints.length; i++){
				response.dataPoints[i].date = getDateChartTick(response.dataPoints[i].date,filter);
			}
			console.log(response);
			setGraphData(response.dataPoints);
			setTextMax(response.textMax);
			setRevenueMax(response.revenueMax);
			setGraphTotal(response);
		}
	}

	const getOldestCampaignMessage = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/campaign/oldest");
		return response; 
	}

	const loadData = async function(filter){
		let range; 
		if(filter === "all_time"){
			let oldestCart = await getOldestCampaignMessage();
			filter = checkAllTimeRange(oldestCart);
			console.log('AFTER CHECK', filter);
			range = parseDateFilter(filter, oldestCart);
		} else{
			range = parseDateFilter(filter);
		}
		let urlDateRange = (range ? "?start="+range.start+"&end="+range.end : "");
		getCampaignGraph(urlDateRange, filter);
	}

	useEffect(() => {
		loadData(filter);
	}, [filter]);

	const checkAllTimeRange = function(oldestCart){
		console.log('ALL TIME RANGE CHECK');
		let MS_IN_HOUR = 60 * 60 * 1000; 
		let MS_IN_DAY = 24 * MS_IN_HOUR;
		console.log(oldestCart);
		if(oldestCart){
			let today = new Date();
			let yesterday = addDays(today,-1);
			let oldestDate = new Date(oldestCart.lastMessage);

			let currentTime = Date.now();
			let diff = currentTime - oldestCart.lastMessage;
			console.log(diff);

			console.log(today);
			console.log(oldestDate);
			if(today.getDate() === oldestDate.getDate() && today.getMonth() === oldestDate.getMonth() && today.getFullYear() === oldestDate.getFullYear()){
				return "today";
			}
			else if(yesterday.getDate() === oldestDate.getDate() && yesterday.getMonth() === oldestDate.getMonth() && yesterday.getFullYear() === oldestDate.getFullYear()){
				return "yesterday";
			}
			else if(diff < (7 * MS_IN_DAY)){
				return "last_days_7";
			}
			else if(diff < (30 * MS_IN_DAY)){
				return "last_days_30";
			}
			else{
				return "full";
			}
		} else{
			return("today");
		}
	}

	return (
		<Box>
			<Box>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={isSmall ? "column" : "row"}
				>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={24}
						fontWeight={600}
						mb={"16px"}
						mt={"8px"}
					>
						Campaigns
					</Typography>
					<Box
						display={"flex"}
						flexDirection={isSmall ? "column" : "row"}
						alignItems={"center"}
					>
						<Select
								options={TimeSelection}
								handleSelectChange={handleDateChange}
								defaultValue="Last 30 Days"
							>
							</Select>
						<Box
							ml={isSmall ? "0px" : "8px"}
							mb={isSmall ? "10px" : "0px"}
							// mt={isSmall ? "0px" : "8px"}
						>
							<CreateCampaignsButton
								lightMode={lightMode}
								onClick={() => navigate("/campaigns/create-campaigns")}
							/>
						</Box>
					</Box>
				</Box>

				<Box
					py={"20px"}
					sx={{
						width: "100%",
						borderRadius: "12px",
						background: theme.palette?.card?.background,
						border: `1px solid ${theme.palette?.card?.border}`,
					}}
				>
					<Box
						display={"flex"}
						sx={{
							gap: "10px",
							paddingLeft: "17px",
							alignItems: "baseline",
						}}
						mb={"8px"}
					>
						<GraphHeading mr={"15px"}>Overview</GraphHeading>
						<GraphTypeHeading
							color={
								graphView === "Revenue" ? theme?.palette?.pageHeading : "#808698"
							}
							sx={{
								borderBottom:
									graphView === "Revenue" ? "2px solid #0C84FE" : "none",
								paddingBottom: graphView === "Revenue" ? "10px" : "0",
							}}
							onClick={() => changeGraphView("Revenue")}
							id="pointerCursor"
						>
							Revenue
						</GraphTypeHeading>
						<GraphTypeHeading
							color={
								graphView === "Texts" ? theme?.palette?.pageHeading : "#808698"
							}
							sx={{
								borderBottom:
									graphView === "Texts" ? "2px solid #0C84FE" : "none",
								paddingBottom: graphView === "Texts" ? "10px" : "0",
							}}
							onClick={() => changeGraphView("Texts")}
							id="pointerCursor"
						>
							Texts
						</GraphTypeHeading>
					</Box>
					<Box mb={"18px"}>
						<Typography
							pl={"17px"}
							fontSize={30}
							fontWeight={600}
							lineHeight={"normal"}
							color={theme?.palette?.pageHeading}
						>
							{graphView === "Revenue" ? ("$") : ("")}{graphTotal[graphView]}
						</Typography>
					</Box>
					<CampaignsGraph
						mode={mode}
						graphView={graphView}
						data={graphData}
						textMax={textMax}
						revenueMax={revenueMax}
					/>
				</Box>
			</Box>
		</Box>
	);
}

const CreateCampaignsButton = ({ lightMode, ...props }) => {
	return (
		<Button
			size="small"
			variant="outlined"
			sx={{
				padding: "7px 0px",
				width: "180px",
				border: "1px solid #0C84FE",
				color: lightMode ? "#0C84FE" : "#EBECF2",
			}}
			{...props}
		>
			<Typography fontSize={"14px"} fontWeight={"600"}>
				Create SMS Campaign
			</Typography>
		</Button>
	);
};

const GraphHeading = ({ children, ...props }) => {
	return (
		<Typography
			lineHeight={"normal"}
			color={"#808698"}
			fontSize={20}
			fontWeight={500}
			{...props}
		>
			{children}
		</Typography>
	);
};

const GraphTypeHeading = ({ children, ...props }) => {
	return (
		<Typography
			lineHeight={"normal"}
			color={"#808698"}
			fontSize={14}
			fontWeight={500}
			{...props}
		>
			{children}
		</Typography>
	);
};

const dayDataMock = [
	{ text: 2900, revenue: 3900, date: "1:00 AM" },
	{ text: 4000, revenue: 5000, date: "3:00 AM" },
	{ text: 6000, revenue: 4000, date: "5:00 AM" },
	{ text: 8000, revenue: 4500, date: "7:00 AM" },
	{ text: 4000, revenue: 5000, date: "9:00 AM" },
	{ text: 5000, revenue: 5500, date: "11:00 AM" },
	{ text: 4500, revenue: 6000, date: "1:00 PM" },
	{ text: 10000, revenue: 6500, date: "3:00 PM" },
	{ text: 11000, revenue: 2000, date: "5:00 PM" },
	{ text: 15000, revenue: 2500, date: "7:00 PM" },
	{ text: 8000, revenue: 3000, date: "9:00 PM" },
	{ text: 3000, revenue: 10000, date: "11:00 PM" },
];
