import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
	MenuItem,
	Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FacebookPixel from '../../Pixels/Facebook.js';
import { txtCartFetch } from "utilifyFunctions";

export default function ConfirmStore({setOnboarding}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [discountCode, setDiscountCode] = useState(generateRandomCode(6));
	const [data, setData] = useState({
		carrier: "USPS",
		domesticShipping: "2-3 Business Days",
		internationalShipping: "4-7 Business Days",
		returnPolicy: "7 Days",
		acceptPayPal: "Yes",
		discountValue: "5%",
	});
	const handleValueChange = (e) => {
		setData({ ...data, [e?.target?.name]: e?.target?.value });
	};
	const fbParams = {value: '100', currency: 'USD', predicted_ltv: '300'}; 

	const saveOnboarding = async function(){
		let req = data;
		req.discountCode = discountCode;

		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/onboardCompleteV2", req);
		console.log(response);
		if(response){
			setOnboarding(4);
		}
	}
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}isMobile={isMobile}>
			<FacebookPixel pixelId="1061429907396108" eventName="StartTrial" params={fbParams}/>
			<Box>
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					mb={"30px"}
					mt={"14px"}
				>
					<img src="/img/onboarding/step3.svg" alt="step3"/>
				</Box>
				<Box mb={"15px"} display={"flex"} justifyContent={"center"}>
					<Typography
						color={"#FFF"}
						fontSize={30}
						fontWeight={600}
						lineHeight={"normal"}
						textAlign={'center'}
					>
						Confirm Store Information
					</Typography>
				</Box>
				<Box mb={"16px"}>
					<CustomLabel>Carrier:</CustomLabel>
					<CustomSelect
						value={data?.carrier}
						name="carrier"
						onChange={handleValueChange}
					>
						<CustomMenuItem value={"USPS"}>USPS</CustomMenuItem>
						<CustomMenuItem value={"UPS"}>UPS</CustomMenuItem>
						<CustomMenuItem value={"FedEx"}>FedEx</CustomMenuItem>
						<CustomMenuItem value={"DHL"}>DHL</CustomMenuItem>
						<CustomMenuItem value={"Canada"}>Canada</CustomMenuItem>
						<CustomMenuItem value={"Post"}>Post</CustomMenuItem>
						<CustomMenuItem value={"Royal Mail"}>Royal Mail</CustomMenuItem>
						<CustomMenuItem value={"Email (Digital Product)"}>
							Email (Digital Product)
						</CustomMenuItem>
					</CustomSelect>
				</Box>
				<Box mb={"16px"}>
					<CustomLabel>Domestic Shipping:</CustomLabel>
					<CustomSelect
						value={data?.domesticShipping}
						name="domesticShipping"
						onChange={handleValueChange}
					>
						<CustomMenuItem value={"2-3 Business Days"}>
							2-3 Business Days
						</CustomMenuItem>
						<CustomMenuItem value={"4-7 Business Days"}>
							4-7 Business Days
						</CustomMenuItem>
						<CustomMenuItem value={"7-10 Business Days"}>
							7-10 Business Days
						</CustomMenuItem>
						<CustomMenuItem value={"Immediate (Email Delivery)"}>
							Immediate (Email Delivery)
						</CustomMenuItem>
					</CustomSelect>
				</Box>
				<Box mb={"16px"}>
					<CustomLabel>International Shipping:</CustomLabel>
					<CustomSelect
						value={data?.internationalShipping}
						name="internationalShipping"
						onChange={handleValueChange}
					>
						<CustomMenuItem value={"4-7 Business Days"}>
							4-7 Business Days
						</CustomMenuItem>
						<CustomMenuItem value={"7-10 Business Days"}>
							7-10 Business Days
						</CustomMenuItem>
						<CustomMenuItem value={"10-14 Business Days"}>
							10-14 Business Days
						</CustomMenuItem>
						<CustomMenuItem value={"14-20 Business Days"}>
							14-20 Business Days
						</CustomMenuItem>
						<CustomMenuItem value={"30+ Business Days"}>
							30+ Business Days
						</CustomMenuItem>
						<CustomMenuItem value={"Immediate (Email Delivery)"}>
							Immediate (Email Delivery)
						</CustomMenuItem>
					</CustomSelect>
				</Box>
				<Box mb={"16px"}>
					<CustomLabel>Return Policy (Days):</CustomLabel>
					<CustomSelect
						value={data?.returnPolicy}
						name="returnPolicy"
						onChange={handleValueChange}
					>
						<CustomMenuItem value={"7 Days"}>7 Days</CustomMenuItem>
						<CustomMenuItem value={"14 Days"}>14 Days</CustomMenuItem>
						<CustomMenuItem value={"30 Days"}>30 Days</CustomMenuItem>
						<CustomMenuItem value={"No Returns"}>No Returns</CustomMenuItem>
					</CustomSelect>
				</Box>
				<Box mb={"16px"}>
					<CustomLabel>Accept PayPal:</CustomLabel>
					<CustomSelect
						value={data?.acceptPayPal}
						name="acceptPayPal"
						onChange={handleValueChange}
					>
						<CustomMenuItem value={"Yes"}>Yes</CustomMenuItem>
						<CustomMenuItem value={"No"}>No</CustomMenuItem>
					</CustomSelect>
				</Box>
				<Box mb={"16px"}>
					<CustomLabel>
						Customize Discount Code for Abandoned Carts:
					</CustomLabel>
					<Box
						width={"100%"}
						sx={{
							border: "1px solid #E8EBF3",
							height: "40px",
						}}
						p={"0 13px 0 16px"}
						borderRadius={"4px"}
						display={"flex"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<Typography
							color={"#0081FF"}
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"normal"}
						>
							{discountCode}
						</Typography>
						<Typography
							color={"#0081FF"}
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"normal"}
							id="pointerCursor"
							onClick={() => {
								setDiscountCode(generateRandomCode(6));
							}}
						>
							Change Discount Code
						</Typography>
					</Box>
				</Box>

				<Box mb={"16px"}>
					<CustomLabel>Discount Value:</CustomLabel>
					<CustomSelect
						value={data?.discountValue}
						name="discountValue"
						onChange={handleValueChange}
					>
						<CustomMenuItem value="5%">5%</CustomMenuItem>
						<CustomMenuItem value="10%">10%</CustomMenuItem>
						<CustomMenuItem value="15%">15%</CustomMenuItem>
						<CustomMenuItem value="20%">20%</CustomMenuItem>
						<CustomMenuItem value="25%">25%</CustomMenuItem>
						<CustomMenuItem value="30%">30%</CustomMenuItem>
						<CustomMenuItem value="35%">35%</CustomMenuItem>
						<CustomMenuItem value="40%">40%</CustomMenuItem>
						<CustomMenuItem value="45%">45%</CustomMenuItem>
						<CustomMenuItem value="50%">50%</CustomMenuItem>
					</CustomSelect>
				</Box>
				<Box display={"flex"} justifyContent={"end"}>
					<Button
						size={"small"}
						sx={{
							height: "40px",
							backgroundColor: "#0081FF",
							padding: "11px 31px",
							color: mode === "light" ? "#FFF" : "#FFF",
							":hover": {
								color: mode === "light" ? "#FFF" : "#FFF",
							},
							fontSize: "14px",
							fontWeight: "600",
						}}
						variant="outlined"
						onClick={() => {
							saveOnboarding()
						}}
					>
						Next Step
					</Button>
				</Box>
			</Box>
		</MainContainer>
	);
}

const MainContainer = ({ children, lightMode, belowTablet,isMobile }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				padding: isMobile?'15px':"32px",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "726px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				// backgroundColor: lightMode ? "#cacae2" : "#cacae2",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				// height: "935px",
				"::-webkit-scrollbar": {
					display: "none",
				},
			}}
		>
			{children}
		</Box>
	);
};

const CustomSelect = ({ children, ...props }) => {
	return (
		<Select
			sx={{
				color: "#FFF",
				"&:focus": {
					backgroundColor: "transparent", // Set the background color to transparent
				},
				"&:focus-within": {
					outline: "none",
					// border: "1px solid #E8EBF3 !important",
				},
				"& .MuiOutlinedInput-notchedOutline": {
					border: "1px solid #E8EBF3 ",
					"&:focus": {
						borderColor:"1px solid #E8EBF3 ",
					},
				},
				// border: "1px solid #E8EBF3 !important",
				"& .MuiSvgIcon-root": { color: "#C1C1C1" },
			}}
			IconComponent={ExpandMoreIcon}
			style={{
				width: "100%",
				height: "40px",
			}}
			onChange={(e) => {
				console.info(e.target.value);
			}}
			{...props}
		>
			{children}
		</Select>
	);
};
const CustomMenuItem = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<MenuItem
			sx={{
				background: theme?.palette?.card?.background,
			}}
			{...props}
		>
			{children}
		</MenuItem>
	);
};

const CustomLabel = ({ children, ...props }) => {
	return (
		<Typography
			color={"#808698"}
			fontSize={14}
			fontWeight={400}
			{...props}
			lineHeight={"normal"}
			mb={"8px"}
		>
			{children}
		</Typography>
	);
};

function generateRandomCode(length) {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";

	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters.charAt(randomIndex);
	}

	return result;
}
