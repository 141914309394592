export const ContactTabs = Object.freeze({
    CartRecovery: "Cart Recovery",
    Campaign: "Campaign"
})

export const ContactFilterOptions = Object.freeze({
    Open: "Open",
    Closed: "Closed",
    Overridden: "Overridden",
    Recovered: "Recovered"
})

export function formatPhoneNumber(phoneNumber) {
    // Remove all non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Australia (Mobile and Landline)
    if (cleaned.startsWith("61") || cleaned.startsWith("0")) {
        // Mobile: +61 4xx xxx xxx or 04xx xxx xxx
        const auMobileMatch = cleaned.match(/^61(\d{1})(\d{4})(\d{3})(\d{3})$/) || cleaned.match(/^0(\d{1})(\d{4})(\d{3})(\d{3})$/);
        // Landline: +61 x xxxx xxxx or 0x xxxx xxxx
        const auLandlineMatch = cleaned.match(/^61(\d{1})(\d{4})(\d{4})$/) || cleaned.match(/^0(\d{1})(\d{4})(\d{4})$/);

        if (auMobileMatch) {
            return `+61 ${auMobileMatch[1]} ${auMobileMatch[2]} ${auMobileMatch[3]} ${auMobileMatch[4]}`;
        } else if (auLandlineMatch) {
            return `+61 ${auLandlineMatch[1]} ${auLandlineMatch[2]} ${auLandlineMatch[3]}`;
        }
    }

    // United Kingdom (Mobile and Landline)
    if (cleaned.startsWith("44") || cleaned.startsWith("0")) {
        // Mobile: +44 7xxx xxx xxx or 07xxx xxx xxx
        const ukMobileMatch = cleaned.match(/^44(7\d{3})(\d{3})(\d{3})$/) || cleaned.match(/^0(7\d{3})(\d{3})(\d{3})$/);
        // Landline: +44 xxxx xxx xxx or 0xxxx xxx xxx
        const ukLandlineMatch = cleaned.match(/^44(\d{4})(\d{3})(\d{3})$/) || cleaned.match(/^0(\d{4})(\d{3})(\d{3})$/);

        if (ukMobileMatch) {
            return `+44 ${ukMobileMatch[1]} ${ukMobileMatch[2]} ${ukMobileMatch[3]}`;
        } else if (ukLandlineMatch) {
            return `+44 ${ukLandlineMatch[1]} ${ukLandlineMatch[2]} ${ukLandlineMatch[3]}`;
        }
    }

    // United States and Canada
    if (cleaned.startsWith("1")) {
        // +1 xxx xxx xxxx
        const usCaMatch = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/);
        if (usCaMatch) {
            return `+1 ${usCaMatch[1]} ${usCaMatch[2]} ${usCaMatch[3]}`;
        }
    }

    // If no valid match is found, return the original phone number
    return phoneNumber;
}

export function formatTimeFromHours(sentAt) {
    if (!sentAt || typeof sentAt !== "string") {
        return sentAt;
    }

    // Extract numeric value and unit from the input string (e.g., "134h", "23m")
    const value = parseInt(sentAt.slice(0, -1), 10); // Numeric part
    const unit = sentAt.slice(-1); // Last character as the unit

    // Convert input into a consistent unit: minutes
    let totalMinutes = 0;
    if (unit === "h") {
        totalMinutes = value * 60; // Convert hours to minutes
    } else if (unit === "m") {
        totalMinutes = value; // Already in minutes
    } else {
        return sentAt; // Invalid format, return as is
    }

    // Convert minutes into the appropriate unit for output
    if (totalMinutes < 60) {
        return `${totalMinutes}m`; // Less than an hour: show in minutes
    }

    const totalHours = totalMinutes / 60;
    if (totalHours < 24) {
        return `${Math.floor(totalHours)}h`; // Less than a day: show in hours
    }

    const totalDays = totalHours / 24;
    if (totalDays < 30) {
        return `${Math.floor(totalDays)}d`; // Less than a month: show in days
    }

    const totalMonths = totalDays / 30;
    if (totalMonths < 12) {
        return `${Math.floor(totalMonths)}mo`; // Less than a year: show in months
    }

    const totalYears = totalMonths / 12;
    return `${Math.floor(totalYears)}y`; // More than a year: show in years
}

export 	function truncateMessage(message, maxLength) {
    if (message.length <= maxLength) return message;
    // Truncate message up to the maxLength
    const truncated = message.slice(0, maxLength);
    // Check if the truncated message ends in the middle of a word
    const lastSpaceIndex = truncated.lastIndexOf(" ");
    // If there's a space within the truncated string, cut at the last space
    return lastSpaceIndex !== -1 ? truncated.slice(0, lastSpaceIndex) + "..." : truncated + "...";
}
