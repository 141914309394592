import { Box, Popover, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { subscriberGraphData } from "resources/constant";
import { TimeSelection } from "resources/constant";
import Subscriber from "components/Chart/Subscriber";
import {
	checkEmptyState,
	updateDataIfEmpty,
} from "resources/helpers/checkEmptyState";

export default function GraphSection({data, total}) {
	const theme = useTheme();
	const [graphData, setGraphData] = useState([]);
	const [emptyState, setEmptyState] = useState(false);


	const { mode } = theme.palette;


	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			const updatedGraphData = updateDataIfEmpty(subscriberGraphData, 0);
			setGraphData(updatedGraphData);
			setEmptyState(true);
		} else {
			setGraphData(subscriberGraphData);
		}
	}, []);

	return (
		<Box>
			<Box
				className="subscriber-graph"
				py={"20px"}
				sx={{
					width: "100%",
					borderRadius: "12px",
					background: theme.palette?.card?.background,
					border: `1px solid ${theme.palette?.card?.border}`,
				}}
			>
				<Box
					display={"flex"}
					sx={{
						padding: "0 17px",
						alignItems: "baseline",
						justifyContent: "space-between",
					}}
					mb={"8px"}
				>
					<GraphHeading>Total Susbcribers</GraphHeading>
				</Box>
				<Box mb={"18px"}>
					<Typography
						pl={"17px"}
						fontSize={30}
						fontWeight={600}
						lineHeight={"normal"}
						color={theme?.palette?.pageHeading}
					>
						{total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
					</Typography>
				</Box>
				<Subscriber mode={mode} data={data} />
			</Box>
		</Box>
	);
}

const GraphHeading = ({ children, ...props }) => {
	return (
		<Typography
			lineHeight={"normal"}
			color={"#808698"}
			fontSize={20}
			fontWeight={600}
			{...props}
		>
			{children}
		</Typography>
	);
};

const FilterDropDown = ({
	anchorEl,
	id,
	handleGraphRange,
	handleClick,
	graphRange,
	handleClose,
}) => {
	const theme = useTheme();
	const open = Boolean(anchorEl);
	const { mode } = theme.palette;
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<React.Fragment>
			<Box
				display={"flex"}
				height={"37px"}
				width={"157px"}
				mb={isSmall ? "10px" : "0px"}
				mt={isSmall ? "0px" : "0"}
				id="pointerCursor"
				sx={{
					borderRadius: "5px",
					padding: "10px",
					border: mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
					background: theme.palette?.card?.background,
					position: "relative",
				}}
				onClick={handleClick}
			>
				<Box paddingRight={"5px"}>
					<img src="/icons/calender.svg" width={"80%"} alt="calender" />
				</Box>
				<Typography
					fontWeight={"500"}
					fontSize={"14px"}
					paddingRight={"10px"}
					display={"flex"}
					alignItems={"center"}
				>
					{graphRange}
				</Typography>
				<Box
					display={"flex"}
					alignItems={"center"}
					sx={{
						position: "absolute",
						top: "14px",
						right: "14px",
					}}
				>
					<img src="/topbar/down.svg" width={"100%"} alt="down"/>
				</Box>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box
					width={"157px"}
					sx={{
						borderRadius: "5px",
						border:
							mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
						background: theme.palette?.card?.background,
						color: "#808698",
						fontSize: "12px",
					}}
				>
					{TimeSelection?.map((item, index) => {
						return (
							<Typography
								key={index}
								sx={{
									padding: "8px 15px",
									":hover": {
										background: "#cccaca3b",
									},
								}}
								fontSize={"14px"}
								id="pointerCursor"
								onClick={() => handleGraphRange(item?.label)}
							>
								{item?.label}
							</Typography>
						);
					})}
				</Box>
			</Popover>
		</React.Fragment>
	);
};

const dayDataMock = [
	{ subscriber: 2.9, date: "1:00 AM" },
	{ subscriber: 4, date: "3:00 AM" },
	{ subscriber: 3.5, date: "5:00 AM" },
	{ subscriber: 3.4, date: "7:00 AM" },
	{ subscriber: 3.2, date: "9:00 AM" },
	{ subscriber: 3.1, date: "11:00 AM" },
	{ subscriber: 3, date: "1:00 PM" },
	{ subscriber: 2.9, date: "3:00 PM" },
	{ subscriber: 2.8, date: "5:00 PM" },
	{ subscriber: 2.7, date: "7:00 PM" },
	{ subscriber: 2.5, date: "9:00 PM" },
	{ subscriber: 3, date: "11:00 PM" },
];
