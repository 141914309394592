/* eslint-disable no-unused-vars */
import {
	Box,
	Grid,
	useTheme,
	Typography,
	useMediaQuery,
	Button,
	Slide,
} from "@mui/material";
import React, {useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { txtCartFetch } from "utilifyFunctions";
import { Mixpanel } from "utils/mixpanel";
import { PAGES, BUTTONS } from "constants/mixPanelEvents";

export default function CampaignsSection() {
	const theme = useTheme();
	const [templates, setTemplates] = useState([]);

	const getHolidayTemplates = async function(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/campaign/template/holiday");
		setTemplates(response?.templates);
	}

	useEffect(() => {
		getHolidayTemplates();
	}, []);
	return (
		<Box
			sx={{
				borderRadius: "12px",
			}}
		>
			<Grid container justifyContent={"space-between"}>
				{templates?.map((item, index) => {
					return (
						<>
							<Card
								item={item}
								key={index}
								title={item?.title}
								description={item?.description}
								img={item?.image}
								date={item?.date}
								theme={theme}
								category={item?.category}
								previewText={item?.text}
								id={item?._id}
							/>
						</>
					);
				})}
			</Grid>
		</Box>
	);
}

const Card = ({
	item,
	theme,
	img,
	title,
	date,
	description,
	category,
	previewText,
	id
}) => {
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedium = useMediaQuery(theme.breakpoints.down("md"));
	const [showPreview, setShowPreview] = useState(false);
	const navigate = useNavigate();

	const handleClick = function (id) {
		navigate("/campaigns/create-campaigns?tid=" + id);
	};

	return (
		<Grid
			container
			id="pointerCursor"
			item
			xs={12}
			sm={5.8}
			lg={2.8}
			md={5.8}
			justifyContent={"space-between"}
			sx={{
				borderRadius: "12px",
			}}
			mb={3}
			position={"relative"}
			gap={"13px"}
		>
			<Box
				display={"flex"}
				flexDirection={"column"}
				width={"100%"}
				overflow={"hidden"}
				className="campaign-planner-container"
				onMouseOver={() => {
					setShowPreview(true);
				}}
				onMouseOut={() => setShowPreview(false)}
				sx={{
					background: theme.palette?.card?.background,
					borderRadius: "12px",
					height: isSmall? "530px": "480px",
					border: `1px solid ${theme.palette?.card?.border}`,
				}}
			>
				<div className="newtest">
					<img
						src={img}
						width={"100%"}
						height={"auto"}
						style={{
							borderTopLeftRadius: "12px",
							borderTopRightRadius: "12px",
						}}
						className="campaign-planner-image"
						alt="campaign"
					/>

					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}
						className={"campaigns-planner-tag"}
						padding={"20px 20px 12px 20px"}
					>
						<Box
							sx={{
								borderRadius: "4px",
								padding: "7px 12px",
								backgroundColor: "rgba(136, 145, 168, 0.15)",
							}}
							className="tag-name"
						>
							<Typography
								color={"#8891A8"}
								fontSize={12}
								fontWeight={600}
								lineHeight={"normal"}
							>
								{category}
							</Typography>
						</Box>
						<Box display={"flex"} alignItems={"center"} className={"date"}>
							<img src="/img/campaign-planner/calender.svg" alt="calender"/>
							<Typography
								fontSize={14}
								fontWeight={500}
								color={"#808698"}
								lineHeight={"normal"}
								ml={"8px"}
							>
								{date}
							</Typography>
						</Box>
					</Box>

					<Typography
						fontSize={16}
						fontWeight={600}
						color={theme.palette?.pageHeading}
						mb={"10px"}
						padding={"0 20px"}
						className="campaign-planner-heading"
					>
						{title}
					</Typography>
					<Typography
						fontSize={14}
						fontWeight={500}
						mb={isMedium ? "30px" : "20px"}
						sx={{
							opacity: "0.5",
							padding: "0 20px",
						}}
						className="campaign-planner-description"
						color={theme.palette?.pageHeading}
					>
						{description}
					</Typography>
				</div>

				<AnimatedBox title={title} previewText={previewText} />

				<Box
					className="here"
					padding={"0 20px 20px 20px"}
					sx={{
						position: "absolute",
						bottom: 0,
						width: "100%",
					}}
				>
					<CustomButtom
						showPreview={showPreview}
						onClick={() => {
							handleClick(id);
							Mixpanel.track_button_action(PAGES.CAMPAIGN_PLANNER, BUTTONS.CUSTOMIZE_AND_SCHEDULE);
						}}
					>
						Customize & Schedule
					</CustomButtom>
				</Box>
			</Box>
		</Grid>
	);
};

function CustomButtom({ mode, children, showPreview, ...props }) {
	return (
		<Button
			fullWidth
			sx={{
				padding: "11px 0px",
				border: mode === "light" ? "1px solid #0081FF" : "1px solid #0081FF",
				marginRight: "10px",
				backgroundColor: showPreview ? "#0081FF" : "transparent",
				":hover": {
					color: "#0081FF",
				},
				color: showPreview ? "#FFF" : "#0081FF",
			}}
			variant="outlined"
			{...props}
		>
			<Typography fontSize={"14px"} fontWeight={"600"}>
				{children}
			</Typography>
		</Button>
	);
}

const AnimatedBox = ({ title = "", previewText = "" }) => {
	const theme = useTheme();
	return (
		<Box className="animatedBox">
			<Typography
				fontSize={16}
				fontWeight={600}
				color={theme.palette?.pageHeading}
				mb={"10px"}
				padding={"0 20px"}
				className="campaign-planner-heading"
			>
				{title}
			</Typography>
			<Typography
				fontSize={16}
				fontWeight={600}
				color={theme.palette?.pageHeading}
				padding={"0 20px"}
				sx={{
					opacity: "0.5",
				}}
			>
				Template preview
			</Typography>
			<Box
				padding={"12px"}
				margin={"10px 20px 20px 20px"}
				bgcolor={theme.palette?.mode === "light" ? "#DBEDFF" : "#0A1E39"}
				sx={{
					borderRadius: "4px",
				}}
			>
				<Typography
					fontSize={14}
					fontWeight={400}
					lineHeight={"20px"}
					color={theme.palette?.pageHeading}
					sx={{
						opacity: 0.7,
					}}
				>
					{previewText}
				</Typography>
			</Box>
		</Box>
	);
};
