import React from "react";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import RenderBoxContent from "./RenderBoxContent";

const SkeletonText = ({ width }) => (
	<Skeleton
		variant="text"
		animation="wave"
		sx={{ fontSize: "1rem", bgcolor: "rgba(0, 13, 49, 0.50)" }}
		height="10px"
		width={width}
	/>
);

export default function ContactDetailEmptyState() {
	return (
		<RenderBoxContent tabName="Customer" maxWidth="349px">
			<Box p="20px 16px">
				<Box display="flex" flexDirection="column" gap="6px">
					<SkeletonText width="60%" />
					<SkeletonText width="40%" />
				</Box>
				<Box display="flex" flexDirection="column" gap="16px" mt="22px">
					{Array(4)
						.fill(0)
						.map((_, index) => (
							<Box display="flex" gap="9px" key={index}>
								<SkeletonText width="10%" />
								<SkeletonText width="40%" />
							</Box>
						))}
				</Box>
			</Box>
			<Divider />
			<Box display="flex" flexDirection="column" p="22px 16px" gap="16px">
				{Array(2)
					.fill(0)
					.map((_, index) => (
						<React.Fragment key={index}>
							<Box display="flex" justifyContent="space-between">
								<SkeletonText width="50%" />
								<SkeletonText width="30%" />
							</Box>
							<Box display="flex" justifyContent="space-between">
								<SkeletonText width="40%" />
								<SkeletonText width="20%" />
							</Box>
							<Box display="flex" justifyContent="space-between">
								<SkeletonText width="45%" />
								<SkeletonText width="35%" />
							</Box>
						</React.Fragment>
					))}
				<Typography fontSize={14} fontWeight={500} lineHeight="20px" sx={{ opacity: 0.5 }}>
					Customer details appear here once customers reply to the texts
				</Typography>
			</Box>
		</RenderBoxContent>
	);
}
