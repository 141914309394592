/* eslint-disable no-unused-vars */
import {
	Box,
	Grid,
	Popover,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import Analytics from "./components/Analytics";
import ChartSection from "./components/ChartSection";
import Tutorial from "./components/Tutorial";
import FunnelSection from "./components/FunnelSection";
import { DataContext } from "../../context/DataContext";
import { TimeSelection } from "resources/constant";
import Antd from "components/Table/Antd";
import TableHeading from "components/Typography/TableHeading";
import TableHeader from "./components/TableHeader";
import ConversationModal from "components/Modal/Conversation";
import Select from "components/Select/Select";
import { txtCartFetch } from "utilifyFunctions";
import EmptyTableData from "./Conversations/Components/EmptyComponentTable";

export default function CartRecovery() {
	const getMode = useTheme();
	const mode = getMode?.palette?.mode;
	const { showCartRecoveryTutorial, setShowCartRecoveryTutorial } =
		useContext(DataContext);
	const [openModal, setOpenModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [modalContent, setModalContent] = useState([]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [recipient, setRecipient] = React.useState("");
	const [dateRange, setDateRange] = React.useState("last_days_30");
	const [conversationRecords, setConversationRecords] = React.useState([]);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const isSmall = useMediaQuery(getMode.breakpoints.down("sm"));
	const lightMode = mode === "light" ? true : false;

	const handleClose = () => {
		setShowCartRecoveryTutorial(false);
	};

	const handleCloseModal = () => {
		setSelectedRow(null);
		setModalContent([]);
		setOpenModal(false);
	};

	async function getConversations() {
		console.log('GET CONVERSATIONS');
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/cart/conversations?limit=5");
		console.log(response);
		if(response){
			for(let i = 0; i < response.length; i++){
				if(response[i].lastMessage){
					let messageDate = new Date(response[i].lastMessage);
					let day = messageDate.toLocaleDateString("default",{ weekday: 'short'});
					let month =  messageDate.toLocaleDateString("default",{month: "short"});
					response[i].date = day + ", " + month + " " + messageDate.getDate() + " " + messageDate.getFullYear();
				} else{
					response[i].date = "";
				}
			}
			console.log('RESPONSE');
			console.log(response);
			setConversationRecords(response);
		}
		return response?.revenue;
	}

	const handleDateChange = (label, value) => {
		console.log("date change");
		console.log(value);
		setDateRange(value);
	}

	const handleOpenModal = (rowId,data) => {
		console.log(data);
		let modalContent = []

		for(let i = 0; i < data.messages.length; i++){
			modalContent.push({message: data.messages[i], txtCartSent: data.senderArray[i]});
		}
		console.log(modalContent);
		setSelectedRow(rowId);
		setModalContent(modalContent);
		setRecipient(data.firstName);
		setOpenModal(true);
	};

	useEffect(() => {
		getConversations();
		return;
	}, []);

	const TableColumns = [
		{
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			title: <TableHeading minWidth={"160px"}>Number</TableHeading>,
			sortable: true,
			width: "27%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "firstName",
			key: "name",
			title: <TableHeading minWidth={"160px"}>Name</TableHeading>,
			sortable: true,
			width: "27%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "conversationLength",
			key: "_id",
			title: <TableHeading minWidth={"250px"}>Last Message</TableHeading>,
			sortable: false,
			width: "32%",
			render: (params,key) =>
				params?.id === 0 ? (
					<Typography
						fontSize={14}
						fontWeight={500}
						color={lightMode ? "#000D31" : "#FFF"}
					>
						{params}
					</Typography>
				) : (
					<Typography
						fontSize={14}
						fontWeight={500}
						color={lightMode ? "#000D31" : "#FFF"}
					>
						<strong
							style={{ color: "#2196F3", cursor: "pointer" }}
							onClick={() => handleOpenModal(params,key)}
						>
							{params} &nbsp;{" "}
						</strong>{" "}
						{key.date}
					</Typography>
				),
		},
		{
			key: "status",
			dataIndex: "status",
			title: <TableHeading minWidth={"160px"}>Status</TableHeading>,
			width: "160px",
			render: (params) =>
				params?.id === 0 ? (
					<Typography
						fontSize={14}
						fontWeight={500}
						color={lightMode ? "#000D31" : "#FFF"}
					>
						{params}
					</Typography>
				) : (
					<>{showStatus(params)}</>
				),
		},
	];
	const showStatus = (value) => {
		return mapStatus[value];
	};

	const mapStatus = {
		"Pending": (
			<span
				className={`conversation_status ${
					lightMode ? "Awaiting_Response" : "Awaiting_Response_Dark"
				}`}
			>
				Awaiting Response
			</span>
		),
		"Customer Replied": (
			<span
				className={`conversation_status ${
					lightMode ? "Customer_Replied" : "Customer_Replied_Dark"
				}`}
			>
				Customer Replied
			</span>
		),
		"Discount Offered": (
			<span
				className={`conversation_status ${
					lightMode ? "Discount_Offered" : "Discount_Offered_Dark"
				}`}
			>
				Discount Offered
			</span>
		),
		"Sold": (
			<span
				className={`conversation_status ${
					lightMode ? "Ordered" : "Ordered_Dark"
				}`}
			>
				Ordered
			</span>
		),
		"Recovered": (
			<span
				className={`conversation_status ${
					lightMode ? "Cart_Viewed" : "Cart_Viewed_Dark"
				}`}
			>
				Cart Viewed
			</span>
		),
	};

	return (
		<Grid container alignItems={"center"}>
			{showCartRecoveryTutorial && (
				<Grid item xs={12}>
					<Tutorial mode={mode} closeTab={handleClose} />
				</Grid>
			)}
			<Grid item xs={12} mb={3}>
				<Analytics />
			</Grid>
			<Grid item xs={12} mb={3}>
				<Box
					p={0}
					sx={{
						width: "100%",
						borderRadius: "12px",
						background: lightMode ? "#FFF" : "#0C0D17",
						border: lightMode ? "1px solid #E8EBF3" : "1px solid #1E202D",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Typography
							p={"21px 0px 14px 20px"}
							fontWeight={"600"}
							fontSize={"20px"}
							lineHeight={"normal"}
						>
							Funnel
						</Typography>
						<Box padding={"14px 14px 20px 0"}>
							<Select
								options={TimeSelection}
								handleSelectChange={handleDateChange}
								defaultValue="Last 30 Days"
							>
							</Select>
						</Box>
					</Box>

					<FunnelSection mode={mode} filter={dateRange} />
				</Box>
			</Grid>
			<Grid item xs={12} mb={3}>
				<Box
					py={"25px"}
					sx={{
						width: "100%",
						borderRadius: "12px",
						background: lightMode ? "#FFF" : "#0C0D17",
						border: lightMode ? "1px solid #E8EBF3" : "1px solid #1E202D",
					}}
				>
					<ChartSection mode={mode} filter={dateRange}/>
				</Box>
			</Grid>
			<Grid item xs={12} mb={3}>
				<Box
					sx={{
						borderBottomLeftRadius: "12px",
						borderBottomRightRadius: "12px",
					}}
				>
					<Antd
						mode={mode}
						rows={conversationRecords}
						columns={TableColumns}
						EmptyComponentTable={<EmptyTableData/>}
						loading={conversationRecords ? false : true}
						TableHead={<TableHeader/>}
						page={"cart-recovery"}
					/>
				</Box>
			</Grid>
			<ConversationModal
				openModal={openModal}
				handleCloseModal={handleCloseModal}
				lightMode={lightMode}
				isSmall={isSmall}
				modalContent={modalContent}
				recipient={recipient}
			/>
		</Grid>
	);
}
