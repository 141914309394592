import { Fragment, useState, useEffect } from "react";
import { PlusSVG } from "resources/Icons/Inbox/Index";
import { Box, Typography, useTheme } from "@mui/material";
import QuickReplyModal from "./QuickReplyModal";
import { txtCartFetch } from "utilifyFunctions";

export default function QuickReply({ closeDropdown, onQuickReplySelect, setVisible }) {
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [quickReplies, setQuickReplies] = useState([]);
    const [newReply, setNewReply] = useState({ name: "", message: "", availableFor: "Everyone" });

    useEffect(() => {
        fetchQuickReplies();
    }, []);

    const openDropdown = () => {
        setVisible(true);
    };

    const fetchQuickReplies = async () => {
        try {
            const response = await txtCartFetch(
                "GET",
                `${process.env.REACT_APP_BACKEND_DOMAIN}/dashboard/inbox/conversation/quick_replies`
            );
            if (response?.data) {
                setQuickReplies(response.data);
            }
        } catch (error) {
            console.error("Error fetching quick replies:", error);
        }
    };

    const deleteQuickReply = async (id) => {
        try {
            const requestBody = { shopId: localStorage.getItem("shopId"), id};
            const response = await txtCartFetch(
                "DELETE",
                `${process.env.REACT_APP_BACKEND_DOMAIN}/dashboard/inbox/conversation/quick_replies`,
                requestBody,
                true
            );
            if (response?.success) {
                return true;
            }
            return false;
        } catch (error) {
            console.error("Error deleting quick reply:", error);
            return false;
        }
    };

    const handleDelete = async (id) => {
        const hasDeletedSuccessfull = await deleteQuickReply(id);
        if (hasDeletedSuccessfull) {
            setQuickReplies((prev) => prev.filter((item) => item.id !== id));
            return true;
        }
        return false;
    };

    const handleQuickReplyClick = (selectedReply) => {
        onQuickReplySelect(selectedReply.message);
        closeDropdown();
    };

    const handleQuickReplyEditClick = (item) => {
        setIsOpen(true);
        setNewReply({
            name: item.name,
            message: item.message,
            availableFor: item.availableFor || "Everyone",
            id: item.id,
        });
        closeDropdown();
    };

    const upsertQuickReply = async () => {
        try {
            const shopId = localStorage.getItem("shopId");
            const requestBody = { ...newReply, shopId };
            const endpoint = `${process.env.REACT_APP_BACKEND_DOMAIN}/dashboard/inbox/conversation/quick_replies`;
            const method = newReply.id ? "PUT" : "POST";

            const response = await txtCartFetch(method, endpoint, requestBody, true);
            if (response?.data) {
                if (newReply.id) {
                    setQuickReplies((prev) =>
                        prev.map((reply) =>
                            reply.id === newReply.id ? response.data : reply
                        )
                    );
                    setNewReply(response?.data);
                    return true;
                } else {
                    setQuickReplies((prev) => [response.data, ...prev]);
                    setNewReply({ name: "", message: "", availableFor: "Everyone" });
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.error("Error creating quick reply:", error);
            return false;
        }
    };

    const handleClose = () => {
        setIsOpen(false);
        setNewReply({ name: "", message: "", availableFor: "Everyone" });
    };

    return (
        <Fragment>
            <Box
                bgcolor={theme?.palette?.card?.background}
                sx={{
                    border: `1px solid ${theme?.palette?.card?.border}`,
                    borderRadius: "8px",
                    width: "548px",
                    height: "373px",
                    maxHeight: "350px",
                    position: "absolute",
                    bottom: "8px",
                }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    p={"20px"}
                    borderBottom={"1px solid #DADEEA"}
                >
                    <Typography
                        fontSize={16}
                        fontWeight={600}
                        lineHeight={"normal"}
                        color={theme?.palette?.pageHeading}
                    >
                        Quick Replies
                    </Typography>
                    <Box display={"flex"} gap={"20px"} alignItems={"center"}>
                        <Box
                            display={"flex"}
                            id="pointerCursor"
                            alignItems={"center"}
                            gap={"8px"}
                            onClick={() => setIsOpen(true)}
                        >
                            <PlusSVG />
                            <Typography
                                fontSize={14}
                                fontWeight={600}
                                lineHeight={"normal"}
                                color={"#0C84FE"}
                            >
                                New Quick Reply
                            </Typography>
                        </Box>
                        <img
                            src="/icons/cut.svg"
                            alt="cut"
                            onClick={closeDropdown}
                            id="pointerCursor"
                            style={{
                                marginLeft: "20px",
                            }}
                        />
                    </Box>
                </Box>
                <Box display={"flex"}>
                    <Box
                        width={"50%"}
                        borderRight={quickReplies.length > 0 && "1px solid #DADEEA"}
                        sx={{
                            maxHeight: "282px",
                            p: "12px",
                            overflow: "hidden",
                            overflowY: "scroll",
                            scrollbarWidth: "none",
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                    >
                        {quickReplies.length === 0 && (
                            <Typography
                                fontSize={12}
                                fontWeight={500}
                                lineHeight={"16px"}
                                color={theme?.palette?.pageHeading}
                                sx={{
                                    opacity: "0.5",
                                }}
                            >
                                Create a new Quick Reply to reply even faster.
                            </Typography>
                        )}
                        {quickReplies.map((item, index) => (
                            <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                padding={"10px 8px"}
                                sx={{
                                    position: "relative",
                                    height: "44px",
                                    background: activeTab === index ? "rgb(218, 222, 234, 0.4)" : "transparent",
                                    borderRadius: "4px",
                                    mb: "5px",
                                }}
                                onMouseEnter={() => setActiveTab(index)}
                                onMouseLeave={() => setActiveTab(null)}
                            >
                                <Typography
                                    id="pointerCursor"
                                    p={"10px"}
                                    fontSize={14}
                                    fontWeight={500}
                                    lineHeight={"normal"}
                                    color={theme?.palette?.pageHeading}
                                    sx={{
                                        flexGrow: 1,
                                        textAlign: "left",
                                    }}
                                    onClick={() => handleQuickReplyClick(item)}
                                >
                                    {item.name}
                                </Typography>
                                <Typography
                                    id="pointerCursor"
                                    sx={{
                                        right: "8px",
                                        padding: "10px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleQuickReplyEditClick(item)}
                                >
                                    <img src="/icons/modal/pencil-edit-button.svg" alt="Edit" height="15px"/>
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box width={"50%"} p={"22px 16px"}>
                        <Typography
                            fontSize={14}
                            fontWeight={700}
                            lineHeight={"normal"}
                            color={theme?.palette?.pageHeading}
                            mb={"6px"}
                        >
                            {quickReplies[activeTab]?.name}
                        </Typography>
                        <Typography
                            fontSize={12}
                            fontWeight={500}
                            lineHeight={"16px"}
                            color={theme?.palette?.pageHeading}
                            sx={{
                                opacity: "0.5",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {quickReplies[activeTab]?.message || "Hover to see details about quick replies."}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <QuickReplyModal
                open={isOpen}
                handleClose={handleClose}
                onSave={upsertQuickReply}
                newReply={newReply}
                setNewReply={setNewReply}
                onDelete={handleDelete}
                openDropdown={openDropdown}
            />
        </Fragment>
    );
}
