import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import PageHeading from "components/Typography/PageHeading";
import React, { useState } from "react";
import KnowledgeBaseModal from "components/Modal/KnowledgeBaseModal";
import CaptureModal from "components/Modal/CaptureModal";
const dataMock = [
  {
    title: "Do you ship physical products?",
    desc: "Most products are virtual, though we do have merch which should be shipped to you.",
    IsTrue: true,
    userSubmitted: false,
  },
  {
    title: "Do you offer refunds?",
    desc: "No, all purchases are final.",
    IsTrue: true,
    userSubmitted: false,
  },
  {
    title: "How long until I receive my order?",
    desc: "Depends on the product you’ve ordered. We typically give an estimate lead time of 2-4 weeks until your order is fulfilled.",
    IsTrue: true,
    userSubmitted: false,
  },
  {
    title: "The product is sold out, will it be available again?",
    desc: "Yes, you can sign up for back-in-stock notifications.",
    IsTrue: true,
    userSubmitted: false,
  },
  {
    title: "Additional Notes",
    IsTrue: false,
    userSubmitted: true,
  },
];

const TabOptions = ["All", "User Submitted", "Auto-Captured"];

export default function KnowledgeBase() {
  const theme = useTheme();
  const [data, setData] = useState([...dataMock]);
  const { mode } = theme.palette;
  const [tab, setTab] = useState(0);
  const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [openModal, setOpenModal] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [openCaptureModal, setOpenCaptureModal] = useState(false);
  const handleFilter = (value) => {
    let result;
    let tmp = [...dataMock];
    if (value === "User Submitted") {
      result = tmp?.filter((item) => {
        return item?.userSubmitted;
      });
    } else if (value === "Auto-Captured") {
      result = tmp?.filter((item) => {
        if (!item?.userSubmitted) {
          return item;
        }
      });
    } else {
      result = [...dataMock];
    }
    setData([...result]);
  };
 
  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item xs={12}>
        <PageHeading mb="23px">Knowledge Base</PageHeading>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={belowSmall ? "column" : "row"}
          gap={"12px"}
        >
          <Box>
            <Box display={"flex"} gap={"12px"}>
              {TabOptions?.map((item, index) => {
                return (
                  <Box
                    id="pointerCursor"
                    key={index}
                    sx={{
                      padding: "12px 18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: `1px solid ${
                        tab === index
                          ? mode === "light"
                            ? "#0A0A18"
                            : "#FFF"
                          : theme?.palette?.card?.border
                      }`,
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      handleFilter(item);
                      setTab(index);
                    }}
                  >
                    <Typography
                      color={theme?.palette?.pageHeading}
                      fontSize={14}
                      fontWeight={600}
                      lineHeight={"normal"}
                      sx={{
                        opacity: tab === index ? "1" : "0.3",
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box>
            <PrimaryButton
              onClick={() => {
                setActiveItem(""); // Reset activeItem to empty string
                setOpenModal(true);
              }}
            >
              New Entry
            </PrimaryButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          gap={"24px"}
          flexDirection={belowSmall ? "column" : "row"}
        >
          <Box
            width={belowSmall ? "100%" : "73%"}
            display={"flex"}
            gap={"12px"}
            flexDirection={"column"}
          >
            {data?.map((item, index) => {
              return (
                <Box
                  id="pointerCursor"
                  key={index}
                  p={"20px"}
                  sx={{
                    background: theme?.palette?.card?.background,
                    borderRadius: "12px",
                    border: `1px solid ${theme?.palette?.card?.border}`,
                    display: "flex",
                    alignItems: "center",
                    gap: "23px",
                  }}
                  onClick={() => {
                    setActiveItem(item);
                    setOpenModal(true);
                  }}
                >
                  <Box>
                    <img
                      src={
                        item?.IsTrue
                          ? "/img/active-trust-hub.svg"
                          : "/img/not-active-trust-hub.svg"
                      }
                      style={{
                        marginTop: item?.desc ? "10px" : "0px",
                      }}
                      alt="trust-hub"
                    />
                  </Box>
                  <Box>
                    <Typography
                      color={theme?.palette?.PageHeading}
                      fontWeight={600}
                      fontSize={16}
                      lineHeight={"normal"}
                      mb={"12px"}
                    >
                      {item.title}
                    </Typography>
                    {item?.desc && (
                      <Typography
                        color={"#808698"}
                        fontWeight={400}
                        fontSize={14}
                        lineHeight={"normal"}
                      >
                        {item.desc}
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box width={belowSmall ? "100%" : "27%"}>
            <Box
              p={"20px"}
              sx={{
                background: theme?.palette?.card?.background,
                borderRadius: "12px",
                border: `1px solid ${theme?.palette?.card?.border}`,
              }}
            >
              <Box
                display={"flex"}
                gap={"10px"}
                alignItems={"center"}
                mb={"12px"}
              >
                <img src="/img/info-blue.svg" alt="info" />
                <Typography
                  color={theme?.palette?.PageHeading}
                  fontWeight={600}
                  fontSize={16}
                  lineHeight={"normal"}
                >
                  What’s Knowledge Base?
                </Typography>
              </Box>
              <Typography
                color={"#808698"}
                fontWeight={400}
                fontSize={14}
                lineHeight={"20px"}
              >
                Our Knowledge Base is what we rely on to answer your customers
                questions quickly, reducing intervention from your team.
               </Typography>
            </Box>
            <Box
              mt={"16px"}
              p={"20px"}
              sx={{
                background: theme?.palette?.card?.background,
                borderRadius: "12px",
                border: `1px solid ${theme?.palette?.card?.border}`,
              }}
            >
              <Box
                display={"flex"}
                gap={"10px"}
                alignItems={"center"}
                mb={"12px"}
              >
                <img src="/img/what's-new.svg" alt="info" />
                <Typography
                  color={theme?.palette?.PageHeading}
                  fontWeight={600}
                  fontSize={16}
                  lineHeight={"normal"}
                >
                  Auto-Capture
                </Typography>
              </Box>
              <Typography
                color={"#808698"}
                fontWeight={400}
                fontSize={14}
                lineHeight={"20px"}
              >
                With Auto-Capture, you can automatically pull content from your
                existing FAQ page to populate the Knowledge Base with up-to-date
                information.
              </Typography>
              <Box display={"flex"} mb={"12px"} mt={"33px"}>
                <Box width={"35%"}>
                  <Typography
                    color={"#808698"}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={"20px"}
                  >
                    Your FAQs:
                  </Typography>
                </Box>
                <Box
                  maxWidth={"183px"}
                  display={"flex"}
                  justifyContent={"end"}
                  flexDirection={"column"}
                  alignItems={"end"}
                  sx={{
                    wordWrap: "break-word",
                  }}
                >
                  <Typography
                    color={theme?.palette?.PageHeading}
                    fontWeight={600}
                    fontSize={14}
                    lineHeight={"normal"}
                    sx={{
                      wordBreak: "break-all",
                      textAlign: "end",
                    }}
                  >
                    robloxbracketracing.com/pages/faqs
                  </Typography>
                  {/* <Typography
										color={theme?.palette?.PageHeading}
										fontWeight={600}
										fontSize={14}
										lineHeight={"normal"}
										sx={
											{
												// wordWrap: "break-word",
											}
										}
									>
										pages/faqs
									</Typography> */}
                </Box>
              </Box>
              <Box display={"flex"} mb={"12px"}>
                <Box width={"35%"}>
                  <Typography
                    color={"#808698"}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={"20px"}
                  >
                    Last Updated:
                  </Typography>
                </Box>
                <Box
                  width={"209px"}
                  display={"flex"}
                  justifyContent={"end"}
                  flexDirection={"column"}
                  alignItems={"end"}
                  sx={{
                    wordWrap: "break-word",
                  }}
                >
                  <Typography
                    color={theme?.palette?.PageHeading}
                    fontWeight={600}
                    fontSize={14}
                    lineHeight={"normal"}
                    sx={
                      {
                        // wordWrap: "break-word",
                      }
                    }
                  >
                    6 minutes ago
                  </Typography>
                </Box>
              </Box>
              <Box display={"flex"} mb={"20px"}>
                <Box width={"35%"}>
                  <Typography
                    color={"#808698"}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={"20px"}
                  >
                    Next Capture:
                  </Typography>
                </Box>
                <Box
                  width={"209px"}
                  display={"flex"}
                  justifyContent={"end"}
                  flexDirection={"column"}
                  alignItems={"end"}
                  sx={{
                    wordWrap: "break-word",
                  }}
                >
                  <Typography
                    color={theme?.palette?.PageHeading}
                    fontWeight={600}
                    fontSize={14}
                    lineHeight={"normal"}
                  >
                    In 7 Days
                  </Typography>
                </Box>
              </Box>
              <Button
                fullWidth
                sx={{
                  padding: "7px 15px",
                  border: "1px solid #0081FF",
                  backgroundColor: "transparent",
                  height: "40px",
                }}
                variant="outlined"
                onClick={() => setOpenCaptureModal(true)}
              >
                <Typography
                  fontSize={"14px"}
                  fontWeight={"600"}
                  color="#0081FF"
                  lineHeight={"normal"}
                >
                  Capture Now
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <KnowledgeBaseModal
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        data={dataMock}
      />
      <CaptureModal
        open={openCaptureModal}
        handleClose={() => setOpenCaptureModal(false)}
      />
    </Grid>
  );
}
