import React from "react";
import { Box } from "@mui/material";

const RenderBoxContent = ({ children, isMobile, activeTab, tabName, theme }) => (
	<Box
		width={isMobile ? "100%" : "25%"}
		bgcolor={theme?.palette?.card?.background}
		sx={{
			maxHeight: `calc(100vh - ${isMobile ? "135px" : "50px"})`,
			minHeight: `calc(100vh - ${isMobile ? "135px" : "50px"})`,
		}}
		borderRight={tabName === "left" ? `1px solid ${theme?.palette?.card?.border}` : undefined}
		borderLeft={tabName === "right" ? `1px solid ${theme?.palette?.card?.border}` : undefined}
		display={isMobile ? (activeTab === tabName ? "block" : "none") : "block"}
	>
		{children}
	</Box>
);

export default RenderBoxContent;
