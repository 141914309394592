import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { txtCartFetch } from "utilifyFunctions";

import {
	Paper,
	Button,
	Typography,
	ThemeProvider,
	createTheme,
	Box,
	Link,
} from "@mui/material";

async function login(domain) {
	const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/login/" + domain);
	window.location.replace(response.installUrl);
}


const LoginForm = () => {
	const [domain, updateDomain] = useState("");
	const handleFormSubmit = () => {
		if(!domain){
			alert("Enter a domain name");
		}
		else{
			login(domain);
		}
		
	};

	const getMode = useTheme();
	const mode = getMode?.palette?.mode;
	const lightMode = mode === "light" ? true : false;

	const theme = createTheme({
		palette: {
			mode: mode,
		},
		typography: {
			fontFamily: '"Inter", sans-serif', // Set the fontFamily to "inherit" to remove the default font-family
		},
	});
	const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

	return (
		<ThemeProvider theme={theme}>
			<Paper sx={{ padding: 2, boxShadow: "none 	" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						height: isLargeScreen ? "auto" : "100vh",
					}}
				>
					<Box
						sx={{
							width: isLargeScreen ? "50%" : "100%",
						}}
						display={"flex"}
						justifyContent={"center"}
					>
						<Box
							sx={{
								width: isLargeScreen ? "500px" : "100%",
								// padding: isLargeScreen ? "45px 10rem" : "0px 0px",
							}}
						>
							<img
								src={lightMode ? "/logos/logo2-light.svg" : "/logos/logo2.svg"}
								alt="Logo"
								style={{ width: "150px", height: "auto" }}
							/>
							<h1
								className="text-[34px] mt-[5px] font-bold"
								style={{ fontWeight: "700" }}
							>
								Login to Existing <span className="txt-cart-text">TxtCart</span>{" "}
								Account
							</h1>{" "}
							<p style={{ fontSize: "13px", fontWeight: "500" }}>
								Enter your Shopify domain
							</p>
							<form onSubmit={handleFormSubmit}>
								<input
									type="text"
									name="domain"
									className="domain-placeholder"
									//   placeholder="your-store.myshopify.com"
									value={domain} // Add the value attribute here
									onChange={(e) => updateDomain(e.target.value)}
								></input>
								<br />
								{/* <button className="connect-button">Connect</button>.30 */}
								{/* <p>
                  Don&apos;t have an account?{" "}
                  <a className="link-signup" href="#">
                    Sign up here
                  </a>
                </p> */}
							</form>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "10%",
									// width: "380px",
									justifyContent: "space-between",
								}}
							></Box>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									// width: "380px",
									backgroundColor: "#0081FF",
									boxShadow: "none",
									fontSize: "12px",
									fontWeight: "600",
									padding: "10px",
								}}
                onClick={handleFormSubmit}
							>
								Sign In
							</Button>
							<Box
								sx={{
									mt: 1,
									display: "flex",
									justifyContent: "center",
								}}
							>
								<Typography
									color={"#969AA5"}
									fontSize={"12px"}
								>
									Don’t have an account?
									<Link
										href="/create-account"
										variant="body2"
										color={"#06F"}
										style={{
											textDecoration: "none",
											paddingLeft: "5px",
											fontWeight: "600",
											fontSize: "12px",
										}}
									>
										Sign up
									</Link>
								</Typography>
							</Box>
						</Box>
					</Box>
					{isLargeScreen && (
						<Box
							sx={{
								width: "50%",
								position: "relative",
								maxHeight: "100vh", // Set a maximum height
								overflow: "hidden", // Hide overflow content
							}}
						>
							<img
								src="/logos/Section.svg"
								alt="Section"
								style={{
									width: "100%",
									height: "94vh",
									maxWidth: "100%",
									//   objectFit: "cover",
									marginLeft: "4em",
								}} // Ensure the image is responsive
							/>
							{/* <Typography
								fontSize={"16px"}
								lineHeight={"20px"}
								sx={{
									position: "absolute",
									bottom: "23%",
									left: "50%",
									transform: "translateX(-50%)",
									color: "#969AA5",
									padding: "10px",
									width: "100%",
									textAlign: "center",
								}}
							>
								Trusted by brands including:
							</Typography> */}
						</Box>
					)}
				</Box>
			</Paper>
		</ThemeProvider>
	);
};

export default LoginForm;

// const FieldLabel = ({ children, ...props }) => {
//   return (
//     <Typography
//       fontWeight={"400"}
//       fontSize={"14px"}
//       marginTop={"24px"}
//       marginBottom={"8px"}
//       {...props}
//     >
//       {children}
//     </Typography>
//   );
// };