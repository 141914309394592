/* eslint-disable no-unused-vars */
import {
	Box,
	Drawer,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
// import { Grid } from "antd";
import React, { useEffect, useState } from "react";
import Contacts from "./Sections/Contacts";
import ChatSection from "./Sections/ChatSection";
import CustomerDetails from "./Sections/CustomerDetails";
import { CustomerDetailsSVG, InfoChatSVG } from "resources/Icons/Inbox/Index";
import { checkEmptyState } from "resources/helpers/checkEmptyState";
import EmptyState from "./components/EmptyState";
//import CustomerTimeLine from "./Screens/CustomerTimeLine";
import { txtCartFetch } from "utilifyFunctions";


export default function Inbox() {
	const theme = useTheme();
	const [open, setOpen] = React.useState(true);
	const handleOpen = () => setOpen(true);
	const handleSidebarClose = () => {
		setOpenSidebar(false);
	};
	const isMd = useMediaQuery(theme.breakpoints.up("md"), {
		defaultMatches: true,
	});
	const [openSidebar, setOpenSidebar] = useState(false);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [activeTab, setActiveTab] = useState("Chat");
	const [emptyState, setEmptyState] = useState(false);
	const [tab, setTab] = useState("1");
	const [data, setData] = useState([]);
	const [inboxContacts, setInboxContacts] = useState([]);
	const [isActive, setIsActive] = useState({ ...inboxContacts[0],index:0 });
	const [bodyMarginTop, setBodyMarginTop] = useState(0);
	const [conversationFilteredCount, setConversationFilteredCount] = useState({});

	const fetchFilteredConversationsCount = async () => {
		try {
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + `/dashboard/inbox/conversation/filtered/count`);
			setConversationFilteredCount(response);
		} catch (error) {
			console.error("Failed to fetch filtered conversations count", error);
		}
    };

	const handleGetContacts = async () => {
		try {
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/inbox/conversations");
			await fetchFilteredConversationsCount();
			setInboxContacts(response);
			if (response.length > 0) {
				setIsActive({ ...response[0], index: 0 });
			}else{
				setEmptyState(true);
			}
		} catch (error) {
			console.error("Failed to fetch contacts:", error);
		}
	};

	// Dynamically track the marginTop of the body
	useEffect(() => {
		const observer = new MutationObserver(() => {
			const htmlMarginTop = parseInt(window.getComputedStyle(document.documentElement).marginTop, 10) || 0;
			const body = document.body;
			const marginTop = parseInt(window.getComputedStyle(body).marginTop, 10) || 0;
			console.log("htmlMarginTop", htmlMarginTop);
			console.log("bodyMarginTop", marginTop);
			setBodyMarginTop(htmlMarginTop + marginTop);
		});
	
		observer.observe(document.body, {
			attributes: true,
			attributeFilter: ["style", "class"],
			childList: false,
			subtree: false,
		});
	
		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		document.body.style.overflow = "hidden";
		document.body.classList.add("hide-scrollbar");
		return () => {
			document.body.classList.remove("hide-scrollbar");
		};
	}, []);

	useEffect(() => {
		const empty = inboxContacts.length === 0;
		if (empty) {
			setEmptyState(true);
		}else{
			setEmptyState(false);
		}
	}, [inboxContacts]);
	useEffect(
		() => {
			handleGetContacts();
		},
		[]
	);

	return emptyState ? (
		<React.Fragment>
			{isMobile && (
				<TabSelector activeTab={activeTab} setActiveTab={setActiveTab} />
			)}
			<Box display={"flex"} overflow={"hidden"}>
				<EmptyState activeTab={activeTab} />
			</Box>
		</React.Fragment>
	) : (
		<React.Fragment>
			<div
				style={{
					overflow: "hidden",
					background: "#fff",
					maxHeight: `calc(100vh - ${bodyMarginTop}px)`,
				}}
			>
			{tab === "1" && (
				<React.Fragment>
					{isMobile && (
						<TabSelector activeTab={activeTab} setActiveTab={setActiveTab} />
					)}
					<Box display={"flex"} overflow={"hidden"}>
						<Contacts
							activeTab={activeTab}
							isActive={isActive}
							setIsActive={setIsActive}
							contactsData={inboxContacts}
							setContactsData={setInboxContacts}
							conversationFilteredCount={conversationFilteredCount}
							setConversationFilteredCount={setConversationFilteredCount}
						/>
						<ChatSection 
							activeTab={activeTab} 
							isActive={isActive} 
							setIsActive={setIsActive} 
							setInboxContacts={setInboxContacts}
							setConversationFilteredCount={setConversationFilteredCount}
						/>
						<CustomerDetails 
							activeTab={activeTab} 
							setTab={setTab} 
							isActive={isActive} 
							setIsActive={setIsActive} 
						/>
					</Box>
				</React.Fragment>
			)}
			{/* {tab === "2" && (
				<React.Fragment>
					<CustomerTimeLine setTab={setTab} />
				</React.Fragment>
			)} */}
			</div>
		</React.Fragment>
	);
}

const TabSelector = ({ activeTab, setActiveTab }) => {
	const theme = useTheme();
	return (
		<Box
			display={"flex"}
			bgcolor={theme?.palette?.card?.background}
		>
			<Box
				width={"50%"}
				p={"15px 0px"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				bgcolor={activeTab === "Info" && "#0081FF"}
				border={`1px solid ${
					activeTab === "Info" ? "#0081FF" : theme?.palette?.card?.border
				}`}
				// TODO: add chatbox transition for mobile tab selector
				onClick={() => {
					if (activeTab === "Info") {
						setActiveTab("Chat");
					} else {
						setActiveTab("Info");
					}
				}}
			>
				<Box display={"flex"} alignItems={"center"} gap={"5px"}>
					<InfoChatSVG isActive={activeTab === "Info" ? true : false} />
					<Typography
						color={activeTab === "Info" ? "#FFF" : theme?.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Info Chat
					</Typography>
				</Box>
			</Box>
			<Box
				width={"50%"}
				p={"15px 0px"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				bgcolor={activeTab === "Customer" && "#0081FF"}
				borderLeft={"none"}
				border={`1px solid ${
					activeTab === "Customer" ? "#0081FF" : theme?.palette?.card?.border
				}`}
				onClick={() => {
					if (activeTab === "Customer") {
						setActiveTab("Chat");
					} else {
						setActiveTab("Customer");
					}
				}}
			>
				<Box display={"flex"} alignItems={"center"} gap={"5px"}>
					<CustomerDetailsSVG
						isActive={activeTab === "Customer" ? true : false}
					/>
					<Typography
						color={
							activeTab === "Customer" ? "#FFF" : theme?.palette?.pageHeading
						}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Customer Details
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
