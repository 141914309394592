import { Box, Typography } from "@mui/material";

export const IncreaseTag = ({ byValue }) => {
	return (
		<Box
			bgcolor={"rgb(24, 223, 144,0.15)"}
			height={"22px"}
			display={"flex"}
			gap={"5px"}
			borderRadius={"100px"}
			alignItems={"center"}
			justifyContent={"center"}
			paddingLeft={"2px"}
			paddingRight={"2px"}
		>
			<img src="/arrow/analytics/increase.svg"  style={{paddingLeft:"2px"}} alt="icon"/>
			<Typography
				color={"#00BE5B"}
				fontSize={14}
				fontWeight={600}
				lineHeight={"normal"}
				paddingRight={"2px"}
			>
				{byValue}%
			</Typography>
		</Box>
	);
};
export const DecreaseTag = ({ byValue }) => {
	return (
		<Box
			bgcolor={"rgb(255, 0, 77,0.15)"}
			paddingLeft={"2px"}
			paddingRight={"2px"}
			borderRadius={"100px"}
			height={"22px"}
			display={"flex"}
			gap={"5px"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<img src="/arrow/analytics/decrease.svg"  style={{paddingLeft:"2px"}} alt="icon"/>
			<Typography
				color={"#FF004C"}
				fontSize={14}
				fontWeight={600}
				lineHeight={"normal"}
				paddingRight={"2px"}
			>
				{byValue}%
			</Typography>
		</Box>
	);
};

export const NeutralTag = () => {
	return (
		<Box
			bgcolor={"rgba(191, 197, 213,.15)"}
			borderRadius={"100px"}
			height={"22px"}
			display={"flex"}
			gap={"5px"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Typography
				color={"gray"}
				fontSize={14}
				fontWeight={600}
				lineHeight={"normal"}
				paddingLeft="2px"
				paddingRight="2px"
			>
				--
			</Typography>
		</Box>
	);
};

export const EmaptyState = () => {
	return (
		<Box
			bgcolor={"rgb(128, 134, 152,0.15)"}
			width={"39px"}
			borderRadius={"100px"}
			height={"22px"}
			display={"flex"}
			gap={"5px"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Typography
				color={"#808698"}
				fontSize={14}
				fontWeight={600}
				lineHeight={"normal"}
			>
				0%
			</Typography>
		</Box>
	);
};