/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import ReactQuill, { Quill } from "react-quill";
import styled from "@emotion/styled";
import "react-quill/dist/quill.snow.css"; // ES6
// import "./styles.css";
import mention from "quill-mention";
import {
	Box,
	Checkbox,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { DeleteSVG, EmojiSVG, ImageSVG } from "resources/Icons";
import Emoji from "./Emoji";
import AddDiscountModal from "components/Modal/AddDiscountCode";

const atValues = [
	{ id: 2, value: "{DiscountCode}" },
	{ id: 3, value: "{DiscountValue" },
	{ id: 4, value: "{FirstName}" },
	{ id: 5, value: "{LastName}" },
	{ id: 6, value: "{StoreName}" },
	{ id: 7, value: "{StoreUrl}" },
];

function Editor({ 
	value = "", 
	setValue = () => {}, 
	setImage, 
	image, 
	setShowNameWarning, 
	setTextValue, 
	setFile, 
	setSMSCount, 
	smsCount, 
	setIsGSM,
	priceRules,
	setPriceRule,
	codeType,
	setCodeType,
	discountType,
	setDiscountType,
	discountValue,
	setDiscountValue,
	discountUrl,
	setDiscountUrl,
	discountPath, 
	setDiscountPath,
	isEmbedLink,
	setIsEmbedLink,
	priceRule,
	setAddDiscountModal,
	addDiscountModal,
	setCodeConfirmed
}) {
		
	const [charLength, setCharLength] = useState(116);
	const [charCountDenominator, setCharCountDenominator] = useState(153);
	const [messageScore, setMessageScore] = useState("😁 Medium");
	const [lastDiscountTagId, setLastDiscountTagId] = useState(false);
	const OPT_OUT_MESSAGE_LENGTH = 17
	const quillRef = useRef();
	const handleChange = (value, delta, ...props) => {
		console.log(value);
		let textValue = value.replaceAll(/<\/p><p>/g, "\n");
		textValue = textValue.replaceAll(/<[^>]*>/g, "");
		textValue = textValue.replaceAll("&lt;", '<');
		textValue = textValue.replaceAll("&gt;", '>');
		textValue = textValue.replaceAll(/\uFEFF/g, '');
		handleMissingRecommendations(textValue);
		setTextValue(textValue);
		setValue(value);
		setCharLength(textValue.length + OPT_OUT_MESSAGE_LENGTH);
		
		calcNumberSegments(textValue);
	};

	
	const calcNumberSegments = function(textValue){
		let isGSM = true; 
		for(let i = 0; i < textValue.length; i++){
			let char = textValue.charAt(i);
			if(!(char && unicodeToGsm[char.charCodeAt(0)])){
				console.log('bad char found');
				console.log(char + " : " + i);
				console.log(char.charCodeAt(0));
				isGSM = false; 
				break;
			}
		}

		let segmentSize = 153;
		if(isGSM === false){
			segmentSize = 67;
		}
		setSMSCount(Math.ceil((textValue.length +OPT_OUT_MESSAGE_LENGTH)/ segmentSize));
		setIsGSM(isGSM);

		console.log('IS GSM: ', isGSM);

		setCharCountDenominator(Math.ceil((textValue.length +OPT_OUT_MESSAGE_LENGTH)/ segmentSize) * segmentSize);
	}

	const handleMissingRecommendations = function(textValue){
		let hitRecs = 0;
		if(textValue.includes("StoreName")){
			console.log('Name');
			hitRecs++;
		}
		if(textValue.includes("StoreUrl")){
			console.log('url');
			hitRecs++;
		}
		if(textValue.includes('DiscountCode')){
			console.log('discount');
			hitRecs++;
		}
		if(hitRecs < 1){
			setMessageScore("😥 Weak");
		} 
		else if(hitRecs < 2){
			setMessageScore("😁 Medium");
		}
		else if(hitRecs < 3){
			setMessageScore("🏆 Powerful");
		}
	}

	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [editorIndex, setEditorIndex] = useState(null);
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);
	var myToolbar = [
		["image"], //add image here
	];
	const memoizedModules = useMemo(
		() => ({
			toolbar: {
				container: myToolbar,
			},
			mention: {
				allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
				positioningStrategy: "fixed",
				mentionDenotationChars: ["@"],
				renderItem: (data) => {
					if (data.disabled) {
						const div = document.createElement("div");
						div.style =
							"height:10px;line-height:10px;font-size:10px;background-color:#ccc;margin:0 -20px;padding:4px";
						div.innerText = data.value;
						return div;
					}

					return data.value;
				},
				source: function (searchTerm, renderList, mentionChar) {
					let values;

					if (mentionChar === "@") {
						values = atValues;
					}

					if (searchTerm.length === 0) {
						renderList(values, searchTerm);
					} else {
						const matches = [];
						for (let i = 0; i < values.length; i++)
							if (
								~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
							)
								matches.push(values[i]);
						renderList(matches, searchTerm);
					}
				},
				dataAttributes: [
					"id",
					"value",
					"denotationChar",
					"link",
					"target",
					"disabled",
					"color",
				],
			},
		}),
		[atValues]
	);

	useEffect(() => {
		var handleMentionClick = (event) => {
			let updatedValue = value;
	
			updatedValue = removeSpanByDataId(updatedValue, event?.value?.id);
	
			setValue(updatedValue);
		};
	
		window.addEventListener("mention-clicked", handleMentionClick);
	
		return () => {
			window.removeEventListener("mention-clicked", handleMentionClick);
		};
	}, [value]); 
	
	const ShortCodesOptions = [
		{ name: "Discount code", code: "{DiscountCode}" },
		{ name: "Discount value", code: "{DiscountValue}" },
		{ name: "First Name", code: "{FirstName}" },
		{ name: "Last Name", code: "{LastName}" },
		{ name: "Store Name", code: "{StoreName}" },
		{ name: "Store URL", code: "{StoreUrl}" },
	];
	const insertCode = (value,index) => {
		if (quillRef.current) {
			const quill = quillRef.current.getEditor();
			const uniqueId = `${value}-${Date.now()}`
			
			if (value === "{DiscountCode}") {
				setAddDiscountModal(true);
				setLastDiscountTagId(uniqueId);
			}
			quill.getModule("mention").insertItem(
				{
					denotationChar:"",
					id: uniqueId,
					value: value,
				},
				true
			);
		}
	};

	const closeModal = (value) => {
		if (!value) {
			const mentionElement = document.querySelector(
				`[data-id="${lastDiscountTagId}"]`
			);

			// Dispatch a click event on the mention element
			mentionElement.dispatchEvent(new MouseEvent("click"));
		} else{
			setCodeConfirmed(true);
		}
		setAddDiscountModal(false);
	};

	useEffect(() => {
		if(!value.includes('data-id="StoreName')){
			setShowNameWarning(true);
		} else{
			setShowNameWarning(false);
		}
	}, [value]);

	const handleImageInsertion = () => {
		if (quillRef.current) {
			const input = document.createElement("input");
			input.setAttribute("type", "file");
			input.setAttribute("accept", "image/*");
			input.click();

			input.onchange = () => {
				const file = input.files[0];
				if (file) {
					setFile(file);
					const reader = new FileReader();

					reader.onload = (e) => {
						const imageDataUrl = e.target.result;

						// Insert the image into the editor
						const quill = quillRef.current.getEditor();
						const range = quill.getSelection();

						// Insert the image using a base64 data URL
						setImage(`<img src="${imageDataUrl}" alt="Image" width='200px' />`);
						// quill.clipboard.dangerouslyPasteHTML(
						// 	0,
						// 	`<img src="${imageDataUrl}" alt="Image" width='200px' />`
						// );

						// quill.setSelection(0 + 1, 0);
						// input.value = ""; // Clear the input for subsequent uploads
					};

					// Read the image as a data URL
					reader.readAsDataURL(file);
				}
			};
		}
	};

	const handleInsertEmoji = (ev, emojiObject) => {
		console.log(ev.emoji);
		console.log(emojiObject.target?.src);
		const quill = quillRef.current.getEditor();
		let index = 0;
		if (editorIndex) {
			index = editorIndex;
		}
		quill.clipboard.dangerouslyPasteHTML(
			index,
			ev.emoji
		);

		quill.setSelection(index + 1, 0);
		setShowEmojiPicker(false);
	};
	const handleImageDeletion = () => {
		setImage("");
	};

	useEffect(() => {
		if (quillRef.current) {
			const editor = quillRef.current.getEditor();
			editor.on("selection-change", (range) => {
				if (range) {
					setEditorIndex(range?.index);
				}
			});
		}
	}, [quillRef]);

	return (
		<div
			className="create-campaign-text"
			style={{
				position: "relative",
			}}
		>
			<CustomTextArea
				theme={theme}
				ref={quillRef}
				onChange={handleChange}
				value={value}
				modules={memoizedModules}
				style={{ height: "220px" }}
			/>
			<Box>
				<Box
					display={"flex"}
					mt={"24px"}
					justifyContent={"space-between"}
					mb={isSmall ? "30px" : "12px"}
				>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={14}
						fontWeight={500}
					>
						Shortcodes
					</Typography>
					{/* <Box display={"flex"} alignItems={"center"} width={"200px"}>
						<Checkbox size="small" defaultChecked sx={{ padding: "0" }} />
						<Typography
							fontSize={12}
							fontWeight={500}
							color={lightMode ? "#18113C" : "#FFF"}
							lineHeight={"normal"}
							ml={"8px"}
						>
							Include opt-out instructions
						</Typography>
					</Box> */}
				</Box>
				<Box
					display={"flex"}
					gap={"8px"}
					flexWrap={"wrap"}
					className="custom-toolbar-button"
				>
					{ShortCodesOptions?.map((item, index) => {
						return (
							<Options
								id="pointerCursor"
								key={index}
								data-value={item}
								data-id={index}
								onClick={() => {
									insertCode(item?.code,index);
								}}
							>
								{item?.name}
							</Options>
						);
					})}
				</Box>
			</Box>
			<Box position={"absolute"} top={"175px"} padding={"12px"} width={"100%"}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					// alignItems={"center"}
				>
					<Box display={"flex"} gap={"11px"}>
						{image ? (
							<Box onClick={handleImageDeletion} id="pointerCursor">
								<DeleteSVG />
							</Box>
						) : (
							<Box onClick={handleImageInsertion} id="pointerCursor">
								<ImageSVG />
							</Box>
						)}
						<Box
							id="pointerCursor"
							onClick={() => {
								setShowEmojiPicker(true);
							}}
						>
							<EmojiSVG />
							{showEmojiPicker && <Emoji setChosenEmoji={handleInsertEmoji} />}
						</Box>
					</Box>

					<Box display={"flex"}>
						<Box
							px={"12px"}
							sx={
								{
									// borderRight: `1px solid ${theme.palette?.card?.border}`,
								}
							}
						>
							<Typography
								fontSize={isSmall ? 10 : 12}
								fontWeight={500}
								color={"#808698"}
							>
								Characters: {charLength}/ {charCountDenominator}
							</Typography>
						</Box>
						<Box
							px={"12px"}
							sx={
								{
									// borderRight: `1px solid ${theme.palette?.card?.border}`,
								}
							}
							mr={"12px"}
						>
							<Typography
								fontSize={isSmall ? 10 : 12}
								fontWeight={500}
								color={"#808698"}
							>
								SMS: {smsCount}
							</Typography>
						</Box>
						<Box>
							<Typography
								fontSize={isSmall ? 10 : 12}
								fontWeight={500}
								color={"#0CD16A"}
							>
								{messageScore}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
			<AddDiscountModal
				open={addDiscountModal} 
				handleClose={closeModal} 
				priceRules={priceRules} 
				setPriceRule={setPriceRule} 
				codeType={codeType} 
				setCodeType={setCodeType}
				discountType={discountType}
				setDiscountType={setDiscountType}
				discountValue={discountValue}
				setDiscountValue={setDiscountValue}
				discountUrl={discountUrl}
				setDiscountUrl={setDiscountUrl}
				setDiscountPath={setDiscountPath}
				discountPath={discountPath}
				setIsEmbedLink={setIsEmbedLink}
				isEmbedLink={isEmbedLink}
				priceRule={priceRule}
			/>
		</div>
	);
}

const CustomTextArea = styled(ReactQuill)(({ theme }) => ({
	"& .mention": {
		fontWeight: 500,
	},
	"& .ql-mention-list-container": {
		backgroundColor: theme?.palette?.card?.background,
		border: `1px solid ${theme?.palette?.card?.border}`,
		borderRadius: "12px",
	},
	"& .ql-toolbar.ql-snow": {
		display: "none",
	},
	"&.ql-image": {
		position: "absolute",
		bottom: "95px",
	},
	"& .ql-container ": {
		borderRadius: "4px !important",
		color: theme.palette?.pageHeading,
		border: `1px solid ${theme.palette?.card?.border} !important`,
		fontSize: "14px",
		fontWeight: "500",
	},
	"& .ql-editor": {
		color: theme?.palette?.pageHeading,
		padding: "16px !important",
		lineHeight: "2",
	},
	"& .ql-editor span : after": {
		content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='11' viewBox='0 0 10 11' fill='none'%3E%3Cpath d='M10 1.50714L8.99286 0.5L5 4.49286L1.00714 0.5L0 1.50714L3.99286 5.5L0 9.49286L1.00714 10.5L5 6.50714L8.99286 10.5L10 9.49286L6.00714 5.5L10 1.50714Z' fill='%230081FF'/%3E%3C/svg%3E");`,
		cursor: "pointer",
		marginLeft: "10px",
	},
	"& .ql-editor span": {
		borderRadius: "4px",
		background:
			theme?.palette?.mode === "light" ? "rgb(217, 236, 255)" : "rgb(8 48 88)",
		padding: "3px 3px",
		color: " rgb(0, 129, 255)",
		"white-space":"nowrap"
	},
	"& .ql-editor img": {
		marginLeft: "10px",
		cursor: "pointer",
	},
}));
const Options = ({ children, ...props }) => {
	return (
		<span
			style={{
				fontSize: "14px",
				fontWeight: "500",
				backgroundColor: " rgba(136, 145, 168, 0.15)",
				color: "#8891A8",
				padding: "3px 7px",
				borderRadius: "4px",
			}}
			{...props}
		>
			{children}{" "}
		</span>
	);
};

export default Editor;

function removeSpanByDataId(htmlString, dataIdToRemove) {
	// Create a DOMParser
	const parser = new DOMParser();

	// Parse the HTML string into a DOM object
	const doc = parser.parseFromString(htmlString, "text/html");

	// Find all span elements with the specified data-id
	const spansToRemove = doc.querySelectorAll(
		`span[data-id="${dataIdToRemove}"]`
	);

	// Iterate over all found spans and remove them
	spansToRemove.forEach((span) => {
		span.parentNode.removeChild(span);
	});

	// Convert the DOM object back to a string
	const updatedHtmlString = new XMLSerializer().serializeToString(doc);

	return updatedHtmlString;
}

// Map of Javascript code points to GSM-7 
const unicodeToGsm = {
	0x000A: [0x0A],
	0x000C: [0x1B, 0x0A],
	0x000D: [0x0D],
	0x0020: [0x20],
	0x0021: [0x21],
	0x0022: [0x22],
	0x0023: [0x23],
	0x0024: [0x02],
	0x0025: [0x25],
	0x0026: [0x26],
	0x0027: [0x27],
	0x0028: [0x28],
	0x0029: [0x29],
	0x002A: [0x2A],
	0x002B: [0x2B],
	0x002C: [0x2C],
	0x002D: [0x2D],
	0x002E: [0x2E],
	0x002F: [0x2F],
	0x0030: [0x30],
	0x0031: [0x31],
	0x0032: [0x32],
	0x0033: [0x33],
	0x0034: [0x34],
	0x0035: [0x35],
	0x0036: [0x36],
	0x0037: [0x37],
	0x0038: [0x38],
	0x0039: [0x39],
	0x003A: [0x3A],
	0x003B: [0x3B],
	0x003C: [0x3C],
	0x003D: [0x3D],
	0x003E: [0x3E],
	0x003F: [0x3F],
	0x0040: [0x00],
	0x0041: [0x41],
	0x0042: [0x42],
	0x0043: [0x43],
	0x0044: [0x44],
	0x0045: [0x45],
	0x0046: [0x46],
	0x0047: [0x47],
	0x0048: [0x48],
	0x0049: [0x49],
	0x004A: [0x4A],
	0x004B: [0x4B],
	0x004C: [0x4C],
	0x004D: [0x4D],
	0x004E: [0x4E],
	0x004F: [0x4F],
	0x0050: [0x50],
	0x0051: [0x51],
	0x0052: [0x52],
	0x0053: [0x53],
	0x0054: [0x54],
	0x0055: [0x55],
	0x0056: [0x56],
	0x0057: [0x57],
	0x0058: [0x58],
	0x0059: [0x59],
	0x005A: [0x5A],
	0x005B: [0x1B, 0x3C],
	0x005C: [0x1B, 0x2F],
	0x005D: [0x1B, 0x3E],
	0x005E: [0x1B, 0x14],
	0x005F: [0x11],
	0x0061: [0x61],
	0x0062: [0x62],
	0x0063: [0x63],
	0x0064: [0x64],
	0x0065: [0x65],
	0x0066: [0x66],
	0x0067: [0x67],
	0x0068: [0x68],
	0x0069: [0x69],
	0x006A: [0x6A],
	0x006B: [0x6B],
	0x006C: [0x6C],
	0x006D: [0x6D],
	0x006E: [0x6E],
	0x006F: [0x6F],
	0x0070: [0x70],
	0x0071: [0x71],
	0x0072: [0x72],
	0x0073: [0x73],
	0x0074: [0x74],
	0x0075: [0x75],
	0x0076: [0x76],
	0x0077: [0x77],
	0x0078: [0x78],
	0x0079: [0x79],
	0x007A: [0x7A],
	0x007B: [0x1B, 0x28],
	0x007C: [0x1B, 0x40],
	0x007D: [0x1B, 0x29],
	0x007E: [0x1B, 0x3D],
	0x00A1: [0x40],
	0x00A3: [0x01],
	0x00A4: [0x24],
	0x00A5: [0x03],
	0x00A7: [0x5F],
	0x00BF: [0x60],
	0x00C4: [0x5B],
	0x00C5: [0x0E],
	0x00C6: [0x1C],
	0x00C9: [0x1F],
	0x00D1: [0x5D],
	0x00D6: [0x5C],
	0x00D8: [0x0B],
	0x00DC: [0x5E],
	0x00DF: [0x1E],
	0x00E0: [0x7F],
	0x00E4: [0x7B],
	0x00E5: [0x0F],
	0x00E6: [0x1D],
	0x00C7: [0x09],
	0x00E8: [0x04],
	0x00E9: [0x05],
	0x00EC: [0x07],
	0x00F1: [0x7D],
	0x00F2: [0x08],
	0x00F6: [0x7C],
	0x00F8: [0x0C],
	0x00F9: [0x06],
	0x00FC: [0x7E],
	0x0393: [0x13],
	0x0394: [0x10],
	0x0398: [0x19],
	0x039B: [0x14],
	0x039E: [0x1A],
	0x03A0: [0x16],
	0x03A3: [0x18],
	0x03A6: [0x12],
	0x03A8: [0x17],
	0x03A9: [0x15],
	0x20AC: [0x1B, 0x65]
}