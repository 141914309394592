import React, { useEffect, useState, useRef } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
	Fade,
} from "@mui/material";
import FacebookPixel from '../../Pixels/Facebook.js';

export default function AnalyzingSales({setOnboarding, revIncrease, ABTest, shopifyPlan}) {
	const firstRender = useRef(true);
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [isLoading, setIsLoading] = useState(true);
	const [tab, setTab] = useState(1);
	function startInfiniteLoop() {
		let counter = 1;

		const loopFunction = () => {

			if (counter < 3) {
				counter++;
			} else {
				counter = 1;
			}
			setTab(counter);
		};

		const intervalId = setInterval(loopFunction, 3000);
		return intervalId;
	}

	const handleGetStarted = function(){
		setOnboarding(2.1);
	}


	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		}
		console.log(firstRender);
		setTimeout(() => {
			setIsLoading(false);
		}, 3000);
	}, []);
	useEffect(() => {
		console.log('ANALYZING SALES');
		const intervalId = startInfiniteLoop();
		return () => {
			clearInterval(intervalId);
		};
	}, []);
	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
			<FacebookPixel pixelId="1061429907396108" eventName="InitiateCheckout"/>
			<Box display={"flex"} flexDirection={isMobile ? "column" : "row"}>
				<Box
					width={isMobile ? "100%" : "50%"}
					display={"flex"}
					flexDirection={"column"}
					sx={{
						borderRight: !isMobile && `1px solid rgb(232, 235, 243,0.2)`,
						borderBottom: isMobile && `1px solid rgb(232, 235, 243,0.2)`,
					}}
					p={"20px 42px 42px 42px"}
				>
					<Box mb={"45px"} display={"flex"} alignItems={"center"} gap={"8px"}>
						<img src="/logos/logo3.svg" width={"120px"} alt="logo"/>
					</Box>

					<Box height={"400px"}>
						{isLoading ? (
							<Loading lightMode={lightMode} />
						) : (
							<Analyzed lightMode={lightMode} revIncrease={revIncrease}/>
						)}
					</Box>
					<Box
						display={"flex"}
						justifyContent={"space-around"}
						flexDirection={belowTablet ? "column" : "row"}
						alignItems={"center"}
					>
						<GetStartedButton
							lightMode={lightMode}
							onClick={() => handleGetStarted()}
						/>
					</Box>
				</Box>
				<Box width={isMobile ? "100%" : "50%"} p={"26px"}>
					<Box
						mb={"20px"}
						height={"90px"}
						display={"flex"}
						alignItems={"center"}
					>
						<img src={dataMock[tab - 1]?.logo} loading="lazy" width={"108px"} alt="logo"/>
					</Box>
					<Box mb={"29px"} minHeight={"200px"}>
						<Typography
							fontSize={isMobile ? 22 : 24}
							fontWeight={500}
							lineHeight={"32px"}
							color={"#FFF"}
							mb={"12px"}
						>
							{dataMock[tab - 1]?.title}
						</Typography>
						<Typography
							fontSize={14}
							fontWeight={500}
							lineHeight={"20px"}
							color={"#FFF"}
							sx={{
								opacity: "0.6",
							}}
						>
							{dataMock[tab - 1]?.by}
						</Typography>
					</Box>
					<Box display={"flex"} flexDirection={"column"} gap={"12px"}>
						{dataMock[tab - 1]?.data?.map((item, index) => {
							return (
								<Box
									key={index}
									border={`0.75px solid rgba(0, 129, 255, 0.35)`}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									bgcolor={"rgba(0, 129, 255, 0.10);"}
									borderRadius={"12px"}
									height={"67px"}
									textAlign={"center"}
								>
									<Box>
										<Typography
											color={"#0081FF"}
											fontSize={"24px"}
											fontWeight={"700"}
											lineHeight={"normal"}
											mb={"2px"}
										>
											{item?.title}
										</Typography>
										<Typography
											fontSize={"14"}
											fontWeight={"500"}
											lineHeight={"20px"}
											color={"#FFF"}
											sx={{
												opacity: "0.5",
											}}
										>
											{item?.value}
										</Typography>
									</Box>
								</Box>
							);
						})}
					</Box>
					<Box display={"flex"} justifyContent={"center"} mt={"33px"}>
						<Box display={"flex"} gap={"5px"}>
							<Box
								width={"60px"}
								p={"5px,0px"}
								height={"3px"}
								bgcolor={tab === 1 ? "#3471FA" : "rgb(255, 255, 255,0.2)"}
								// id="pointerCursor"
								// onClick={() => setTab(1)}
							>
								{" "}
							</Box>
							<Box
								width={"60px"}
								height={"3px"}
								bgcolor={tab === 2 ? "#3471FA" : "rgb(255, 255, 255,0.2)"}
								// id="pointerCursor"
								// onClick={() => setTab(2)}
							>
								{" "}
							</Box>
							<Box
								width={"60px"}
								height={"3px"}
								p={"5px,0px"}
								bgcolor={tab === 3 ? "#3471FA" : "rgb(255, 255, 255,0.2)"}
								// id="pointerCursor"
								// onClick={() => setTab(3)}
							>
								{" "}
							</Box>
						</Box>
						{/* <img src="/img/onboarding/step.svg" /> */}
					</Box>
				</Box>
			</Box>
		</MainContainer>
	);
}
const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "893px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				"::-webkit-scrollbar": {
					display: "none",
				},
				// height: "578px",
			}}
		>
			{children}
		</Box>
	);
};
const GetStartedButton = ({ lightMode, ...props }) => {
	return (
		<Button
			size={"small"}
			fullWidth
			{...props}
			sx={{
				// width: "150px",
				height: "40px",
				padding: "5px 15px",
				backgroundColor: "#0081FF",
				color: "#FFF",
				":hover": {
					color: lightMode ? "#0081FF" : "#FFF",
				},
			}}
			variant="outlined"
		>
			<Typography fontSize={"14px"} fontWeight={600}>
				Get Started
			</Typography>
		</Button>
	);
};

const dataMock = [
	{
		logo: "/img/onboarding/cultures.svg",
		title:
			"“Email is being phased out and replaced with SMS. TxtCart is ahead and others need to jump on board.”",
		by: "-Shak, Gold Cultures CEO",
		data: [
			{
				title: "$45,642+",
				value: "Additional Revenue from SMS",
			},
			{
				title: "117+",
				value: "Abandoned Carts Recovered",
			},
			{
				title: "20x",
				value: "Return on Investment",
			},
		],
	},

	{
		logo: "/img/onboarding/crossnet.png",
		title:
			"“We were able to increase our AOV by over $50 by using TxtCart compared to Postscript. Love the TxtCart Team.”",
		by: "-Meade Brothers, Crossnet Founders",

		data: [
			{
				title: "$521,590+",
				value: "Additional Revenue from SMS",
			},
			{
				title: "1,793+",
				value: "Abandoned Carts Recovered",
			},
			{
				title: "17.6x",
				value: "Return on Investment",
			},
		],
	},

	{
		logo: "/img/onboarding/joy.png",
		title:
			"“TxtCart has been instrumental in growth. They have been a partner for years & I couldn't imagine what it would look like without their team”",
		by: "-Sam, CEO Joyride",
		data: [
			{
				title: "$1,468,945+",
				value: "Additional Revenue from SMS",
			},
			{
				title: "17,511+",
				value: "Abandoned Carts Recovered",
			},
			{
				title: "32x",
				value: "Return on Investment",
			},
		],
	},
];

const Analyzed = ({revIncrease}) => {
	return (
		<Fade in={true} timeout={1000}>
			<Box>
				<Typography
					fontSize={"24px"}
					fontWeight={500}
					lineHeight={"32px"}
					color={"#FFF"}
					mb={"40px"}
				>
					Based on our analysis of your sales data, our team expects to add
				</Typography>
				<Box position={"relative"} mb={"29px"}>
					<img src="/img/onboarding/box.svg" width={"100%"} alt="box"/>
					<Box
						position={"absolute"}
						sx={{
							top: "50%",
							left: "50%",
							transform: "translate(-90px, -29px)",
						}}
					>
						<Fade in={true} timeout={1500}>
							<Typography
								fontSize={"90px"}
								fontWeight={"700"}
								lineHeight={"32px"}
								color={"#FFF"}
								mb={"20px"}
							>
								{revIncrease}%
							</Typography>
						</Fade>
						<Typography
							fontSize={"16px"}
							fontWeight={"500"}
							lineHeight={"32px"}
							color={"#FFF"}
						>
							more revenue via SMS
						</Typography>
					</Box>
				</Box>
			</Box>
		</Fade>
	);
};
const Loading = ({ lightMode }) => {
	return (
		<Fade in={true} timeout={500}>
			<Box>
				<Typography
					color={lightMode ? "#FFF" : "#FFF"}
					fontSize={"24px"}
					fontWeight={500}
				>
					Analyzing your store’s sales
				</Typography>
				<Box
					// height={"260px"}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					mb={"182px"}
					mt={"128px"}
				>
					<img
						src="/icons/load.png"
						className="onboarding-loader"
						loading="lazy"
						alt="loader"
					/>
				</Box>
			</Box>
		</Fade>
	);
};
