/* eslint-disable no-unused-vars */
import { Box, Typography } from "@mui/material";
import React from "react";

import {
	XAxis,
	YAxis,
	CartesianGrid,
	ResponsiveContainer,
	Area,
	AreaChart,
	Tooltip,
} from "recharts";

export default function CartRecoveryChart({
	mode,
	showConversationsGraph,
	showOrdersGraph,
	showRepliesGraph,
	data,
	max
}) {
	const CustomTick = (props) => {
		const { x, y, payload } = props;
		let getX = (value, x) => {
			return x-20;
		};

		return (
			<g>
				<g>
					<text
						x={getX(payload.index, x)}
						y={y + 10}
						fontSize={"14px"}
						opacity={0.5}
						fill={mode === "light" ? "#0A0A18" : "#EBECF2"}
					>
						{payload.value}
					</text>
				</g>
			</g>
		);
	};

	return (
		<div style={{ width: "100%" }}>
			<ResponsiveContainer width="100%" height={500}>
				<AreaChart
					height={100}
					data={data}
					margin={{
						top: 10,
						right: 19,
						left: 5,
						bottom: 0,
					}}
					isAnimationActive={true}
				>
					<XAxis
						dataKey="date"
						tickLine={false}
						scale={"auto"}
						tickMargin={10}
						tick={<CustomTick />}
						width={5}
					/>
					<YAxis
						tickCount={10}
						axisLine={false}
						tickLine={false}
						tickMargin={20}
						type="number"
						domain={max > 0 ? [0, max] : [0,50]}
						ticks={max > 0 ? undefined : [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50]}
						allowDecimals={false}
					/>
					<Tooltip content={<CustomTooltip />} />
					<CartesianGrid
						strokeDasharray="1 0"
						vertical={false}
						color={mode === "light" ? "#EBEBEB" : "#303343"}
						opacity={mode === "light" ? 0.5 : 0.2}
					/>
					<defs>
						<linearGradient
							id="conversations-gradient"
							x1="0"
							y1="1"
							x2="0"
							y2="0"
						>
							<stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
							<stop offset="100%" stopColor="#0C84FE" />
						</linearGradient>
						<linearGradient id="replies-gradient" x1="0" y1="1" x2="0" y2="0">
							<stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
							<stop offset="100%" stopColor="#9963FE" />
						</linearGradient>
						<linearGradient id="orders-gradient" x1="0" y1="1" x2="0" y2="0">
							<stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
							<stop offset="100%" stopColor="#0CD16A" />
						</linearGradient>
					</defs>
						<Area
							dataKey="conversations"
							id="conversations"
							key="conversations"
							fill="url(#conversations-gradient)"
							stroke="#0C84FE"
							opacity={1}
							hide={!showConversationsGraph}
							fillOpacity={0.2}
							strokeWidth={2}
							isAnimationActive={true}
							animationEasing='linear'
						/>
						<Area
							id="replies"
							dataKey="replies"
							key="replies"
							fill="url(#replies-gradient)"
							stroke="#9963FE"
							opacity={1}
							hide={!showRepliesGraph}
							fillOpacity={0.2}
							strokeWidth={2}
							isAnimationActive={true}
							animationEasing='linear'
						/>
						<Area
							id="orders"
							dataKey="orders"
							key="orders"
							fill="url(#orders-gradient)"
							stroke={"#0CD16A"}
							opacity={1}
							fillOpacity={0.2}
							strokeWidth={2}
							isAnimationActive={true}
							hide={!showOrdersGraph}
							animationEasing='linear'
						/>
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
}

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<Box
				className="tooltip"
				sx={{
					padding: "12px 12px",
					background: "#FFF",
					width: "200px",
					opacity: "0.95",
					// height: "170px",
					borderRadius: "3px",
					boxShadow: "rgb(174, 174, 174) 0px 0px 10px",
					// lineHeight:'12px'
				}}
			>
				<Typography
					fontSize={12}
					fontWeight={600}
					mb={1}
					color={"#7C7C85"}
					lineHeight={"normal"}
				>
					{label}
				</Typography>

				{payload.map((item) => (
					<div
						key={item.dataKey}
						style={{
							borderColor: item.color,
							margin:
								item?.dataKey === "orders" ? "0px 0px 0px 0px" : "12px 0px",
						}}
						className="tooltip-text"
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								// alignItems:'center'
							}}
						>
							<span
								className={`${item?.dataKey}`}
								style={{ marginRight: "10px ", marginTop: "3px" }}
							>
								&nbsp;
							</span>
							<Typography
								fontSize={12}
								color={"#7C7C85"}
								lineHeight={"normal"}
								textTransform={"capitalize"}
							>
								{item.dataKey} :
							</Typography>
						</Box>

						<Typography fontSize={12} color={"#7C7C85"} lineHeight={"normal"}>
							{item.value}
						</Typography>
					</div>
				))}
			</Box>
		);
	}
};
