import React from "react";
import {
	Box,
	Typography,
	useTheme,
	Button,
	useMediaQuery,
} from "@mui/material";
import Tooltip from "components/Tooltip";
import {
	CustomDesc,
	CustomMenuItem,
	CustomSelect,
	CustomTypography,
	demoOption,
} from "./Common";

const Compliance = () => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box>
			<Box mb="20px">
				<Box mb={"8px"}>
					<CustomTypography>SMS compliance requirements</CustomTypography>
				</Box>
				<CustomDesc theme={theme}>
					Tell us about how you collected your SMS subscribers so we can check
					you’re fully compiant.
				</CustomDesc>
			</Box>
			<Box mb={"20px"}>
				<Box display={"flex"} gap={"4px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						mb={"8px"}
					>
						Service (required)
					</Typography>
					<Tooltip text={""} lightMode={lightMode} />
				</Box>

				<Box width={belowSmall ? "100%" : "66%"}>
					<CustomSelect placeholder={"Select a service"}>
						{demoOption.slice(0, 2)?.map((item, index) => {
							return (
								<CustomMenuItem value={item} key={index}>
									{item}
								</CustomMenuItem>
							);
						})}
					</CustomSelect>
				</Box>
			</Box>
			<Box mb={"20px"}>
				<Box display={"flex"} gap={"4px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						mb={"8px"}
					>
						Subscriber collection tool (required)
					</Typography>{" "}
					<Tooltip text={""} lightMode={lightMode} />
				</Box>

				<Box width={belowSmall ? "100%" : "66%"}>
					<CustomSelect placeholder={"Select a tool"}>
						{demoOption.slice(0, 2)?.map((item, index) => {
							return (
								<CustomMenuItem value={item} key={index}>
									{item}
								</CustomMenuItem>
							);
						})}
					</CustomSelect>
				</Box>
			</Box>
			<Box mb={"20px"}>
				<Box display={"flex"} gap={"4px"}>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						mb={"8px"}
					>
						Screenshot of your collection form (optional)
					</Typography>{" "}
					<Tooltip text={""} lightMode={lightMode} />
				</Box>

				<Box width={belowSmall ? "100%" : "66%"}>
					<Button
						fullWidth
						sx={{
							padding: "11px 20px",
							border: "1px dashed rgba(0, 129, 255, 0.40);",
							backgroundColor:lightMode?'#FFF': "rgba(255, 255, 255, 0.05)",
							marginRight: "8px",
						}}
						variant="outlined"
					>
						<img src="/icons/add.svg" alt="add"/>
						<Typography
							ml={"8px"}
							fontSize={"14px"}
							fontWeight={"600"}
							color={"#0081FF"}
							lineHeight={"normal"}
						>
							Upload Image
						</Typography>
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default Compliance;
