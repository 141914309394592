/* eslint-disable react/no-unknown-property */
import React from "react";

export default function Mail() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			style={{ minWidth: "20px", minHeight: "20px" }}
		>
			<path
				d="M1.66602 5.8335L8.47011 10.5964C9.02109 10.982 9.29658 11.1749 9.59624 11.2496C9.86093 11.3156 10.1378 11.3156 10.4025 11.2496C10.7021 11.1749 10.9776 10.982 11.5286 10.5964L18.3327 5.8335M5.66602 16.6668H14.3327C15.7328 16.6668 16.4329 16.6668 16.9677 16.3943C17.4381 16.1547 17.8205 15.7722 18.0602 15.3018C18.3327 14.767 18.3327 14.067 18.3327 12.6668V7.3335C18.3327 5.93336 18.3327 5.2333 18.0602 4.69852C17.8205 4.22811 17.4381 3.84566 16.9677 3.60598C16.4329 3.3335 15.7328 3.3335 14.3327 3.3335H5.66602C4.26588 3.3335 3.56582 3.3335 3.03104 3.60598C2.56063 3.84566 2.17818 4.22811 1.9385 4.69852C1.66602 5.2333 1.66602 5.93336 1.66602 7.3335V12.6668C1.66602 14.067 1.66602 14.767 1.9385 15.3018C2.17818 15.7722 2.56063 16.1547 3.03104 16.3943C3.56582 16.6668 4.26588 16.6668 5.66602 16.6668Z"
				stroke="#0081FF"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
