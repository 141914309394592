import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import ContactDetailEmptyState from "./ContactDetailEmptyState";
import ConversationEmptyState from "./ConversationEmptyState";
import InboxEmptyState from "./InboxEmptyState";

export default function EmptyState({ activeTab }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<React.Fragment>
			{isMobile ? (
				activeTab === "Info" && <ConversationEmptyState isMobile={isMobile} />
			) : (
				<ConversationEmptyState isMobile={isMobile} />
			)}
			{isMobile ? (
				activeTab === "Chat" && <InboxEmptyState isMobile={isMobile} />
			) : (
				<InboxEmptyState isMobile={isMobile} />
			)}
			{isMobile ? (
				activeTab === "Customer" && <ContactDetailEmptyState />
			) : (
				<ContactDetailEmptyState />
			)}
		</React.Fragment>
	);
}
