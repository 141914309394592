import React, { Component } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	Divider,
} from "@mui/material";
import { TextInput } from "components/Inputs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function KnowledgeBaseModal({ open, handleClose, activeItem }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light";
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Modal
			open={open}
			id="KnowledgeBaseModal"
			aria-labelledby="KnowledgeBaseModal"
			aria-describedby="KnowledgeBaseModal"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box p={"20px"}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						mb={"8px"}
					>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"20px"}
							fontWeight={600}
							lineHeight={"normal"}
						>
							Update Knowledge Base Entry
						</Typography>
						<img
							src="/icons/cut.svg"
							alt="cut"
							onClick={handleClose}
							id="pointerCursor"
							style={{
								marginTop: "-10px",
							}}
						/>
					</Box>
					<Box>
						<CustomTypography theme={theme}>
							Source: Scrapped from FAQ Page
						</CustomTypography>
					</Box>
				</Box>

				<Divider />
				<Box p={"22px 20px 20px 20px"}>
					<Box mb={"20px"}>
						<Typography
							color={"#808698"}
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"normal"}
							mb={"8px"}
						>
							Question
						</Typography>
						<TextInput value={activeItem?.title} />
					</Box>
					<MyStatefulEditor markup={activeItem?.desc} />
					<Box display={"flex"} justifyContent={"space-between"} mt={"20px"}>
						<Button
							size={"small"}
							sx={{
								backgroundColor: "#FF465C",
								padding: "11px 20px",
								color: "#FFF",
								border: "1px solid #FF465C",
								":hover": {
									color: mode === "light" ? "#FF465C" : "#FFF",
									border: `1px solid #FF465C`,
								},
							}}
							variant="outlined"
						>
							<Typography fontSize={"14px"} fontWeight={"600"}>
								Archive
							</Typography>
						</Button>
						<Button
							size={"small"}
							sx={{
								backgroundColor: "#0081FF",
								padding: "11px 20px",
								color: "#FFF",
								":hover": {
									color: mode === "light" ? "#0C0D17" : "#FFF",
								},
							}}
							variant="outlined"
						>
							<Typography fontSize={"14px"} fontWeight={"600"}>
								Update
							</Typography>
						</Button>
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "873px",
				backgroundColor: theme?.palette?.card?.background,
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				height: belowTablet ? "616px" : "auto",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};

const CustomTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			color={theme.palette?.pageHeading}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			sx={{
				opacity: "0.5",
			}}
			{...props}
		>
			{children}
		</Typography>
	);
};

export class MyStatefulEditor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editorValue: props.markup || "",
		};
	}

	onEditorChange = (value) => {
		this.setState({ editorValue: value });
	};

	render() {
		return (
			<React.Fragment>
				<div className="editor-design">
					<div style={{ width: "100%", position: "relative" }}>
						{/* Left Column */}
						<div style={{ flex: 1 }}>
							<ReactQuill
								value={this.state.editorValue}
								onChange={this.onEditorChange}
								modules={{
									toolbar: [
										[{ header: [1, 2, 3, false] }],
										["bold", "italic", "underline", "strike", "blockquote"],
										[{ list: "ordered" }, { list: "bullet" }],
										["link", "image"],
										["clean"],
									],
								}}
							/>
						</div>

						{/* Right Column */}
						<Box
							position={"absolute"}
							className="knowledge-base-editor-output"
							top={"3px"}
							right={0}
							width={"50%"}
						>
							<div
								style={{ flex: 1, display: "flex", flexDirection: "column" }}
							>
								{/* Right Column Content */}
								<div style={{ flex: 1, width: "100%" }}>
									<ReactQuill
										readOnly={true}
										value={this.state.editorValue}
										modules={{ toolbar: [] }}
									/>
								</div>
							</div>
						</Box>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
