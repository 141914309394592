import React, { useContext, useState } from "react";
import { useTheme } from "@mui/styles";
import { Box, Button,  Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LockedPage({message, setShowVideoModal, showWatchVideo}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<MainStep
					lightMode={lightMode}
					mode={mode}
					setShowVideoModal={setShowVideoModal}
					belowTablet={belowTablet}
					message={message}
					showWatchVideo={showWatchVideo}
				/>
		</MainContainer>
	);
}

const WatchVideoButton = ({ lightMode, belowTablet, ...props }) => {
	return (
		<Button
			size={"small"}
			sx={{
				width: "150px",
				marginBottom: belowTablet ? "10px" : "0px",
				height: belowTablet ? "100%" : "40px",
				backgroundColor: "transparent",
				border: "1px solid #0C84FE",
				padding: "5px 15px",
				color: "#FFF",
				":hover": {
					color: lightMode ? "#0081FF" : "#FFF",
				},
			}}
			variant="outlined"
			{...props}
		>
			<img src="/icons/modal/play.svg" alt="play"/>
			<Typography ml={1} color={"#0C84FE"} fontSize={"14px"} fontWeight={600}>
				Watch Video
			</Typography>
		</Button>
	);
};
const GetFeatureButton = ({ lightMode }) => {
	const navigate = useNavigate()
	return (
		<Button
			size={"small"}
			sx={{
				width: "150px",
				height: "40px",
				padding: "5px 15px",
				backgroundColor: "#0081FF",
				color: "#FFF",
				":hover": {
					color: lightMode ? "#0081FF" : "#FFF",
				},
			}}
			variant="outlined"
			onClick={() => navigate("/settings?tab=billing")}
		>
			<Typography fontSize={"14px"} fontWeight={600}>
				Get Feature
			</Typography>
		</Button>
	);
};

const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: belowTablet
					? "translate(-50%, -50%)"
					: "translate(-21%, -50%)",
				width: belowTablet ? "90%" : "465px",
				backgroundColor: lightMode ? "#FFF" : "#0C0D17",
				padding: belowTablet ? "30px 20px" : "40px 64px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
			}}
		>
			{children}
		</Box>
	);
};

const MainStep = ({
	lightMode,
	setShowVideoModal,
	belowTablet,
	setStepShow,
	message,
	showWatchVideo
}) => {
	return (
		<Box display={"flex"} flexDirection={"column"}>
			<Box display={"flex"} justifyContent={"center"}>
				<Box
					sx={{
						background: lightMode ? "#E6F3FF" : "rgba(230, 243, 255, 0.10)",
						width: "80px",
						height: "80px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						borderRadius: "100px",
					}}
					mb={2}
				>
					<img src="/icons/modal/lock.svg" alt="Icon" />
				</Box>
			</Box>

			<Heading lightMode={lightMode}>Upgrade to Unlock</Heading>
			<DescriptionText lightMode={lightMode}>
				{message}
			</DescriptionText>
			<Box
				display={"flex"}
				justifyContent={"space-around"}
				flexDirection={belowTablet ? "column" : "row"}
				alignItems={"center"}
			>
				{
					showWatchVideo ? (				
					<WatchVideoButton
						onClick={() => {
							setShowVideoModal(true);
						}}
						belowTablet={belowTablet}
					/>) : 
					(<></>)
				}
				<GetFeatureButton lightMode={lightMode} setStepShow={setStepShow} />
			</Box>
		</Box>
	);
};

const Heading = ({ children, lightMode, ...props }) => {
	return (
		<Typography
			color={lightMode ? "#000D31" : "#FFF"}
			fontSize={"24px"}
			fontWeight={600}
			mb={"12px"}
			textAlign={"center"}
			{...props}
		>
			{children}
		</Typography>
	);
};

const DescriptionText = ({ children, lightMode, ...props }) => {
	return (
		<Typography
			color={lightMode ? "#000D31" : "#FFF"}
			fontSize={"14px"}
			fontWeight={500}
			mb={3}
			sx={{
				opacity: "0.5",
				textAlign: "center",
			}}
            {...props}
		>
			{children}
		</Typography>
	);
};
