import React from "react";
import { Box, Typography, useMediaQuery, useTheme, Modal, Divider } from "@mui/material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import SecondaryButton from "components/Buttons/SecondaryButton";

export default function UnsubscribeCustomerModal({ open, handleClose, handleUnsubscribeConversation, phoneNumber }) {
  const theme = useTheme();
  const { mode } = theme.palette;
  const lightMode = mode === "light" ? true : false;
  const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Modal
      open={open}
      onClose={handleClose} 
      id='UnsubscribeCustomerModal'
      aria-labelledby='UnsubscribeCustomerModal'
      aria-describedby='UnsubscribeCustomerModal'>
      <MainContainer lightMode={lightMode} belowTablet={belowTablet}>
        <Box p={"20px 20px 13px 20px"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
            mb={"20px"}>
            <Box display={"flex"} gap={"4px"} alignItems={"center"}>
              <Icon />
              <Typography
                color={theme.palette?.pageHeading}
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"normal"}>
                Unsubscribe Customer?
              </Typography>
            </Box>
            <img
              src='/icons/cut.svg'
              alt='cut'
              onClick={handleClose}
              id='pointerCursor'
            />
          </Box>
          <Box>
            <CustomTypography>
              You’re about to unsubscribe {phoneNumber} from SMS Marketing. They will no longer
              receive texts from your brand and you will not be able to reply in their
              conversations.
              <br />
              <br />
              Do you wish to continue?
            </CustomTypography>
          </Box>
        </Box>

        <Divider />
        <Box p={"16px 17px"} display={"flex"} justifyContent={"end"} gap={"16px"}>
          <PrimaryButton padding={"11px 20px"} onClick={handleClose}>Cancel</PrimaryButton>
          <SecondaryButton padding={"11px 20px"} onClick={handleUnsubscribeConversation}>Unsubscribe</SecondaryButton>
        </Box>
      </MainContainer>
    </Modal>
  );
}

const MainContainer = ({ children, lightMode }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "556px",
        backgroundColor: theme?.palette?.card?.background,
        borderRadius: "12px",
        outline: "none",
        border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
        ":focus-visible": {
          border: "none",
        },

        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: 24,
        overflowY: "auto",
        maxWidth: "90vw",
      }}>
      {children}
    </Box>
  );
};

const CustomTypography = ({ children, ...props }) => {
  return (
    <Typography
      color={"#808698"}
      fontSize={"14px"}
      fontWeight={400}
      lineHeight={"normal"}
      {...props}>
      {children}
    </Typography>
  );
};

const Icon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M17.2071 15.2929C16.8166 14.9024 16.1834 14.9024 15.7929 15.2929C15.4024 15.6834 15.4024 16.3166 15.7929 16.7071L17.5858 18.5L15.7929 20.2929C15.4024 20.6834 15.4024 21.3166 15.7929 21.7071C16.1834 22.0976 16.8166 22.0976 17.2071 21.7071L19 19.9142L20.7929 21.7071C21.1834 22.0976 21.8166 22.0976 22.2071 21.7071C22.5976 21.3166 22.5976 20.6834 22.2071 20.2929L20.4142 18.5L22.2071 16.7071C22.5976 16.3166 22.5976 15.6834 22.2071 15.2929C21.8166 14.9024 21.1834 14.9024 20.7929 15.2929L19 17.0858L17.2071 15.2929Z'
      fill='#0081FF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.78618 14.5C8.26522 14.5003 9.74427 14.5006 11.2233 14.5C11.3424 14.4999 11.4847 14.4999 11.61 14.5101C11.7552 14.522 11.9632 14.5524 12.1811 14.6635C12.4634 14.8073 12.6928 15.0368 12.8367 15.319C12.9477 15.537 12.9782 15.745 12.99 15.8902C13.0003 16.0155 13.0002 16.1578 13.0002 16.2769C12.9997 17.5923 12.9997 18.9077 13.0002 20.2231C13.0002 20.3423 13.0003 20.4845 12.99 20.6098C12.9782 20.7551 12.9477 20.963 12.8367 21.181C12.6928 21.4632 12.4634 21.6927 12.1811 21.8365C11.9632 21.9476 11.7552 21.978 11.61 21.9899C11.4847 22.0001 11.3424 22.0001 11.2233 22C8.34592 21.999 5.46803 21.9847 2.59069 22.0001C2.51932 22.0005 2.37587 22.0012 2.2397 21.9772C1.61868 21.8677 1.13246 21.3815 1.02296 20.7605C0.99895 20.6243 0.999714 20.4809 1.00009 20.4095C1.00324 19.8186 0.973059 19.2163 1.07613 18.6318C1.44114 16.5617 3.06187 14.941 5.13193 14.576C5.56434 14.4997 6.0595 14.4998 6.78618 14.5Z'
      fill='#0081FF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.5 7.5C4.5 4.46243 6.96243 2 10.0001 2C13.0375 2 15.5 4.46243 15.5 7.5C15.5 10.5376 13.0375 13 10.0001 13C6.96243 13 4.5 10.5376 4.5 7.5Z'
      fill='#0081FF'
    />
  </svg>
)
