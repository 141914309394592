export const pages = [
	{
		title: "Home",
		href: "/",
		icon: "home.svg",
		darkIcon: "dark/home.svg",
		iconClick: "home-click.svg",
	},
	{
		title: "Cart Recovery",
		href: "/cart-recovery",
		icon: "cart.svg",
		darkIcon: "dark/cart.svg",
		iconClick: "cart-click.svg",
		children: [
			// Children of the "Parent Item"
			{
				title: "Orders",
				href: "/cart-recovery/orders",
				icon: "Orders",
			},
			{
				title: "Conversations",
				href: "/cart-recovery/conversations",
				icon: "Conversations",
			},
			// {
			// 	title: "Knowledge Base",
			// 	href: "/cart-recovery/knowledge-Base",
			// 	icon: "Knowledge-Base",
			// },
		],
	},
	{
		title: "Inbox",
		href: "/inbox",
		icon: "inbox.svg",
		darkIcon: "dark/inbox.svg",
		iconClick: "inbox-click.svg",
		newTag: true,
	},
	{
		title: "Campaigns",
		href: "/campaigns",
		icon: "campaign.svg",
		darkIcon: "dark/campaign.svg",
		iconClick: "campaign-click.svg",
		// children: [
			// Children of the "Parent Item"
			// {
			// 	title: "Lists",
			// 	href: "/campaigns/lists",
			// 	icon: "Lists",
			// },
			// {
			// 	title: "Segments",
			// 	href: "/campaigns/segments",
			// 	icon: "Segments",
			// },
		// ],
	},
	{
		title: "Campaign Planner",
		href: "/campaign-planner",
		iconClick: "campaign-planner-click.svg",
		icon: "campaign-planner.svg",
		darkIcon: "dark/campaign-planner.svg",
	},
	// {
	// 	title: "Flows",
	// 	href: "/flows",
	// 	iconClick: "flow-click.svg",
	// 	icon: "flow.svg",
	// 	darkIcon: "dark/flow.svg",
		// children: [
		// 	// Children of the "Parent Item"
		// 	{
		// 		title: "Automations",
		// 		href: "/flows/automations",
		// 		icon: "Automations",
		// 	},
		// 	{
		// 		title: "Flow Builder",
		// 		href: "/flows/flow-builder",
		// 		icon: "Flow-Builder",
		// 	},
		// ],
	// },
	// {
	// 	title: "Popups",
	// 	href: "/popups",
	// 	iconClick: "pop-up-click.svg",
	// 	icon: "pop-up.svg",
	// 	darkIcon: "/dark/pop-up.svg",
	// },
	{
		title: "Analytics",
		href: "/analytics",
		icon: "analytic.svg",
		darkIcon: "dark/analytic.svg",
		iconClick: "analytic-click.svg",
		children: [
			// Children of the "Parent Item"
			{
				title: "Insights",
				href: "/analytics/insights",
				icon: "Insights",
			},
			// {
			// 	title: "Flows",
			// 	href: "/analytics/flows",
			// 	icon: "Flows",
			// },
			{
				title: "Campaigns",
				href: "/analytics/campaigns",
				icon: "Flow-Builder",
			},
			{
				title: "Subscribers",
				href: "/analytics/subscribers",
				icon: "Subscribers",
			},
			// {
			// 	title: "Reports",
			// 	href: "/analytics/reports",
			// 	icon: "Reports",
			// },
		],
	},
	{
		title: "Trust Hub",
		href: "/trust-hub",
		icon: "trust-hub.svg",
		iconClick: "trust-hub-click.svg",
		darkIcon: "dark/trust-hub.svg",
	},
	{
		title: "Integrations",
		href: "/integrations",
		iconClick: "integration-click.svg",
		icon: "integration.svg",
		darkIcon: "dark/integration.svg",
	},
];

export const chartMock = [
	{ name: "Conversations", value: 0, date: 1 },
	{ name: "Conversations", value: 3, date: 2 },
	{ name: "Conversations", value: 3, date: 3 },
	{ name: "Conversations", value: 2, date: 4 },
	{ name: "Conversations", value: 1, date: 5 },

	{ name: "Orders", value: 3, date: 6 },
	{ name: "Orders", value: 2, date: 5 },
	{ name: "Orders", value: 3, date: 1 },
	{ name: "Orders", value: 2, date: 2 },
	{ name: "Orders", value: 2, date: 3 },
	{ name: "Orders", value: 2, date: 4 },
];

export const dashboardFeaturesMock = [
	{
		title: "Create your first SMS campaign",
		description:
			"Lorem ipsum dolor sit amet consectetur. Id viverra massa sed donec aenean nibh mauris lorem.",
		img: "campaign",
		action: "Create Campaign",
	},
	{
		title: "Activate your first automation",
		description:
			"Lorem ipsum dolor sit amet consectetur. Id viverra massa sed donec aenean nibh mauris lorem.",
		img: "automation",
		action: "Activate Automation",
	},
];

export const dashboardOnBoardingMock = [
	{
		isCompleted: true,
		title: "Enable your international phone numbers for UK & AUS",
	},
	{ isCompleted: true, title: "Create a pop-up to get more subscribers" },
	{
		isCompleted: false,
		title: "Create an SMS Flow to recover abandoned carts",
	},
];

export const planMock = [
	{
		id: 1,
		name: "Starter",
		desc: "For Solopreneurs and Small Businesses",
		priceMonthly: "$29",
		priceAnnually: "$24",
		discount: "$290 billed Annually",
		features: {
			heading: "EVERYTHING IN BASIC PLUS",
			keyFeatures: [
				"Real Human Reps + AI",
				"24hr & 48hr follow up texts",
				"SMS & MMS Campaigns",
				"Custom Discount Codes",
				"Campaign Planner",
				"Integrations",
			],
		},
	},

	{
		id: 2,
		name: "Growth",
		desc: "For Small to Medium Businesses",
		priceMonthly: "$79",
		priceAnnually: "$66",
		discount: "$790 billed Annually",
		features: {
			heading: "EVERYTHING IN STARTER PLUS",
			keyFeatures: [
				"Custom Personas",
				"10DLC Phone Numbers",
				"Advanced Segmentation",
				"Automations",
				"Inbox",
				"Popups",
			],
		},
	},
	{
		id: 3,
		name: "Pro",
		desc: "For Medium to Large Businesses",
		priceMonthly: "$299",
		priceAnnually: "$249",
		discount: "$2,990 billed Annually",
		features: {
			heading: "EVERYTHING IN GROWTH PLUS",
			keyFeatures: [
				"Contextual Insights",
				"Advanced Analytics",
				"Reports",
				"SMS Flow Builder & Flows",
				"Custom SMS Branded Links",
				"24/7 Live Support",
			],
		},
	},
	{
		id: 4,
		name: "Enterprise",
		desc: "For Large Businesses and Plus Stores",
		priceMonthly: "$999",
		priceAnnually: "$833",
		discount: "$9,990 billed Annually",
		features: {
			heading: "EVERYTHING IN PRO PLUS",
			keyFeatures: [
				"Dedicated account manager",
				"Personalized onboarding & setup",
				"Migration from previous platform",
				"Call/Text Support",
				"Custom Pricing Options",
			],
		},
	},
];

export const TimeSelection = [
	{
		action: "",
		label: "Today",
		value:"today"
	},
	{
		action: "",
		label: "Yesterday",
		value:"yesterday"
	},
	{
		action: "",
		label: "Last 7 Days",
		value:"last_days_7"
	},
	{
		action: "",
		label: "Last 30 Days",
		value:"last_days_30"
	},
	{
		action: "",
		label: "This Month",
		value:"current_month"
	},
	{
		action: "",
		label: "Last Month",
		value:"last_month"
	},
	{
		action: "",
		label: "This Year",
		value:"current_year"
	},
	{
		action: "",
		label: "Last Year",
		value:"last_year"
	},
	{
		action: "",
		label: "All Time",
		value:"all_time"
	},
];

export const OrderDataMock = [
	{
		id: 1,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 10 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 28.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 2,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 11 2023",
		ItemCount: "3",
		AppliedDiscount: 12,
		OrderTotal: 20.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 3,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 12 2023",
		ItemCount: "7",
		AppliedDiscount: 20,
		OrderTotal: 10.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 4,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 13 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 19.0,
		ViewOrder: "View on Shopify",
	},

	{
		id: 1,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 10 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 28.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 2,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 11 2023",
		ItemCount: "3",
		AppliedDiscount: 12,
		OrderTotal: 20.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 3,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 12 2023",
		ItemCount: "7",
		AppliedDiscount: 20,
		OrderTotal: 10.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 4,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 13 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 19.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 1,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 10 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 28.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 2,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 11 2023",
		ItemCount: "3",
		AppliedDiscount: 12,
		OrderTotal: 20.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 3,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 12 2023",
		ItemCount: "7",
		AppliedDiscount: 20,
		OrderTotal: 10.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 4,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 13 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 19.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 1,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 10 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 28.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 2,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 11 2023",
		ItemCount: "3",
		AppliedDiscount: 12,
		OrderTotal: 20.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 3,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 12 2023",
		ItemCount: "7",
		AppliedDiscount: 20,
		OrderTotal: 10.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 4,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 13 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 19.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 1,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 10 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 28.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 2,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 11 2023",
		ItemCount: "3",
		AppliedDiscount: 12,
		OrderTotal: 20.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 3,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 12 2023",
		ItemCount: "7",
		AppliedDiscount: 20,
		OrderTotal: 10.0,
		ViewOrder: "View on Shopify",
	},
	{
		id: 4,
		OrderID: "5383422673200",
		OrderDate: "Mon Jul 13 2023",
		ItemCount: "1",
		AppliedDiscount: 10,
		OrderTotal: 19.0,
		ViewOrder: "View on Shopify",
	},
];
export const campaignsDataMock = [
	{
		campaignName: "VIP Exclusive: Free Dad Hat 1",
		type: "template",
		sentOn: "3/10/23 4:37PM",
		status: "Sent",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 2",
		sentOn: "3/11/23 4:37PM",
		status: "Draft",
		type: "template",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 3",
		sentOn: "3/12/23 4:37PM",
		status: "Scheduled",
		type: "template",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 4",
		sentOn: "3/15/23 4:37PM",
		status: "Sending",
		type: "template",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 5",
		sentOn: "3/25/23 4:37PM",
		type: "template",
		status: "Error",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},

	{
		campaignName: "VIP Exclusive: Free Dad Hat 6",
		sentOn: "3/25/23 4:37PM",
		status: "Draft",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 7",
		sentOn: "3/25/23 4:37PM",
		status: "Scheduled",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 8",
		sentOn: "3/25/23 4:37PM",
		status: "Sending",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 9",
		sentOn: "3/25/23 4:37PM",
		status: "Error",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 10",
		sentOn: "3/25/23 4:37PM",
		status: "Error",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 11",
		sentOn: "3/25/23 4:37PM",
		status: "Scheduled",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 12",
		sentOn: "3/25/23 4:37PM",
		status: "Error",
		type: "Sending",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 13",
		sentOn: "3/25/23 4:37PM",
		status: "Sent",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 14",
		sentOn: "3/25/23 4:37PM",
		status: "Draft",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 15",
		sentOn: "3/25/23 4:37PM",
		status: "Error",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "$1,987.00",
		roi: "8.83X",
	},
];
export const campaignsDataAnalyticsMock = [
	{
		campaignName: "VIP Exclusive: Free Dad Hat 1",
		type: "template",
		sentOn: "3/11/23 4:37PM",
		status: "Sent",
		sent: "11,989",
		replyRate: "19.39%",
		ctr: "1.59%",
		revenue: "1,687.00",
		roi: "1.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 2",
		sentOn: "3/18/23 4:37PM",
		status: "Draft",
		type: "template",
		sent: "12,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "2,987.00",
		roi: "6.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 3",
		sentOn: "3/19/23 4:37PM",
		status: "Scheduled",
		type: "template",
		sent: "19,989",
		replyRate: "8.39%",
		ctr: "12.59%",
		revenue: "5,987.00",
		roi: "7.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 4",
		sentOn: "3/2/23 4:37PM",
		status: "Sending",
		type: "template",
		sent: "2,989",
		replyRate: "7.39%",
		ctr: "1.59%",
		revenue: "1,957.00",
		roi: "8.13X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 5",
		sentOn: "3/21/23 4:37PM",
		type: "template",
		status: "Error",
		sent: "10,989",
		replyRate: "11.39%",
		ctr: "10.49%",
		revenue: "1,287.00",
		roi: "6.83X",
	},

	{
		campaignName: "VIP Exclusive: Free Dad Hat 6",
		sentOn: "3/22/23 4:37PM",
		status: "Draft",
		type: "custom",
		sent: "11,989",
		replyRate: "1.39%",
		ctr: "10.96%",
		revenue: "8,287.00",
		roi: "9.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 7",
		sentOn: "3/23/23 4:37PM",
		status: "Scheduled",
		type: "custom",
		sent: "15,919",
		replyRate: "19.39%",
		ctr: "1.59%",
		revenue: "8,987.00",
		roi: "1.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 8",
		sentOn: "3/24/23 4:37PM",
		status: "Sending",
		type: "custom",
		sent: "15,929",
		replyRate: "14.39%",
		ctr: "18.59%",
		revenue: "7,987.00",
		roi: "6.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 9",
		sentOn: "3/25/23 4:37PM",
		status: "Error",
		type: "custom",
		sent: "18,989",
		replyRate: "12.39%",
		ctr: "18.59%",
		revenue: "6,987.00",
		roi: "1.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 10",
		sentOn: "3/25/23 4:37PM",
		status: "Error",
		type: "custom",
		sent: "5,989",
		replyRate: "6.39%",
		ctr: "0.59%",
		revenue: "8,987.00",
		roi: "2.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 11",
		sentOn: "3/25/23 4:37PM",
		status: "Scheduled",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 12",
		sentOn: "3/25/23 4:37PM",
		status: "Error",
		type: "Sending",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 13",
		sentOn: "3/25/23 4:37PM",
		status: "Sent",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 14",
		sentOn: "3/25/23 4:37PM",
		status: "Draft",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "1,987.00",
		roi: "8.83X",
	},
	{
		campaignName: "VIP Exclusive: Free Dad Hat 15",
		sentOn: "3/25/23 4:37PM",
		status: "Error",
		type: "custom",
		sent: "15,989",
		replyRate: "16.39%",
		ctr: "10.59%",
		revenue: "1,987.00",
		roi: "8.83X",
	},
];
export const StatusOptions = [
	{
		value: "all",
		label: "All",
	},
	{
		value: "draft",
		label: "Draft",
	},
	{
		value: "sent",
		label: "Sent",
	},
	{
		value: "scheduled",
		label: "Scheduled",
	},
	{
		value: "sending",
		label: "Sending",
	},
];
export const LastModifiedOptions = [
	{
		action: "",
		label: "Today",
	},
	{
		action: "",
		label: "Yesterday",
	},
];
export const campaignGraphData = [
	{ text: 4900, revenue: 5000, date: "1" },
	{ text: 6900, revenue: 7000, date: "2" },
	{ text: 8900, revenue: 9000, date: "3" },
	{ text: 11900, revenue: 12000, date: "4" },
	{ text: 13900, revenue: 14000, date: "5" },
	{ text: 14900, revenue: 15000, date: "6" },
	{ text: 14400, revenue: 14500, date: "7" },
	{ text: 13800, revenue: 14000, date: "8" },
	{ text: 13400, revenue: 13500, date: "9" },
	{ text: 12900, revenue: 13000, date: "10" },
	{ text: 12100, revenue: 12200, date: "11" },
	{ text: 11600, revenue: 11700, date: "12" },
	{ text: 11100, revenue: 11200, date: "13" },
	{ text: 10700, revenue: 10800, date: "14" },
	{ text: 10200, revenue: 10300, date: "15" },
	{ text: 11200, revenue: 11300, date: "16" },
	{ text: 12200, revenue: 12300, date: "17" },
	{ text: 13200, revenue: 13300, date: "18" },
	{ text: 14200, revenue: 14300, date: "19" },
	{ text: 15200, revenue: 15300, date: "20" },
	{ text: 14200, revenue: 16300, date: "21" },
	{ text: 13200, revenue: 17300, date: "22" },
	{ text: 12200, revenue: 18300, date: "23" },
	{ text: 11200, revenue: 19300, date: "24" },
	{ text: 10200, revenue: 18300, date: "25" },
	{ text: 9200, revenue: 17300, date: "26" },
	{ text: 8200, revenue: 16300, date: "27" },
	{ text: 7200, revenue: 15300, date: "28" },
	{ text: 6200, revenue: 14300, date: "29" },
	{ text: 5200, revenue: 13300, date: "30" },
];
export const subscriberGraphData = [
	{ subscriber: 3.47, date: "1" },
	{ subscriber: 2.12, date: "2" },
	{ subscriber: 1.76, date: "3" },
	{ subscriber: 0.81, date: "4" },
	{ subscriber: 3.64, date: "5" },
	{ subscriber: 0.93, date: "6" },
	{ subscriber: 1.28, date: "7" },
	{ subscriber: 2.83, date: "8" },
	{ subscriber: 3.12, date: "9" },
	{ subscriber: 0.52, date: "10" },
	{ subscriber: 2.05, date: "11" },
	{ subscriber: 1.35, date: "12" },
	{ subscriber: 2.25, date: "13" },
	{ subscriber: 1.67, date: "14" },
	{ subscriber: 1.48, date: "15" },
	{ subscriber: 1.9, date: "16" },
	{ subscriber: 3.26, date: "17" },
	{ subscriber: 0.74, date: "18" },
	{ subscriber: 1.59, date: "19" },
	{ subscriber: 3.84, date: "20" },
	{ subscriber: 2.41, date: "21" },
	{ subscriber: 0.62, date: "22" },
	{ subscriber: 1.03, date: "23" },
	{ subscriber: 3.98, date: "24" },
	{ subscriber: 0.4, date: "25" },
	{ subscriber: 1.14, date: "26" },
	{ subscriber: 0.18, date: "27" },
	{ subscriber: 3.39, date: "28" },
	{ subscriber: 1.8, date: "29" },
	{ subscriber: 1.42, date: "30" },
];

export const ListsMock = [
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
];
export const reportMock = [
	{
		reportName: "Active Buyers",
		channel: "Slack",
		frequency: "Daily, 3:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 12 2023",
		Status: "In Progress",
	},
	{
		reportName: "Active Buyers",
		channel: "Email",
		frequency: "Daily, 3:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 18 2023",
		Status: "In Progress",
	},
	{
		reportName: "Active Buyers",
		channel: "Email",
		frequency: "Daily, 3:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 18 2023",
		Status: "Complete",
	},
	{
		reportName: "Active Buyers",
		channel: "Slack",
		frequency: "Daily, 3:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 20 2023",
		Status: "Complete",
	},
	{
		reportName: "Active Buyers",
		channel: "Email",
		frequency: "Weekly, 12:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 18 2023",
		Status: "Complete",
	},
	{
		reportName: "Active Buyers",
		channel: "Slack",
		frequency: "Daily, 3:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 11 2023",
		Status: "Complete",
	},
	{
		reportName: "Active Buyers",
		channel: "Email",
		frequency: "Weekly, 12:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 24 2023",
		Status: "Complete",
	},
	{
		reportName: "Active Buyers",
		channel: "Slack",
		frequency: "Weekly, 12:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 5 2023",
		Status: "In Progress",
	},
	{
		reportName: "Active Buyers",
		channel: "Email",
		frequency: "Weekly, 12:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 1 2023",
		Status: "Complete",
	},
	{
		reportName: "Active Buyers",
		channel: "Slack",
		frequency: "Weekly, 12:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 3 2023",
		Status: "Complete",
	},
	{
		reportName: "Active Buyers",
		channel: "Email",
		frequency: "Daily, 3:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 5 2023",
		Status: "In Progress",
	},
	{
		reportName: "Active Buyers",
		channel: "Slack",
		frequency: "Daily, 3:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 6 2023",
		Status: "In Progress",
	},
	{
		reportName: "Active Buyers",
		channel: "Email",
		frequency: "Weekly, 12:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 8 2023",
		Status: "In Progress",
	},
	{
		reportName: "Active Buyers",
		channel: "Email",
		frequency: "Weekly, 12:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 11 2023",
		Status: "In Progress",
	},
	{
		reportName: "Active Buyers",
		channel: "Slack",
		frequency: "Daily, 3:00 PM",
		summary: "Order Revenue, AOV",
		more: "Lorem ipsum dolor sit amet consectetur. Volutpat in justo amet.",
		createdOn: "Tue, July 19 2023",
		Status: "In Progress",
	},
];
export const insightsMock = [
	{
		sn: "1",
		productName: "Product Name",
		recoveries: "11,068",
		recovered: "5.94%",
	},
	{
		sn: "2",
		productName: "Product Name",
		recoveries: "12,068",
		recovered: "6.94%",
	},
	{
		sn: "3",
		productName: "Product Name",
		recoveries: "13,068",
		recovered: "7.94%",
	},
	{
		sn: "4",
		productName: "Product Name",
		recoveries: "14,068",
		recovered: "8.94%",
	},
	{
		sn: "5",
		productName: "Product Name",
		recoveries: "15,068",
		recovered: "9.94%",
	},
];
export const flowsAnalyticsMock = [
	{
		name: "Abandoned Cart",
		sent: "15,068",
		clicks: "12,345",
		ctr: "7.21%",
		orders: "482",
		revenue: "3876.45",
	},
	{
		name: "Abandoned Cart - New Customer",
		sent: "15,068",
		clicks: "18,762",
		ctr: "9.83%",
		orders: "781",
		revenue: "2345.67",
	},
	{
		name: "Abandoned Cart - Returning Customer",
		sent: "15,068",
		clicks: "14,209",
		ctr: "6.45%",
		orders: "598",
		revenue: "4567.89",
	},
	{
		name: "Auto-Response Message",
		sent: "15,068",
		clicks: "9,876",
		ctr: "4.32%",
		orders: "345",
		revenue: "1234.56",
	},
	{
		name: "Browse Abandonment - Add to Cart",
		sent: "15,068",
		clicks: "16,543",
		ctr: "8.97%",
		orders: "654",
		revenue: "5432.10",
	},
	{
		name: "Convert Subscriber - No order 30 days",
		sent: "15,068",
		clicks: "11,234",
		ctr: "5.76%",
		orders: "723",
		revenue: "2109.87",
	},
	{
		name: "Customer Win-Back",
		sent: "15,068",
		clicks: "13,567",
		ctr: "7.89%",
		orders: "432",
		revenue: "6543.21",
	},
	{
		name: "Loyalty Offer",
		sent: "15,068",
		clicks: "15,678",
		ctr: "6.54%",
		orders: "567",
		revenue: "8765.43",
	},
	{
		name: "Post Purchase Upsell",
		sent: "15,068",
		clicks: "10,876",
		ctr: "4.98%",
		orders: "678",
		revenue: "9876.54",
	},
	{
		name: "Shipping Notifications - Order Delivered",
		sent: "15,068",
		clicks: "17,345",
		ctr: "8.76%",
		orders: "789",
		revenue: "4321.09",
	},
	{
		name: "Shipping Notifications - Order Shipped",
		sent: "15,068",
		clicks: "12,543",
		ctr: "5.32%",
		orders: "543",
		revenue: "7654.32",
	},
	{
		name: "Subscribed, No Order In First 30 Days",
		sent: "15,068",
		clicks: "14,109",
		ctr: "6.89%",
		orders: "876",
		revenue: "3210.98",
	},
	{
		name: "Welcome Series",
		sent: "15,068",
		clicks: "16,789",
		ctr: "7.43%",
		orders: "987",
		revenue: "1098.76",
	},
	{
		name: "Product Review Request",
		sent: "15,068",
		clicks: "11,567",
		ctr: "5.12%",
		orders: "345",
		revenue: "6789.01",
	},
];

export const subscriberTableMock = [
	{
		name: "Chance Saris",
		subscriberDate: "3/01/23 4:37PM",
		source: "Online Store",
		country: "United States",
		orders: "3",
		spend: "1,087.00",
	},
	{
		name: "Gretchen Stanton",
		subscriberDate: "3/25/23 4:37PM",
		source: "Online Store",
		country: "United States",
		orders: "2",
		spend: "1,187.00",
	},
	{
		name: "Omar Saris",
		subscriberDate: "3/02/23 4:37PM",
		source: "Online Store",
		country: "United States",
		orders: "15",
		spend: "1,287.00",
	},
	{
		name: "Chance Schleifer",
		subscriberDate: "3/25/23 4:37PM",
		source: "Online Store",
		country: "Australia",
		orders: "4",
		spend: "2,987.00",
	},
	{
		name: "Omar Mango",
		subscriberDate: "3/25/23 4:37PM",
		source: "Online Store",
		country: "Australia",
		orders: "2",
		spend: "1,957.00",
	},
	{
		name: "Zain George",
		subscriberDate: "3/25/23 4:37PM",
		source: "Online Store",
		country: "Mexico",
		orders: "12",
		spend: "2,187.00",
	},
	{
		name: "Ruben Lipshutz",
		subscriberDate: "3/25/23 4:37PM",
		source: "Online Store",
		country: "Australia",
		orders: "52",
		spend: "2,187.00",
	},
	{
		name: "Lincoln Kenter",
		subscriberDate: "3/25/23 4:37PM",
		source: "Online Store",
		country: "Australia",
		orders: "2",
		spend: "3,997.00",
	},
	{
		name: "Phillip Dorwart",
		subscriberDate: "3/25/23 4:37PM",
		source: "Online Store",
		country: "Canada",
		orders: "0",
		spend: "1,927.00",
	},
	{
		name: "Tiana Gouse",
		subscriberDate: "3/25/23 4:37PM",
		source: "Online Store",
		country: "United States",
		orders: "3",
		spend: "1,887.00",
	},
];

export const segmentsMock = [
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
	{
		name: "Active Buyers",
		contact: "52",
		lastUpdated: "Tue, July 18 2023",
	},
];

export const targetAudienceOptionMock = [
	"All Customers - (8239)",
	"All US/CAD Customers - (122)",
	"All UK/AUS Customers - (438)",
	"Abandoned Checkout Not Recovered - (3489)",
	"30+ days No Contact - (383)",
	"Email Opt In Customers - (7837)",
	"Customers Texted More than Once - (988)",
	"Customers with One Purchase - (219)",
	"Customers with 2+ Purchases - (87)",
	"Purchased in Last 30 Days - (99)",
	"No Purchase in Last 7 Days - (3737)",
	"Total Spend Under $100 - (200)",
	"Total Spend Between $101-499 - (438)",
	"Total Spend Over $500 - (73)",
];

export const AdditionalTermsMock = `
<Typography
					fontSize={15}
					fontWeight={600}
					color={"#FFF"}
					lineHeight={"20px"}
                    mb={'24px'}
				>
					These terms have been copied to your clipboard, paste them into your
					Store's Terms of Service.
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>
					SMS/MMS MOBILE MESSAGE MARKETING PROGRAM TERMS AND CONDITIONS
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>
					We are offering a mobile messaging program (the "Program"), which you
					agree to use and participate in subject to these Mobile Messaging
					Terms and Conditions and Privacy Policy (the "Agreement"). By opting
					in to or participating in any of our Programs, you accept and agree to
					these terms and conditions, including, without limitation, your
					agreement to resolve any disputes with us through binding,
					individual-only arbitration, as detailed in the "Dispute Resolution"
					section below. This Agreement is limited to the program and is not
					intended to modify other Terms and Conditions or Privacy Policy that
					may govern the relationship between you and Us in other contexts.
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>
					The Program allows users to receive SMS/MMS mobile messages by
					affirmatively opting into the program, such as through online or
					application-based enrollment forms. Regardless of the opt-in method
					you utilized to join the Program, you agree that this Agreement
					applies to your participation in the program. By participating in the
					program, you agree to receive automated or prerecorded marketing
					mobile messages at the phone number associated with your opt-in, and
					you understand that consent is not required to make any purchase from
					Us. While you consent to receive messages sent using an autodialer,
					the foregoing shall not be interpreted to suggest or imply that any or
					all of our mobile messages are sent using an automatic telephone
					dialing system ("ATDS" or "autodialer").
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>
					If you do not wish to continue participating in the Program or no
					longer agree to this Agreement, you agree to reply STOP to any mobile
					text message from Us in order to opt out of the program. You may
					receive an additional mobile message confirming your decision to
					opt-out. You understand and agree that the foregoing options are the
					only reasonable methods of opting out. You understand and agree that
					attempting to opt out by texting other words or verbally requesting
					one of our team members to remove you from our list is not accounted
					for as a reasonable means of opting out.
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>
					You acknowledge that the message frequency is various and that consent
					is not a condition to purchase. The program involves recurring mobile
					messages, and additional mobile messages may be sent periodically
					based on your interaction with us.
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
				>
					You must have a wireless device of your own, be capable of two-way
				</Typography>
`;

export const OrderOverviewMock = [
	{
		name: "Jan",
		cartRecovery: 10,
		campaigns: 10,
		flow: 13,
		automation: 13,
	},
	{
		name: "Feb",
		cartRecovery: 41,
		campaigns: 31,
		flow: 31,
		automation: 31,
	},
	{
		name: "Mar",
		cartRecovery: 61,
		campaigns: 51,
		flow: 41,
		automation: 21,
	},
	{
		name: "Apr",
		cartRecovery: 83,
		campaigns: 60,
		flow: 63,
		automation: 45,
	},
	{
		name: "May",
		cartRecovery: 75,
		campaigns: 33,
		flow: 60,
		automation: 55,
	},
	{
		name: "Jun",
		cartRecovery: 27,
		campaigns: 31,
		flow: 40,
		automation: 45,
	},
	{
		name: "Jul",
		cartRecovery: 30,
		campaigns: 34,
		flow: 23,
		automation: 32,
	},
	{
		name: "Aug",
		cartRecovery: 50,
		campaigns: 70,
		flow: 60,
		automation: 40,
	},
	{
		name: "Sep",
		cartRecovery: 63,
		campaigns: 60,
		flow: 30,
		automation: 20,
	},
	{
		name: "Oct",
		cartRecovery: 81,
		campaigns: 69,
		flow: 23,
		automation: 43,
	},
	{
		name: "Nov",
		cartRecovery: 40,
		campaigns: 47,
		flow: 54,
		automation: 23,
	},
	{
		name: "Dec",
		cartRecovery: 60,
		campaigns: 39,
		flow: 34,
		automation: 56,
	},
];

export const AverageAOVMock = [
	{
		name: "Jan",
		cartRecovery: 10,
		campaigns: 10,
		flow: 13,
		automation: 13,
	},
	{
		name: "Feb",
		cartRecovery: 41,
		campaigns: 31,
		flow: 31,
		automation: 31,
	},
	{
		name: "Mar",
		cartRecovery: 61,
		campaigns: 51,
		flow: 41,
		automation: 21,
	},
	{
		name: "Apr",
		cartRecovery: 83,
		campaigns: 60,
		flow: 63,
		automation: 45,
	},
	{
		name: "May",
		cartRecovery: 75,
		campaigns: 33,
		flow: 60,
		automation: 55,
	},
	{
		name: "Jun",
		cartRecovery: 150,
		campaigns: 180,
		flow: 40,
		automation: 45,
	},
	{
		name: "Jul",
		cartRecovery: 30,
		campaigns: 34,
		flow: 23,
		automation: 32,
	},
	{
		name: "Aug",
		cartRecovery: 50,
		campaigns: 70,
		flow: 60,
		automation: 40,
	},
	{
		name: "Sep",
		cartRecovery: 63,
		campaigns: 60,
		flow: 30,
		automation: 170,
	},
	{
		name: "Oct",
		cartRecovery: 81,
		campaigns: 69,
		flow: 23,
		automation: 43,
	},
	{
		name: "Nov",
		cartRecovery: 40,
		campaigns: 47,
		flow: 54,
		automation: 132,
	},
	{
		name: "Dec",
		cartRecovery: 60,
		campaigns: 39,
		flow: 34,
		automation: 56,
	},
];

export const conversationsTableMock = [
	{
		id: 1,
		number: "+1 (614) 379-2032",
		name: "Ruben Philips",
		lastMessage: "1 Messages",
		status: "Awaiting Response",
	},
	{
		id: 2,
		number: "+1 (614) 379-2032",
		name: "Maria Mango",
		lastMessage: "3 Messages",
		status: "Discount Offered",
	},
	{
		id: 3,
		number: "+1 (614) 379-2032",
		name: "Craig Franci",
		lastMessage: "5 Messages",
		status: "Ordered",
	},
	{
		id: 4,
		number: "+1 (614) 379-2032",
		name: "Madelyn Bator",
		lastMessage: "2 Messages",
		status: "Awaiting Response",
	},
	{
		id: 5,
		number: "+1 (614) 379-2032",
		name: "Erin Levin",
		lastMessage: "1 Messages",
		status: "Cart Viewed",
	},

	{
		id: 6,
		number: "+1 (614) 379-2032",
		name: "Ruben Philips",
		lastMessage: "1 Messages",
		status: "Awaiting Response",
	},
	{
		id: 7,
		number: "+1 (614) 379-2032",
		name: "Maria Mango",
		lastMessage: "3 Messages",
		status: "Discount Offered",
	},
	{
		id: 8,
		number: "+1 (614) 379-2032",
		name: "Craig Franci",
		lastMessage: "5 Messages",
		status: "Ordered",
	},
	{
		id: 9,
		number: "+1 (614) 379-2032",
		name: "Madelyn Bator",
		lastMessage: "2 Messages",
		status: "Awaiting Response",
	},
	{
		id: 10,
		number: "+1 (614) 379-2032",
		name: "Erin Levin",
		lastMessage: "1 Messages",
		status: "Cart Viewed",
	},
	{
		id: 11,
		number: "+1 (614) 379-2032",
		name: "Ruben Philips",
		lastMessage: "1 Messages",
		status: "Awaiting Response",
	},
	{
		id: 12,
		number: "+1 (614) 379-2032",
		name: "Maria Mango",
		lastMessage: "3 Messages",
		status: "Ordered",
	},
	{
		id: 13,
		number: "+1 (614) 379-2032",
		name: "Craig Franci",
		lastMessage: "5 Messages",
		status: "Awaiting Response",
	},
	{
		id: 14,
		number: "+1 (614) 379-2032",
		name: "Madelyn Bator",
		lastMessage: "2 Messages",
		status: "Cart Viewed",
	},
	{
		id: 15,
		number: "+1 (614) 379-2032",
		name: "Erin Levin",
		lastMessage: "1 Messages",
		status: "Ordered",
	},

	{
		id: 16,
		number: "+1 (614) 379-2032",
		name: "Ruben Philips",
		lastMessage: "1 Messages",
		status: "Awaiting Response",
	},
	{
		id: 17,
		number: "+1 (614) 379-2032",
		name: "Maria Mango",
		lastMessage: "3 Messages",
		status: "Discount Offered",
	},
	{
		id: 18,
		number: "+1 (614) 379-2032",
		name: "Craig Franci",
		lastMessage: "5 Messages",
		status: "Ordered",
	},
	{
		id: 19,
		number: "+1 (614) 379-2032",
		name: "Madelyn Bator",
		lastMessage: "2 Messages",
		status: "Awaiting Response",
	},
	{
		id: 20,
		number: "+1 (614) 379-2032",
		name: "Erin Levin",
		lastMessage: "1 Messages",
		status: "Cart Viewed",
	},

	{
		id: 21,
		number: "+1 (614) 379-2032",
		name: "Ruben Philips",
		lastMessage: "1 Messages",
		status: "Awaiting Response",
	},
	{
		id: 22,
		number: "+1 (614) 379-2032",
		name: "Maria Mango",
		lastMessage: "3 Messages",
		status: "Discount Offered",
	},
	{
		id: 23,
		number: "+1 (614) 379-2032",
		name: "Craig Franci",
		lastMessage: "5 Messages",
		status: "Ordered",
	},
	{
		id: 24,
		number: "+1 (614) 379-2032",
		name: "Madelyn Bator",
		lastMessage: "2 Messages",
		status: "Awaiting Response",
	},
	{
		id: 25,
		number: "+1 (614) 379-2032",
		name: "Erin Levin",
		lastMessage: "1 Messages",
		status: "Cart Viewed",
	},
	{
		id: 26,
		number: "+1 (614) 379-2032",
		name: "Ruben Philips",
		lastMessage: "1 Messages",
		status: "Awaiting Response",
	},
	{
		id: 26,
		number: "+1 (614) 379-2032",
		name: "Maria Mango",
		lastMessage: "3 Messages",
		status: "Ordered",
	},
	{
		id: 28,
		number: "+1 (614) 379-2032",
		name: "Craig Franci",
		lastMessage: "5 Messages",
		status: "Awaiting Response",
	},
	{
		id: 29,
		number: "+1 (614) 379-2032",
		name: "Madelyn Bator",
		lastMessage: "2 Messages",
		status: "Cart Viewed",
	},
	{
		id: 30,
		number: "+1 (614) 379-2032",
		name: "Erin Levin",
		lastMessage: "1 Messages",
		status: "Ordered",
	},
];

export const affilateTableMock = [
	{
		number: 1,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Free",
	},
	{
		number: 2,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Starter",
	},
	{
		number: 3,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Growth",
	},
	{
		number: 4,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Pro",
	},
	{
		number: 5,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Starter",
	},
	{
		number: 6,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Free",
	},
	{
		number: 7,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Starter",
	},
	{
		number: 8,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Growth",
	},
	{
		number: 9,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Pro",
	},
	{
		number: 10,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Starter",
	},
	{
		number: 11,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Pro",
	},
	{
		number: 12,
		storeName: "Roblox Racing",
		registerDate: "05/23/23",
		plan: "Starter",
	},
];
