import React from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
} from "@mui/material";

export default function WelcomeV2({setOnboarding, ABTest}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleClick = function(){
		setOnboarding(2.4);
	}
	
	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
			<Box>
				<Box display={"flex"} justifyContent={"center"} mb={"6px"}>
					<img src="/img/onboarding/celebrate.svg" alt="celebrate"/>
				</Box>
				<Box textAlign={"center"} mb={"38px"}>
					<Typography
						fontSize={isMobile ? 22 : 30}
						fontWeight={600}
						lineHeight={"32px"}
						color={"#FFF"}
						mb={"12px"}
					>
						Hello and welcome to TxtCart!
					</Typography>

					<Typography
						fontSize={isMobile ? 14 : 14}
						fontWeight={500}
						lineHeight={"20px"}
						color={"#FFF"}
						sx={{
							opacity: 0.5,
						}}
					>
						Here’s how we can help your store
					</Typography>
				</Box>
				<Box display={"flex"} flexDirection={"column"} gap={"27px"}>
					{dataMock?.map((item, index) => {
						return (
							<Box display={"flex"} gap={"14px"} key={index}>
								<img src={item?.img} alt="img"/>
								<Typography
									fontSize={isMobile ? 20 : 20}
									fontWeight={500}
									lineHeight={"32px"}
									color={"#FFF"}
								>
									{item?.title}
								</Typography>
							</Box>
						);
					})}
				</Box>
				<Box display={"flex"} justifyContent={"center"} mt={'65px'} mb={'16px'}>
					<Button
						size={"small"}
						sx={{
							// width: "150px",
							height: "40px",
							padding: "5px 15px",
							backgroundColor: "#0081FF",
							color: "#FFF",
							":hover": {
								color: lightMode ? "#0081FF" : "#FFF",
							},
						}}
						variant="outlined"
                        onClick={() => handleClick()}
					>
						<Typography fontSize={"14px"} fontWeight={600}>
							Experience the TxtCart Magic
						</Typography>
						<img
							src="/img/onboarding/next-arrow.svg"
							style={{
								marginLeft: "12px",
							}}
							alt="tick"
						/>
					</Button>
				</Box>
			</Box>
		</MainContainer>
	);
}
const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "739px",
				p: "53px 42px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				"::-webkit-scrollbar": {
					display: "none",
				},
				// height: "578px",
			}}
		>
			{children}
		</Box>
	);
};

const dataMock = [
	{
		img: "/img/onboarding/dollar.svg",
		title: "Increase your ability to make more sales",
	},
	{
		img: "/img/onboarding/cart.svg",
		title: "Recover more abandoned carts",
	},
	{
		img: "/img/onboarding/annouce.svg",
		title: "Target customers who opt into SMS marketing",
	},
	{
		img: "/img/onboarding/cursor.svg",
		title: "Improve engagement by bringing subscribers back to your store",
	},
];
