import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";

export default function MoblieOnboardingStep({
	index,
	mode,
	text,
	isCompleted,
	isLargeScreen,
	isLast,
	handleEnableClick,
	handleSkipClick,
	step
}) {
	const lightMode = mode === "light" ? true : false;

	return (
		<Grid
			container
			lg={12}
			alignItems={"center"}
			margin={"0 15px"}
			justifyContent={"space-between"}
			padding={"16px"}
			sx={{
				background: mode === "dark" && "rgba(42, 43, 55, 0.50)",
				marginBottom: "20px",
				":active": {
					background: "#E8EBF3",
				},
			}}
			borderRadius={"12px"}
			border={lightMode ? "1px solid #EAECF0" : "none"}
			onClick={() => handleEnableClick(step,isCompleted)}
		>
			<Grid item xs={isCompleted ? 12 : 9}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					{isCompleted && (
						<Box display={'flex'} >
							<Box
								mr={"10px"}
								sx={{
									width: "28px",
									height: "28px",
									background: "rgb(12, 132, 254,0.15)",
									borderRadius: "50%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<img src="/icons/check.svg" width={"15px"} alt="check" />
							</Box>
						</Box>
					)}

					<Typography
						fontSize={isLargeScreen ? "18px" : "16px"}
						fontWeight={"600"}
						sx={{
							opacity: isCompleted ? "0.35" : "1",
						}}
					>
						{text}
					</Typography>
				</Box>
			</Grid>
			{!isCompleted && (
				<Grid container xs={3} justifyContent={"end"}>
					<Box>
						<img
							src={
								lightMode
									? "/arrow/arrow-right.svg"
									: "/arrow/dark/arrow-right.svg"
							}
							alt="SVG"
						/>
					</Box>
				</Grid>
			)}
		</Grid>
	);
}
