/* eslint-disable no-unused-vars */
import React from "react";
// import "./index.css";
import { Table } from "antd";
import { styled, useTheme } from "@mui/material";

const SettingsTable = ({
	mode,
	columns = [],
	rows = [],
	page = "mainPage",
	TableHead,
	border = false,
}) => {
	// console.log(mode, columns, rows, page, TableHead);

	const theme = useTheme();

	return (
		<>
			<div
				className="conversationTable"
				style={{
					background: theme.palette?.card?.background,
					// background: 'red',

					borderRadius: "12px",
					padding: 0,
					margin: 0,
				}}
			>
				<CustomTable
					border={border}
					columns={columns}
					TableHead={TableHead}
					dataSource={rows}
					page={page}
					rowClassName="No-hover"
					pagination={false}
				/>
			</div>
		</>
	);
};

export default SettingsTable;

const CustomTable = styled(Table)(({ theme, border }) => ({
	"& .ant-table-thead .ant-table-cell": {
		backgroundColor: theme.palette?.card?.background,
		padding: "10px  0  10px 20px !important",
		// minWidth: "100px",
	},
	"& .ant-table-thead th:first-child": {
		"border-start-start-radius": border ? "11px !important" : "0px !important",
	},
	"& .ant-table-thead th:last-child": {
		"border-start-end-radius": border ? "11px !important" : "0px !important",
	},
	"& .ant-table-tbody tr:last-child td:first-child": {
		"border-end-start-radius": "11px !important",
	},
	"& .ant-table-tbody tr:last-child  td:last-child": {
		"border-end-end-radius": "11px !important",
	},

	"& .ant-table  .ant-table-content": {
		overflow: "auto",
		height: "auto",
	},
	"& .ant-table  table": {
		width: "100%",
		borderRadius: "12px",
	},
	"& .No-hover .ant-table-cell-row-hover": {
		background:
			theme.palette?.mode === "light" ? "#FFF !important" : "#0F111B !important",
	},

	"& .ant-table": {
		PointerEvent: "none",
		borderRadius: border ? "12px !important" : "0px !important",
		border: border
			? `1px solid ${theme.palette?.card?.border} !important`
			: "none !important",
		borderBottomRightRadius: "12px !important",
		borderBottomLeftRadius: "12px !important",
	},
	"& .ant-table-thead >tr>th ": {
		borderBottom: `1px solid ${
			theme.palette?.mode === "light" ? "#E8EBF3" : "#1E202D"
		}`,
	},
	"& .ant-table-tbody tr:last-child td": {
		borderBottom: "none !important",
	},
	"& .ant-table-tbody >tr >td": {
		borderBottom: `1px solid ${
			theme.palette?.mode === "light" ? "#E8EBF3" : "#1E202D"
		}`,
		padding: "15px  0  17px 20px !important",
		backgroundColor: theme.palette?.card?.background,
	},
	"& .ant-table-thead >tr>th ::before": {
		backgroundColor: "transparent !important",
	},
	"& .ant-table-thead .ant-table-cell::before": {
		display: "none",
	},

	"& .ant-table-content::-webkit-scrollbar": {
		height: "3px",
		width: "2px" /* Width of the entire scrollbar */,
		background:
			theme.palette?.card?.background /* Background color of the track */,
	},
}));
