import React from "react";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import RenderBoxContent from "./RenderBoxContent";

const SkeletonText = ({ width }) => (
	<Skeleton
		variant="text"
		animation="wave"
		sx={{ fontSize: "1rem", bgcolor: "rgba(0, 13, 49, 0.50)" }}
		height="10px"
		width={width}
	/>
);

export default function ConversationEmptyState({ isMobile }) {
	const countForChat = 7;

	return (
		<RenderBoxContent isMobile={isMobile} tabName="Info" maxWidth="343px">
			{Array.from({ length: countForChat }).map((_, index) => (
				<React.Fragment key={index}>
					<Box p="20px" display="flex" gap="6px" flexDirection="column">
						<SkeletonText width="102px" />
						<SkeletonText width="118px" />
						<Box display="flex" justifyContent="space-between">
							<SkeletonText width="200px" />
							<SkeletonText width="42px" />
						</Box>
					</Box>
					<Divider />
				</React.Fragment>
			))}
			<Typography
				p="20px"
				fontSize={14}
				fontWeight={500}
				lineHeight="20px"
				sx={{ opacity: 0.5 }}
			>
				Your conversations will appear here once customers reply to the texts
			</Typography>
		</RenderBoxContent>
	);
}
