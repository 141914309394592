import { Box, Popover, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { StatusOptions } from "resources/constant";
import Option from "../Select/Option/Option.js";

export default function Status({
	options,
	width='100%',
	handleSelectChange,
	selected,
	setSelected
}) {
	const [anchorElStatus, setAnchorElStatus] = useState(null);
	const open = Boolean(anchorElStatus);
	const id = open ? "simple-popover" : undefined;

	const handleClick = (event) => {
		setAnchorElStatus(event.currentTarget);
	};

	const handleOptionChange = (label,value) => {
		setSelected("Status: " + label)
		handleSelectChange(label,value);
		handleClose();
		console.log(label);
	};

	const handleClose = () => {
		setAnchorElStatus(null);
	};

	const theme = useTheme();
	return (
		<>
			<Box
				display={"flex"}
				height={"34px"}
				width={"100%"}
				id="pointerCursor"
				sx={{
					borderRadius: "5px",
					padding: "8px 10px",
					border: `1px solid ${theme.palette?.card?.border}`,
					background: theme.palette?.card?.background,
					justifyContent:'space-between'
				}}
				onClick={handleClick}
			>
				<Typography fontWeight={"500"} fontSize={"12px"} paddingRight={"10px"}>
				{selected}
				</Typography>
				<Box display={"flex"} justifyContent={"end"} ml={"5px"}>
					<img src="/topbar/down.svg" width={"50%"} alt="down"/>
				</Box>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorElStatus}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box
					width={width}
					sx={{
						borderRadius: "5px",
						border: `1px solid ${theme.palette?.card?.border}`,
						background: theme.palette?.card?.background,
						color: "#808698",
						fontSize: "12px",
					}}
				>
					{StatusOptions?.map((item, index) => {
						return (
							<Option 
							index={index} 
							label={item.label}
							value={item.value}
							handleOptionSelect={handleOptionChange}>
						</Option>
						);
					})}
				</Box>
			</Popover>
		</>
	);
}
