import { Box, Button, Typography, useTheme } from "@mui/material";
import { CustomDesc } from "./Common";

const Imported = ({ setTab, handleClose, setImported }) => {
	const theme = useTheme();
	const { mode } = theme.palette;

	return (
		<Box p={"20px"}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "end",
					alignItems: "center",
				}}
				mb={"42px"}
			>
				<img
					src="/icons/cut.svg"
					alt="cut"
					onClick={() => {
						setImported(false);
						setTab(0);
						handleClose();
					}}
					id="pointerCursor"
					style={
						{
							// marginTop: "-10px",
						}
					}
				/>
			</Box>
			<Box
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				textAlign={"center"}
			>
				<Box>
					<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
						<Box
							width={"80px"}
							height={"80px"}
							borderRadius={"50%"}
							bgcolor={mode === "light" ? "#E6F3FF" : "#171923"}
							display={"flex"}
							justifyContent={"center"}
							alignItems={"center"}
							mb={"16px"}
						>
							<Box>
								<img src="/img/importing.svg" alt="importing"/>
							</Box>
						</Box>
					</Box>

					<Typography
						fontSize={"24px"}
						fontWeight={600}
						lineHeight={"normal"}
						color={theme?.palette?.pageHeading}
						mb={"12px"}
					>
						Importing your subscribers
					</Typography>
					<CustomDesc maxWidth="443px" mb="28px">
						Our support team is reviewing your subscriber list and will get back
						to you within the next 48 hours.
					</CustomDesc>
					<CustomDesc mb="44px">
						For any questions please{" "}
						<span
							style={{ color: "#0081FF", opacity: "1 !important" }}
							className="opacity-1"
						>
							contact support.
						</span>
					</CustomDesc>
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"end"} height={"40px"}>
				<Button
					size={"small"}
					sx={{
						backgroundColor: "#0081FF",
						padding: "11px 20px",
						color: "#FFF",
						":hover": {
							color: mode === "light" ? "#0C0D17" : "#FFF",
						},
					}}
					variant="outlined"
					onClick={() => {
						setTab(0);
						setImported(false);
						handleClose();
						// setTab(tab + 1);
					}}
				>
					<Typography fontSize={"14px"} fontWeight={"600"}>
						Close
					</Typography>
				</Button>
			</Box>
		</Box>
	);
};

export default Imported;
