import React, {useState} from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
	styled,
} from "@mui/material";
import { PhoneInput, defaultCountries, parseCountry, } from "react-international-phone";
import "react-international-phone/style.css";

import { txtCartFetch } from "utilifyFunctions";

export default function MobileNumber({handleClose, setOnboarding, onboardingNumber, setTestMessage, setRecipientPhone}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [phone, setPhone] = useState("");
	const [country, setCountry] = useState("");

	const sendTest = async function(){
		let body = {
			country: country, 
			phone: phone
		}
		const response = await txtCartFetch("POST", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/onboarding/test", body);
		console.log(response);
		if(response){
			setTestMessage(response.message);
			console.log('message set', response.message);
			setOnboarding(6);
		}
	}

	const handlePhoneChange = function(e){
		console.log(e.phone);
		console.log(e.country.iso2);
		setPhone(e.phone);
		setCountry(e.country.iso2);
		setRecipientPhone(e.phone);
	}
	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
			<Box>
				<Typography
					fontSize={20}
					fontWeight={600}
					lineHeight={"normal"}
					color={"#FFF"}
					mb={"16px"}
				>
					Enter your mobile number
				</Typography>
				<Box mb={"25px"}>
					<PhoneInputField onboardingNumber={onboardingNumber} handleChange={(phone, { country }) =>{handlePhoneChange({ phone, country })}}/>
				</Box>
				<Box display={"flex"} justifyContent={"end"}>
					<Box display={"flex"} gap={"8px"}>
						<Button
							size={"small"}
							sx={{
								height: "40px",
								backgroundColor: "transparent",
								padding: "11px 31px",
								border: "none",
								color: "#818993",
								":hover": {
									color: mode === "light" ? "#818993" : "#818993",
									border: "none",
								},
								fontSize: "14px",
								fontWeight: "400",
							}}
							variant="outlined"
							onClick={() => {
								// navigate("/onboarding/step-test-message");
								handleClose()
							}}
						>
							Skip
						</Button>
						<Button
							size={"small"}
							sx={{
								height: "40px",
								backgroundColor: "#0081FF",
								padding: "11px 31px",
								color: mode === "light" ? "#FFF" : "#FFF",
								":hover": {
									color: mode === "light" ? "#FFF" : "#FFF",
								},
								fontSize: "14px",
								fontWeight: "600",
							}}
							variant="outlined"
							onClick={() => {
								sendTest();
							}}
						>
							Send Test SMS
						</Button>
					</Box>
				</Box>
			</Box>
		</MainContainer>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				padding: "40px 30px",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "726px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				// backgroundColor: lightMode ? "#cacae2" : "#cacae2",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				height: "250px",
				maxHeight: "250px",
				overflow: "hidden",
				"::-webkit-scrollbar": {
					display: "none",
				},
				// overflowY: "scroll",
				// height: "935px",
			}}
		>
			{children}
		</Box>
	);
};

function PhoneInputField({ value, handleChange }) {

	const countries = defaultCountries.filter((country) => {
		const { iso2 } = parseCountry(country);
		return ['us', 'gb', 'au', 'ca'].includes(iso2);
	  });

	return (
		<StyledPhoneInput
			defaultCountry="us"
			value={value}
			onChange={handleChange}
			countries={countries}
		/>
	);
}
const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
	width: "100%",
	"& .react-international-phone-input": {
		width: "100%",
		backgroundColor: "transparent !important",
		color: "#FFF",
		border: `1px solid #818993 !important`,
	},
	"& .react-international-phone-country-selector-button": {
		backgroundColor: "transparent !important",
		border: `1px solid #818993 !important`,
	},
	"& .react-international-phone-country-selector-dropdown": {
		backgroundColor: theme.palette?.card?.background,
		color: theme.palette?.pageHeading,
		border: `1px solid #818993 !important`,
	},
	"& .react-international-phone-country-selector-dropdown__list-item:hover": {
		backgroundColor: theme.palette?.card?.background,
	},
	"& .react-international-phone-country-selector-button__dropdown-arrow ": {
		/* Your custom styles for the arrow */
		"background-image":
			"url('/arrow/down.svg')" /* Example for changing image */,
	},
}));
