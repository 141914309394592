/* eslint-disable react/no-unescaped-entities */
import React, {useEffect} from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";

export default function AdditionalTerms({ handleClose }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
   
	useEffect(() => {
		navigator.clipboard.writeText("SMS / MMS Text Message Marketing Program Privacy Policy\n\nYour privacy is extremely important to us. We will only use the information you provide through the Program to transmit your mobile messages and respond to you, if necessary. This includes but is not limited to, sharing information with platform providers, phone companies (carriers), and other vendors who assist us in the delivery of mobile messages. \n\nWE DO NOT SELL, RENT, LOAN, TRADE, LEASE, OR OTHERWISE TRANSFER FOR PROFIT ANY PHONE NUMBERS OR CUSTOMER INFORMATION COLLECTED THROUGH THE PROGRAM TO ANY THIRD PARTY.\n\nNonetheless, We reserve the right at all times to disclose any information as necessary to satisfy any law, regulation, or governmental request, to avoid liability, or to protect our rights or property. When you complete forms online or otherwise provide us with information in connection with the program, you agree to provide accurate, complete, and true information. You agree not to use a false or misleading name or a name that you are not authorized to use. If, in Our sole discretion, We believe that any such information is untrue, inaccurate, or incomplete, or you have opted into the Program for an ulterior purpose, We may refuse you access to the program and pursue any appropriate legal remedies.\n\nCalifornia Civil Code Section 1798.83 and The General Data Protection Regulation 2016/679 (GDPR) allows users of the program that are California or European Union residents to request certain information regarding our disclosure of the information you provide through the Program to third parties for their direct marketing purposes.\n\nContact us at the following address to make a request:\n\nINSERT YOUR COMPANY NAME\n\nINSERT YOUR COMPANY ADDRESS\n\nINSERT YOUR COMPANY EMAIL ADDRESS");
	});
	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
			<Box>
				<Box
					display={"flex"}
					justifyContent={"center"}
					mb={"22px"}
					position={"relative"}
				>
					<img
						src="/icons/cut.svg"
						alt="close"
						onClick={() => {
							handleClose()
						}}
						id="pointerCursor"
						style={{
							position: "absolute",
							top: "-18px",
							right: "0",
						}}
					/>
					<Typography
						mt={"4px"}
						fontSize={"24px"}
						fontWeight={600}
						lineHeight={"normal"}
						color={"#FFF"}
					>
						Additional Privacy Policy
					</Typography>
				</Box>
				<Typography
					fontSize={15}
					fontWeight={600}
					color={"#FFF"}
					lineHeight={"20px"}
                    mb={'24px'}
				>
					These terms have been copied to your clipboard, paste them into your
					Store's Privacy Policy.
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>
					
                    SMS / MMS Text Message Marketing Program Privacy Policy
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>
                    Your privacy is extremely important to us. We will only use the information you provide through the Program to transmit your mobile messages and respond to you, if necessary. This includes but is not limited to, sharing information with platform providers, phone companies (carriers), and other vendors who assist us in the delivery of mobile messages.
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>
WE DO NOT SELL, RENT, LOAN, TRADE, LEASE, OR OTHERWISE TRANSFER FOR PROFIT ANY PHONE NUMBERS OR CUSTOMER INFORMATION COLLECTED THROUGH THE PROGRAM TO ANY THIRD PARTY.
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>
Nonetheless, We reserve the right at all times to disclose any information as necessary to satisfy any law, regulation, or governmental request, to avoid liability, or to protect our rights or property. When you complete forms online or otherwise provide us with information in connection with the program, you agree to provide accurate, complete, and true information. You agree not to use a false or misleading name or a name that you are not authorized to use. If, in Our sole discretion, We believe that any such information is untrue, inaccurate, or incomplete, or you have opted into the Program for an ulterior purpose, We may refuse you access to the program and pursue any appropriate legal remedies.
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
                    mb={'24px'}
				>

California Civil Code Section 1798.83 and The General Data Protection Regulation 2016/679 (GDPR) allows users of the program that are California or European Union residents to request certain information regarding our disclosure of the information you provide through the Program to third parties for their direct marketing purposes.
				</Typography>
				<Typography
					fontSize={14}
					fontWeight={500}
					color={"#FFF"}
					lineHeight={"20px"}
					sx={{
						opacity: "0.5",
					}}
				>
					Contact us at the following address to make a request:

                    INSERT YOUR COMPANY NAME

                    INSERT YOUR COMPANY ADDRESS

                    INSERT YOUR COMPANY EMAIL ADDRESS
				</Typography>
			</Box>
		</MainContainer>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				padding: "36px",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "800px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				// backgroundColor: lightMode ? "#cacae2" : "#cacae2",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				height: "758px",
				"::-webkit-scrollbar": {
					display: "none",
				},
			}}
		>
			{children}
		</Box>
	);
};


