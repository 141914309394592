import React from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { ErrorSVG } from "resources/Icons/Campaigns";

export default function ErrorPage() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
			<Box display={"flex"} flexDirection={"column"}>
				<Box display={"flex"} justifyContent={"center"}>
					<img
						src="/img/cart-recovery/cut.svg"
						id="pointerCursor"
						style={{
							position: "absolute",
							top: "30px",
							right: "30px",
						}}
						onClick={() => {
							// setStepShow(1);
						}}
						alt="cut"
					/>
					<Box
						sx={{
							background:  "rgb(254, 114, 12, 0.10)",
							width: "90px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							borderRadius: "100px",
						}}
						mb={2}
					>
						<ErrorSVG />
					</Box>
				</Box>

				<Heading lightMode={lightMode}>Error</Heading>
				<DescriptionText lightMode={lightMode}>
					This store has been flagged for S.H.A.F.T. content and is not able to
					use the TxtCart Platform.
				</DescriptionText>
				<DescriptionText lightMode={lightMode}>
					If you believe this is a mistake, please contact support at
					support@txtcartapp.com
				</DescriptionText>
			</Box>
		</MainContainer>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: belowTablet
					? "translate(-50%, -50%)"
					: "translate(-21%, -50%)",
				width: belowTablet ? "90%" : "508px",
				backgroundColor: lightMode ? "#FFF" : "#0C0D17",
				padding: belowTablet ? "30px 20px" : "40px 64px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
			}}
		>
			{children}
		</Box>
	);
};

const Heading = ({ children, lightMode, ...props }) => {
	return (
		<Typography
			color={lightMode ? "#000D31" : "#FFF"}
			fontSize={"24px"}
			fontWeight={600}
			mb={"12px"}
			textAlign={"center"}
			{...props}
		>
			{children}
		</Typography>
	);
};

const DescriptionText = ({ children, lightMode, ...props }) => {
	return (
		<Typography
			color={lightMode ? "#000D31" : "#FFF"}
			fontSize={"14px"}
			fontWeight={500}
			mb={1}
			sx={{
				opacity: "0.5",
				textAlign: "center",
			}}
			{...props}
		>
			{children}
		</Typography>
	);
};
