import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { CustomSubHeading } from "./EINTab";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function DataReviewTab({setTab, settings, formData}) {
	const theme = useTheme();

	return (
		<Box>
			<FixContainer mb={4}>
				<CustomSubHeading theme={theme}>
					The following information will be provided for review by a third
					party. If any of this information is incorrect, contact support.
				</CustomSubHeading>
			</FixContainer>
			<FixContainer mb={2}>
				<CustomSubHeading theme={theme}>
					Please ensure this is your exact business legal name associated with
					your Registration Number.
				</CustomSubHeading>
				<Box display={"flex"} alignItems={"center"} mb={2} mt={2}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
						alt="tick"
					/>
					<CustomSubHeading theme={theme} mb={0}>
						Brand Name: &nbsp;
					</CustomSubHeading>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{settings.shopName}
					</Typography>
				</Box>
				<Box display={"flex"} alignItems={"center"} mb={2}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
						alt="tick"
					/>
					<CustomSubHeading theme={theme} mb={0}>
						Has EIN: &nbsp;
					</CustomSubHeading>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{formData.hasEIN}
					</Typography>
				</Box>
				<Box display={"flex"} alignItems={"center"} mb={2}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
						alt="tick"
					/>
					<CustomSubHeading theme={theme} mb={0}>
						Email:&nbsp;
					</CustomSubHeading>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{settings.email}
					</Typography>
				</Box>
				<Box display={"flex"} alignItems={"center"} mb={2}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
						alt="tick"
					/>
					<CustomSubHeading theme={theme} mb={0}>
						Comanpy URL: &nbsp;
					</CustomSubHeading>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{settings.domain}
					</Typography>
				</Box>
				<Box display={"flex"} alignItems={"center"} mb={2}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
						alt="tick"
					/>
					<CustomSubHeading theme={theme} mb={0}>
						Address Line 1: &nbsp;
					</CustomSubHeading>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{settings.address}
					</Typography>
				</Box>
				<Box display={"flex"} alignItems={"center"} mb={2}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
						alt="tick"
					/>
					<CustomSubHeading theme={theme} mb={0}>
						City: &nbsp;
					</CustomSubHeading>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{settings.city}
					</Typography>
				</Box>
				<Box display={"flex"} alignItems={"center"} mb={2}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
						alt="tick"
					/>
					<CustomSubHeading theme={theme} mb={0}>
						State/Province: &nbsp;
					</CustomSubHeading>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{settings.state}
					</Typography>
				</Box>
				<Box display={"flex"} alignItems={"center"} mb={2}>
					<img
						src="/arrow/tick.svg"
						style={{
							marginRight: "10px",
						}}
						alt="tick"
					/>
					<CustomSubHeading theme={theme} mb={0}>
						Postal Code: &nbsp;
					</CustomSubHeading>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={14}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{settings.zip}
					</Typography>
				</Box>
			</FixContainer>
			<Box display={"flex"} justifyContent={"end"}>
				<PrimaryButton
					onClick={() => {
						setTab("VerificationTab");
					}}
				>
					Next
				</PrimaryButton>
			</Box>
		</Box>
	);
}

export const FixContainer = ({ children, ...props }) => {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Box
			width={belowTablet ? "100%" : "100%"}
			minWidth={belowTablet ? "100%" : "100%"}
			maxWidth={belowTablet ? "100%" : "100%"}
			{...props}
		>
			{children}
		</Box>
	);
};