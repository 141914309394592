/* eslint-disable no-unused-vars */
import {
	Box,
	Grid,
	Popover,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import PageHeading from "components/Typography/PageHeading";
import React, { useEffect, useState } from "react";
import RecoveredProducts from "./components/RecoveredProducts";
import AbandonedProducts from "./components/AbandonedProducts";
import OrderOverview from "components/Chart/OrderOverview";
import { DownArrow } from "resources/Icons/Arrows";
import AverageAOV from "components/Chart/AverageAOV";
import { AverageAOVMock, OrderOverviewMock } from "resources/constant";
import InsightPieChart from "components/Chart/InsightPieChart";
import {
	checkEmptyState,
	updateDataIfEmpty,
} from "resources/helpers/checkEmptyState";
import Select from "components/Select/Select";
import { TimeSelection } from "resources/constant";
import { txtCartFetch } from "utilifyFunctions";
import { addDays } from "utilifyFunctions";
import { getDateChartTick } from "utilifyFunctions";
import { parseDateFilter } from "utilifyFunctions";

export default function Insights() {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [totalSalesData, setTotalSalesData] = useState([]);
	const [dateRange, setDateRange] = useState("last_days_30");
	const [salesByCountry, setSalesByCountry] = useState([]);
	const [salesByCountrySum,setSalesByCountrySum] = useState(null);
	const [salesByGender, setSalesByGender] = useState([]);
	const [genderHeader,setGenderHeader] = useState(null);
	const [mostAbandoned, setMostAbandoned] = useState(null);
	const [mostRecovered, setMostRecovered] = useState(null);

	const handleDateChange = (label, value) => {
		setDateRange(value);
		loadMetrics(value);
	}

	const checkAllTimeRange = function(oldestCart){
		let MS_IN_HOUR = 60 * 60 * 1000; 
		let MS_IN_DAY = 24 * MS_IN_HOUR;
		if(oldestCart){
			let today = new Date();
			let yesterday = addDays(today,-1);
			let oldestDate = new Date(oldestCart.lastMessage);

			let currentTime = Date.now();
			let diff = currentTime - oldestCart.lastMessage;

			if(today.getDate() === oldestDate.getDate() && today.getMonth() === oldestDate.getMonth() && today.getFullYear() === oldestDate.getFullYear()){
				return "today";
			}
			else if(yesterday.getDate() === oldestDate.getDate() && yesterday.getMonth() === oldestDate.getMonth() && yesterday.getFullYear() === oldestDate.getFullYear()){
				return "yesterday";
			}
			else if(diff < (7 * MS_IN_DAY)){
				return "last_days_7";
			}
			else if(diff < (30 * MS_IN_DAY)){
				return "last_days_30";
			}
			else{
				return "full";
			}
		} else{
			return("today");
		}
	}

	const getOldestCart = async function(){
		let oldestCart = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/cart/oldest");
		console.log(oldestCart);
		return oldestCart;
	}

	const getMostAbandonedProducts = async function(dateRange){
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/products/abandoned"+dateRange);
		setMostAbandoned(response);
		console.log(response);
	}

	const getMostRecoveredProducts = async function(dateRange){
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/products/recovered"+dateRange);
		setMostRecovered(response);
		console.log(response);
	}

	async function getSalesByGender(dateRange){
		let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/sales/gender"+dateRange);
		let sum = response.male?.count + response?.female?.count; 
		response.male.value = response.male?.count;
		response.female.value = response.female?.count;
		let femalePercent = 0;
		let malePercent = 0; 
		if(response.male?.value > 0){
			malePercent = Math.round((response.male?.value / sum) * 100); 
		}
		if(response.female?.value > 0){
			femalePercent = Math.round((response.female?.value / sum) * 100);
		}
		let heading = {};

		response.female.fill = "rgba(255, 0, 76, 0.25)";
		response.female.stroke =  "#FF004C";
		response.male.fill = "rgba(0, 129, 255, 0.25)";
		response.female.stroke = "#FF004C";
		if(femalePercent > malePercent){
			heading.title = "Female";
			heading.value = femalePercent+"%";
		} else{
			heading.title = "Male";
			heading.value = malePercent + "%";
		}
		setGenderHeader(heading);
		setSalesByGender(Object.values(response));
	}

	async function getSalesByCountry(dateRange) {

		let fills = [
			{
				stroke: "#0081FF",
				fill: "rgba(0, 129, 255, 0.25)",
			},
			{
				stroke: "#FF004C",
				fill: "rgba(255, 0, 76, 0.25)",
			},
			{
				stroke: "#0CD16A",
				fill: "rgba(12, 209, 106, 0.25)",
			},
			{
				stroke: "#FFA800",
				fill: "rgba(255, 168, 0, 0.25)",
			},
		]
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/sales/country"+dateRange);
		let countries = [];
		let sum = 0;
		for(let i = 0; i < response?.length; i++){
			response[i].data.fill = fills[i].fill;
			response[i].data.stroke = fills[i].stroke;
			response[i].data.value =response[i].data.count;
			countries.push(response[i].data);
			sum += response[i].data?.count;
		}

		let header = {
			value: sum,
			title:"Total Sales"
		}
		setSalesByCountry([...countries]);
		setSalesByCountrySum(header);
	}

	async function getTotalSalesGraph(dateRange,filter){
		if(filter === 'all_time'){
			let localOldestCart = await getOldestCart();
			filter = checkAllTimeRange(localOldestCart);
			dateRange = parseDateFilter(filter, localOldestCart);
			dateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		}

		let URL = process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/composite/sales/total/graph"+dateRange
		if(dateRange){
			URL += "&dateFilter="+filter;
		}

		const response = await txtCartFetch("GET",URL);

		for(let i = 0; i < response?.dataPoints?.length; i++){
			response.dataPoints[i].date = getDateChartTick(response.dataPoints[i].date,filter);
		}
		setTotalSalesData(response?.dataPoints);
	}

	async function loadMetrics(filter){
		let dateRange = parseDateFilter(filter);
		let urlDateRange = (dateRange ? "?start="+dateRange.start+"&end="+dateRange.end : "");
		getTotalSalesGraph(urlDateRange,filter);
		getSalesByCountry(urlDateRange);
		getSalesByGender(urlDateRange);
		getMostAbandonedProducts(urlDateRange);
		getMostRecoveredProducts(urlDateRange);
	}

	useEffect(() => {
		loadMetrics("last_days_30");
	}, []);

	return (
		<Grid container alignItems={"center"}>
			<Grid item xs={12}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={!belowTablet ? "row" : "column"}
				>
					<PageHeading>Insights</PageHeading>
					<Select
						options={TimeSelection}
						handleSelectChange={handleDateChange}
						defaultValue="Last 30 Days"
					>
					</Select>
				</Box>
			</Grid>

			<Grid item xs={12} mt={"20px"}>
				<Box
					display={"flex"}
					gap={"22px"}
					flexDirection={!belowTablet ? "row" : "column"}
				>
					<Box width={"100%"}>
						<FirstChart totalSalesData={totalSalesData}/>
					</Box>
					<Box width={"100%"}>
						<SecondChart totalSalesData={totalSalesData}/>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} mt={"24px"}>
				<Box
					display={"flex"}
					gap={"24px"}
					flexDirection={!belowTablet ? "row" : "column"}
					className={"bine"}
				>
					<Box width={"100%"} className={"vine"}>
							<InsightPieChart
								title="Sales by Country"
								data={salesByCountry}
								heading={salesByCountrySum}
							/>
						</Box>
						<Box width={"100%"} className={"vine"}>
							<InsightPieChart
								title="Sales by Gender"
								data={salesByGender}
								heading={genderHeader}
							/>
						</Box>
				</Box>
			</Grid>
			<Grid item xs={12} mt={"29px"}>
				<Box
					display={"flex"}
					gap={"22px"}
					flexDirection={!belowTablet ? "row" : "column"}
				>
					<Box width={"100%"}>
						<PageHeading mb="16px">Top Recovered Products</PageHeading>
						<RecoveredProducts  data={mostRecovered}/>
					</Box>
					<Box width={"100%"}>
						<PageHeading mb="16px">Top Abandoned Products</PageHeading>

						<AbandonedProducts data={mostAbandoned}/>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}

const FirstChart = ({totalSalesData}) => {
	const [showBarChart, setShowBarChart] = useState(false);
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const open = Boolean(anchorEl);
	const [graphData, setGraphData] = useState([]);
	const id = open ? "simple-popover" : undefined;
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			const updatedGraphData = updateDataIfEmpty(OrderOverviewMock, 0);
			setGraphData(updatedGraphData);
		} else {
			setGraphData(OrderOverviewMock);
		}
	}, []);

	return (
		<Box
			bgcolor={theme?.palette?.card?.background}
			border={`1px solid ${theme?.palette?.card?.border}`}
			borderRadius={"12px"}
			p={"20px"}
		>
			<Box
				display={"flex"}
				mb={"34px"}
				alignItems={isMobile ? "baseline" : "unset"}
			>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={isMobile ? "column" : "row"}
					gap={isMobile && "14px"}
					width={"100%"}
				>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={20}
						fontWeight={600}
						lineHeight={"normal"}
						mb={isMobile && "14px"}
					>
						Order Overview
					</Typography>
					<Box display={"flex"}>
						<GraphLines />

						<Box>
							<DropDown
								handleClick={handleClick}
								handleClose={handleClose}
								showBarChart={showBarChart}
								anchorEl={anchorEl}
								open={open}
								id={id}
								setShowBarChart={setShowBarChart}
							/>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box>
				<OrderOverview showBarChart={showBarChart} data={totalSalesData} />
			</Box>
		</Box>
	);
};
const SecondChart = ({totalSalesData}) => {
	const [showBarChart, setShowBarChart] = useState(true);
const [graphData, setGraphData] = useState([])
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			const updatedGraphData = updateDataIfEmpty(AverageAOVMock, 0);
			setGraphData(updatedGraphData);
		} else {
			setGraphData(AverageAOVMock);
		}
	}, []);
	return (
		<Box
			bgcolor={theme?.palette?.card?.background}
			border={`1px solid ${theme?.palette?.card?.border}`}
			borderRadius={"12px"}
			p={"20px"}
		>
			<Box
				display={"flex"}
				mb={"34px"}
				alignItems={isMobile ? "baseline" : "unset"}
			>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection={isMobile ? "column" : "row"}
					gap={isMobile && "14px"}
					width={"100%"}
				>
					<Typography
						color={theme?.palette?.pageHeading}
						fontSize={20}
						fontWeight={600}
						lineHeight={"normal"}
						mb={isMobile && "14px"}
					>
						Average AOV
					</Typography>
					<Box display={"flex"}>
						<GraphLines />
						<Box>
							<DropDown
								handleClick={handleClick}
								handleClose={handleClose}
								showBarChart={showBarChart}
								anchorEl={anchorEl}
								open={open}
								id={id}
								setShowBarChart={setShowBarChart}
							/>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box>
				<AverageAOV showBarChart={showBarChart} data={graphData} totalSalesData={totalSalesData}/>
			</Box>
		</Box>
	);
};

const GraphTagHeading = ({ children }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Typography
			color={theme?.palette?.pageHeading}
			fontSize={isMobile ? 12 : 14}
			fontWeight={500}
			lineHeight={"normal"}
		>
			{children}
		</Typography>
	);
};

const DropDown = ({
	handleClick,
	handleClose,
	showBarChart,
	anchorEl,
	open,
	id,
	setShowBarChart,
}) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box ml={"20px"}>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				height={"40px"}
				width={"65px"}
				mb={isMobile ? "10px" : "0px"}
				mt={isMobile ? "0px" : "0"}
				id="pointerCursor"
				sx={{
					borderRadius: "5px",
					padding: "11px",
					border: mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
					background: theme.palette?.card?.background,
					position: "relative",
				}}
				onClick={handleClick}
			>
				<Box paddingRight={"5px"}>
					{showBarChart ? (
						<img
							src={`/img/analytics/${
								mode === "light" ? "bar-chart" : "bar-chart-dark"
							}.svg`}
							width={"19px"}
						/>
					) : (
						<img
							src={`/img/analytics/${
								mode === "light" ? "line-chart" : "line-chart-dark"
							}.svg`}
							width={"80%"}
						/>
					)}
				</Box>
				<Box
					display={"flex"}
					alignItems={"center"}
					sx={{
						top: "14px",
						right: "14px",
					}}
				>
					<DownArrow />
				</Box>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box
					width={"65px"}
					sx={{
						borderRadius: "5px",
						border:
							mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
						background: theme.palette?.card?.background,
						color: "#808698",
						fontSize: "12px",
					}}
				>
					<Typography
						sx={{
							padding: "8px 15px",
							":hover": {
								background: "#cccaca3b",
							},
						}}
						fontSize={"14px"}
						id="pointerCursor"
						onClick={() => {
							handleClose();
							setShowBarChart(!showBarChart);
						}}
					>
						{!showBarChart ? (
							<img
								src={`/img/analytics/${
									mode === "light" ? "bar-chart" : "bar-chart-dark"
								}.svg`}
								width={"19px"}
							/>
						) : (
							<img
								src={`/img/analytics/${
									mode === "light" ? "line-chart" : "line-chart-dark"
								}.svg`}
								width={"19px"}
							/>
						)}
						{/* {item} */}
					</Typography>
				</Box>
			</Popover>
		</Box>
	);
};

const GraphLines = () => {
	return (
		<Box display={"flex"} gap={"12px"} className="insight-ellipse">
			<Box>
				<Box display={"flex"} alignItems={"center"} gap={"7px"}>
					<Box className="cart-Recovery-ellipse">&nbsp;</Box>
					<GraphTagHeading>Cart Recovery</GraphTagHeading>
				</Box>
				{/* <Box display={"flex"} alignItems={"center"} gap={"7px"}>
					<Box className="automations-ellipse"> &nbsp;</Box>
					<GraphTagHeading>Automations</GraphTagHeading>
				</Box> */}
			</Box>
			<Box>
				<Box display={"flex"} alignItems={"center"} gap={"7px"}>
					<Box className="campaigns-ellipse"> &nbsp;</Box>
					<GraphTagHeading>Campaigns</GraphTagHeading>
				</Box>
				{/* <Box display={"flex"} alignItems={"center"} gap={"7px"}>
					<Box className="flows-ellipse">&nbsp;</Box>
					<GraphTagHeading>Flows</GraphTagHeading>
				</Box> */}
			</Box>
		</Box>
	);
};

const pieData = [
	{
		heading: {
			value: "5,210",
			title: "Total Sales",
		},
		title: "Sales By Country",
		data: [
			{
				name: "USA",
				value: 100,
				stroke: "#0081FF",
				fill: "rgba(0, 129, 255, 0.25)",
			},
			{
				name: "Canada",
				value: 10,
				stroke: "#FF004C",
				fill: "rgba(255, 0, 76, 0.25)",
			},
			{
				name: "Uk",
				value: 10,
				stroke: "#0CD16A",
				fill: "rgba(12, 209, 106, 0.25)",
			},
			{
				name: "Australia",
				value: 10,
				stroke: "#FFA800",
				fill: "rgba(255, 168, 0, 0.25)",
			},
		],
	},
	{
		heading: {
			value: "74%",
			title: "Male",
		},
		title: "Sales By Gender",
		data: [
			{
				name: "Male",
				value: 5012,
				stroke: "#0081FF",
				fill: "rgba(0, 129, 255, 0.25)",
			},
			{
				name: "Female",
				value: 4198,
				stroke: "#FF004C",
				fill: "rgba(255, 0, 76, 0.25)",
			},
		],
	},
	// {
	// 	heading: {
	// 		value: "21%",
	// 		title: "Tablet",
	// 	},
	// 	title: "Sales By Device",
	// 	data: [
	// 		{
	// 			name: "Desktop",
	// 			value: 400,
	// 			stroke: "#0081FF",
	// 			fill: "rgba(0, 129, 255, 0.25)",
	// 		},
	// 		{
	// 			name: "Tablet",
	// 			value: 300,
	// 			stroke: "#9963FE",
	// 			fill: "rgba(153, 99, 254, 0.25)",
	// 		},
	// 		{
	// 			name: "Mobile",
	// 			value: 100,
	// 			stroke: "#FF5C00",
	// 			fill: "rgba(255, 92, 0, 0.25)",
	// 		},
	// 	],
	// },
];
