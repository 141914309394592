import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { styled, useTheme } from "@mui/material/styles";
import { Menu, IconButton, Typography, Button } from "@mui/material";
import UserMenu from "./components/UserMenu";
import HelpMenu from "./components/HelpMenu";
import SearchPopover from "./components/SearchPopover";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { txtCartFetch } from "utilifyFunctions";
import Canny from "./Canny.js";
import { Mixpanel } from '../../utils/mixpanel';
import { PAGES, BUTTONS } from '../../constants/mixPanelEvents';

const Topbar = ({ onSidebarOpen }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElHelp, setAnchorElHelp] = React.useState(null);
  const [subscribers, setSubscribers] = React.useState('');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickHelp = (event) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseHelp = () => {
    setAnchorElHelp(null);
  };

  const theme = useTheme();
  const { mode } = theme.palette;


  const loadSubscribers = async () =>{
    let response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/metric/shoppers/count");
    setSubscribers(response?.shoppers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  }

  React.useEffect(() => {
		loadSubscribers();
		return;
	}, []);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      width={1}
      height={"50px"}
      paddingX={2}
    >
      <Box
        sx={{
          height: 64,
        }}
        borderRadius={1}
        width={{ xs: 70, md: 260 }}
      >
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: "20px 16px 16px 16px",
            display: { xs: "flex", md: "none" },
          }}
        >
          <img
            src={`/icons/${
              mode === "dark" ? "dark/menuIcon.svg" : "menuIcon.svg"
            }`}
            alt="Menu Icon"
          />
        </Button>
      </Box>
      <Box
        id="lg-topbar"
        sx={{
          display: { xs: "none", lg: "flex" },
          paddingRight: 3,
          maxWidth: "100%", 
          marginLeft: "-1.2%"       
         }}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Box width={"250px"}>
          {/* <Search className="seachBox">
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              onChange={handleInputChange}
              onClick={handleInputChange}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
  
            />
          </Search>

          <SearchPopover
            isPopoverOpen={isPopoverOpen}
            anchorElSearch={anchorElSearch}
            handlePopoverClose={handlePopoverClose}
            theme={theme}
          /> */}
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Button
            size={"small"}
            sx={{
              padding: "0px",
            }}
          >
            <Box
              color={mode === "light" ? "#0C84FE" : "#EBECF2"}
              display={"flex"}
              sx={{
                backgroundColor: mode === "light" ? "#EEF6FF" : "#2A2B37",
                borderRadius: "5px",
                padding: "5px 10px",
                ":hover": {
                  backgroundColor: mode === "light" ? "#e5eef8" : "#212232", // Change the background color on hover
                  color: mode === "light" ? "#0C84FE" : "#EBECF2", // Change the text color on hover
                },
              }}
              onClick={() => navigate("/analytics/subscribers")}
            >
              <Box marginRight={"5px"} display={"flex"}>
                <img
                  src={
                    mode === "light"
                      ? "/topbar/people.svg"
                      : "/topbar/peoples.svg"
                  }
                  alt="People Icon"
                  width={"85%"}
                />
              </Box>
              <Box>
                <Typography variant="p" fontSize={"12px"}>
                  SMS Subscribers: <b>{subscribers}</b>
                </Typography>
              </Box>
            </Box>
          </Button>
          <Button
            size={"small"}
            sx={{
              padding: "0px",
              marginLeft: "8px",
            }}
          >
            <Box
              id="pointerCursor"
              color={mode === "light" ? "#0C84FE" : "#EBECF2"}
              display={"flex"}
              sx={{
                backgroundColor: mode === "light" ? "#0A84FE" : "#0A84FE",
                borderRadius: "5px",
                padding: "7px 15px",
                color: "#ffff",
                ":hover": {
                  backgroundColor: "#007cf9", // Change the background color on hover
                },
                ":active": {
                  backgroundColor: "#3e9af7",
                },
              }}
              onClick={() => {
                navigate("/campaigns/create-campaigns");
                Mixpanel.track_button_action(PAGES.GLOBAL, BUTTONS.CREATE_SMS_CAMPAIGN);
              }}
            >
              <Typography variant="p" fontSize={"12px"}>
                Create SMS Campaign
              </Typography>
            </Box>
          </Button>

          <Box marginLeft={2} display={"flex"} alignItems={"end"}>
            <Box id="pointerCursor" position={"relative"}>
			  <Canny mode={mode}></Canny>
            </Box>
            <Box ml={1} mr={1} id="pointerCursor">
              <IconButton
                size="small"
                aria-label="help-menu"
                aria-controls="help-menu"
                aria-haspopup="true"
                onClick={handleClickHelp}
              >
                <img
                  src={
                    mode === "light"
                      ? "/topbar/help.svg"
                      : "/topbar/dark/help.svg"
                  }
                  alt="Help Icon"
                />
              </IconButton>
              <Menu
                anchorEl={anchorElHelp}
                keepMounted
                open={Boolean(anchorElHelp)}
                onClose={handleCloseHelp}
                sx={{
                  marginTop: "6px",
                }}
              >
                <HelpMenu mode={mode} onClose={handleCloseHelp} />
              </Menu>
            </Box>
            <Box alignItems={"center"}>
              <IconButton
                size="small"
                aria-label="user-menu"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <img
                  src={
                    mode === "light"
                      ? "/topbar/user-bar.svg"
                      : "/topbar/dark/user-bar.svg"
                  }
                  width={"24px"}
                  height={"24px"}
                  alt="User"
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                backgroundColor={mode === "light" ? "#FFF" : "#0F111B"}
                keepMounted
                id="usermenu1"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                  marginTop: "6px",
                }}
              >
                <UserMenu mode={mode} onClose={handleClose} />
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        id="sm-topbar"
        sx={{
          display: { md: "flex", lg: "none", xs: "flex" },
        }}
        alignItems={"end"}
        width={"100%"}
        flexDirection={"row-reverse"}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <IconButton
            size="small"
            aria-label="user-menu"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <img
              src={
                mode === "light"
                  ? "/topbar/user-bar.svg"
                  : "/topbar/dark/user-bar.svg"
              }
              width={"24px"}
              height={"24px"}
              alt="User Icon"
            />
          </IconButton>
        </Box>
        <Box mr={1} id="pointerCursor">
          <IconButton
            size="small"
            aria-label="help-menu"
            aria-controls="help-menu"
            aria-haspopup="true"
            onClick={handleClickHelp}
          >
            <img
              src={
                mode === "light" ? "/topbar/help.svg" : "/topbar/dark/help.svg"
              }
              alt="Help Icon"
            />
          </IconButton>
        </Box>
        {/* <Box mr={2} id="pointerCursor">
          {showInput ? (
            <Search>
              <SearchIconWrapper>
                <img
                  src={`/topbar/${
                    mode === "dark" ? "dark/search.svg" : "search.svg"
                  }`}
                />
              </SearchIconWrapper>
              <StyledInputBase
                onChange={handleInputChange}
                onClick={handleInputChange}
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
				
              />
              <img
                src={`/topbar/${mode === "dark" ? "dark/cut.svg" : "cut.svg"}`}
                className="mobile-search-cut-icon"
                onClick={handleInputChange}
              />
            </Search>
          ) : (
            <img
              onClick={() => setShowInput(true)}
              src={
                mode === "light"
                  ? "/topbar/search.svg"
                  : "/topbar/dark/search.svg"
              }
              alt="Search Icon"
            />
          )}
        </Box> */}
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#808698",
  "& .MuiInputBase-input": {
    border:
      theme.palette?.mode === "light"
        ? "1px solid #E8EBF3"
        : "1px solid #1E202D",
    borderRadius: "5px",
    padding: "5px 0px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "20ch",
        border: "1px solid #0C84FE",
      },
    },
  },
  "::placeholder": {
    color: "#808698",
    fontSize: "14px",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "inherit",
  "&:hover": {
    backgroundColor: "inherit",
  },
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(() => ({
  padding: "0 0 0 8px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  color: "#808698",
  justifyContent: "center",
}));