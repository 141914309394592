import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import Antd from "components/Table/Antd";
import TableHeading from "components/Typography/TableHeading";
import React, { useEffect, useState } from "react";
import { flowsAnalyticsMock } from "resources/constant";
import EmptyTableData from "./EmptyTableData";
import { checkEmptyState } from "resources/helpers/checkEmptyState";

export default function TableSecton() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const [tableData, setTableData] = useState();

	const columns = [
		{
			dataIndex: "name",
			key: "name",
			title: <TableHeading minWidth={"260px"}>Name</TableHeading>,
			sortable: false,
			width: "25%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "sent",
			key: "sent",
			title: <TableHeading minWidth={"100px"}>Sent</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.sent) - parseInt(b.sent),
			width: "12%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "clicks",
			key: "clicks",
			title: <TableHeading minWidth={"100px"}>Clicks</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.clicks) - parseInt(b.clicks),
			width: "12%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "ctr",
			key: "ctr",
			title: <TableHeading minWidth={"100px"}>CTR</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.ctr) - parseInt(b.ctr),
			width: "12%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "orders",
			key: "orders",
			title: <TableHeading minWidth={"100px"}>Orders</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.orders) - parseInt(b.orders),
			width: "12%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "revenue",
			key: "revenue",
			title: <TableHeading minWidth={"100px"}>Revenue</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.revenue) - parseInt(b.revenue),
			width: "8%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					${params}
				</Typography>
			),
		},
	];

	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		let data;
		if (!empty) {
			data = [...flowsAnalyticsMock];
		} else {
			data = [];
		}
		setTimeout(() => {
			setTableData([...data])
		}, 4000);
	}, []);

	return (
		<Box>
			<Antd
				mode={mode}
				columns={columns}
				rows={tableData}
				loading={tableData ? false : true}
				EmptyComponentTable={<EmptyTableData />}
			/>
		</Box>
	);
}