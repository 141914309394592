import DottedMap from "dotted-map";
import React, { useState } from "react";
import { Box, useTheme, styled, Typography } from "@mui/material";

const Map = ({salesByCountry}) => {
	const map = new DottedMap({ height: 60, grid: "vertical" });
	for(let i = 0; i < salesByCountry?.length; i++){
		map.addPin(salesByCountry[i]);
	}
	const points = map.getPoints();
	const pins = points.filter((point) => point.data);
	
	const svgOptions = {
		backgroundColor: "#FFFFFF",
		color: "#808698",
		radius: 0.35,
	};
	
		const viewBox = "0 0 130 61";
		const checkHighlight = (item) => pins.some((pin) => pin?.data === item?.data);
		const theme = useTheme();
		const { mode } = theme.palette;
		const [activeTooltip, setActiveTooltip] = useState(null);
		const lightMode = mode === "light" ? true : false;
	
		const showText = (value) => {
			console.log(value);
			setActiveTooltip(value);
		};
		const CustomBox = styled(Box)(({ theme, lightMode }) => ({
			color: lightMode ? "#0A0A18" : "#EBECF2",
			backgroundColor: theme.palette?.card?.background,
			position: "absolute",
			fontSize: "12px",
			padding: "14px 10px",
			width: "145px",
			border: `1px solid ${theme.palette?.card?.border}`,
			borderRadius: "12px",
			bottom: "17px",
			"::before": {
				content: `" "`,
				display: "block",
				position: "absolute",
				left: "42.5%",
				top: "100%",
				width: 0,
				height: 0,
				border: "10px solid transparent",
				"border-top-color": theme.palette?.card?.border,
			},
			"::after": {
				content: `" "`,
				display: "block",
				position: "absolute",
				left: "43%",
				top: "100%",
				width: 0,
				height: 0,
				border: " 9px solid transparent",
				"border-top-color": theme.palette?.card?.background,
			},
		}));

		return (
			<Box width="100%" position={"relative"}>
				{pins?.map((item, index) => {
					return (
						<Box
							key={index}
							top={item?.data?.name === "Canada" ? "18%" : item?.data?.name === "USA" ? "35%" : item?.data?.name === "United Kingdom" ? "18%" : "72%"} 
							left={item?.data?.name === "Canada" ? "5%" : item?.data?.name === "USA" ? "15%" : item?.data?.name === "United Kingdom" ? "40%" : "78%"} 
							style={{
								position: "absolute",
								left: `${item?.x}`,
								top: `${item?.y}`,
							}}
							sx={{
								visibility:
									activeTooltip === item?.data?.name ? "visible" : "hidden",
							}}
						>
							<CustomBox theme={theme} lightMode={lightMode}>
								<Typography color={"#9095A5"} fontSize={12} fontWeight={600}>
									{item?.data?.name}
								</Typography>
								<Typography
									color={theme?.palette?.pageHeading}
									fontSize={14}
									fontWeight={600}
								>
									${item?.data?.revenue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								</Typography>
								<Typography color={"#9095A5"} fontSize={12} fontWeight={600}>
									Rank :{" "}
									<span style={{ color: theme?.palette?.pageHeading }}>
										#{item?.data?.rank}
									</span>
								</Typography>
							</CustomBox>
						</Box>
					);
				})}
				<Box>
					<svg
						viewBox={viewBox}
						style={{ background: theme?.palette?.card?.background }}
						width="100%"
						height="100%"
						xmlns="http://www.w3.org/2000/svg"
					>
						{points.map((point, index) =>
							checkHighlight(point) ? (
								<React.Fragment key={index}>
									<circle
										cx={point.x}
										cy={point.y + 1}
										r={2}
										fill="#0C84FE"
										style={{ opacity: 0.3 }}
										className="tooltip-marker"
									/>
									<circle
										id="pointerCursor"
										cx={point.x}
										cy={point.y + 1}
										r={1}
										fill="#0C84FE"
										onMouseOver={() => {
											showText(point?.data?.name);
										}}
										onMouseLeave={() => {
											setActiveTooltip(null);
										}}
										style={{ opacity: 1 }}
									/>
								</React.Fragment>
							) : (
								<circle
									key={index}
									cx={point.x}
									cy={point.y + 1}
									r={svgOptions.radius}
									fill={svgOptions.color}
									style={{ opacity: 0.25 }}
								/>
							)
						)}
					</svg>
				</Box>
			</Box>
		);
};

export default Map;
