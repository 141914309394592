import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	Divider,
} from "@mui/material";
import Compliance from "./Compliance";
import SelectList from "./SelectList";
import Upload from "./Upload";
import CSV from "./CSV";
import { CustomDesc } from "./Common";
import Imported from "./Imported";

export default function ImportSubscribers({ open, handleClose }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [tab, setTab] = useState(0);
	const [listType, setListType] = useState("New List");
	const [imported, setImported] = useState(false);

	return (
		<Modal
			open={open}
			id="Import"
			aria-labelledby="Import"
			aria-describedby="Import"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				{!imported ? (
					<>
						<Box p="20px">
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								mb={"8px"}
							>
								<Typography
									color={theme.palette?.pageHeading}
									fontSize={"20px"}
									fontWeight={600}
									lineHeight={"normal"}
								>
									Import Subscribers
								</Typography>
								<img
									src="/icons/cut.svg"
									alt="cut"
									onClick={() => {
										setTab(0);
										handleClose();
									}}
									id="pointerCursor"
									style={{
										marginTop: "-10px",
									}}
								/>
							</Box>
							<Box>
								<CustomDesc theme={theme}>
									Important your existing SMS and email subscribers so you can
									use them in your campaigns.
								</CustomDesc>
							</Box>
						</Box>

						<Divider />
						<Box p="20px" display={"flex"} flexDirection={"column"}>
							<Box
								bgcolor={mode === "light" ? "#EFF7FF" : "#20212C"}
								display={"flex"}
								gap={belowSmall?'5px':"12px"}
								p={belowSmall ? "8px 17px" : "11px 17px"}
								width={"100%"}
								borderRadius={"4px"}
								mb={"20px"}
							>
								{TabOptions?.map((item, index) => {
									return (
										<React.Fragment key={index}>
											<Box key={index}>
												<Typography
													fontSize={belowSmall ? "12px" : "14px"}
													fontWeight={600}
													lineHeight={"normal"}
													sx={{
														opacity: !tab === index && !mode === "light" && "0.7",
													}}
													color={
														tab === index
															? "#0081FF"
															: theme?.palette?.pageHeading
													}
												>
													{item}
												</Typography>
											</Box>
											{index<TabOptions?.length-1&&<img src="/arrow/segment-right.svg" alt="segment-right"/>}
										</React.Fragment>
									);
								})}
							</Box>
							{tab === 0 && (
								<SelectList listType={listType} setListType={setListType} />
							)}
							{tab === 1 && <Compliance />}
							{tab === 2 && <Upload />}
							{tab === 3 && <CSV />}
							<Box
								display={tab === 2 ? "flex" : "block"}
								justifyContent={"space-between"}
								flexDirection={belowSmall ? "column" : "row"}
								alignItems={'center'}
							>
								{tab === 2 && (
									<Box mb={belowSmall ? "32px" : "0px"} id='pointerCursor'>
										<Typography
											width={"276px"}
											color={"#0081FF"}
											fontSize={14}
											fontWeight={600}
										>
											View Upload Instructions
										</Typography>
									</Box>
								)}
								<Box
									display={"flex"}
									justifyContent={belowSmall ? "space-between" : "end"}
									height={"40px"}
								>
									<Box>
										{tab > 0 && (
											<Button
												sx={{
													padding: "11px 20px",
													border: `1px solid ${theme.palette?.card?.border}`,
													backgroundColor: "transparent",
													marginRight: belowSmall ? "0px" : "8px",
												}}
												variant="outlined"
												onClick={() => {
													setTab(tab - 1);
												}}
											>
												<Typography
													fontSize={"14px"}
													fontWeight={"600"}
													color={theme.palette?.pageHeading}
													lineHeight={"normal"}
												>
													Back
												</Typography>
											</Button>
										)}
									</Box>

									{tab === 3 ? (
										<Button
											size={"small"}
											sx={{
												backgroundColor: "#0081FF",
												padding: "11px 20px",
												color: "#FFF",
												":hover": {
													color: mode === "light" ? "#0C0D17" : "#FFF",
												},
											}}
											variant="outlined"
											onClick={() => {
												// setTab(tab + 1);
												setImported(true);
											}}
										>
											<Typography fontSize={"14px"} fontWeight={"600"}>
												Import
											</Typography>
										</Button>
									) : (
										<Button
											size={"small"}
											sx={{
												backgroundColor: "#0081FF",
												padding: "11px 20px",
												color: "#FFF",
												":hover": {
													color: mode === "light" ? "#0C0D17" : "#FFF",
												},
											}}
											variant="outlined"
											onClick={() => {
												setTab(tab + 1);
											}}
										>
											<Typography fontSize={"14px"} fontWeight={"600"}>
												Next
											</Typography>
										</Button>
									)}
								</Box>
							</Box>
						</Box>
					</>
				) : (
					<Imported
						setTab={setTab}
						handleClose={handleClose}
						setImported={() => setImported(false)}
					/>
				)}
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "703px",
				backgroundColor: theme?.palette?.card?.background,
				// padding: "20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				// height:'407px',
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
				maxHeight: "90vh",
			}}
		>
			{children}
		</Box>
	);
};

const TabOptions = ["Select List", "Compliance", "Upload", "CSV"];
