/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
} from "@mui/material";
import OnboardingToolTip from "components/Tooltip/OnboardingToolTip";
import { txtCartFetch } from "utilifyFunctions";

export default function SelectPlanV4({setOnboarding}) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;

	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	const handleSelect = async function(skuId){
		console.log(skuId);
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/billing/change?skuId=starter_29_15_02&cycle=monthly");
			console.log(response);
			if(response){
				window.location.replace(response.redirect_url);
			}
	}

	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
			<Box>
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					mb={"20px"}
					mt={"14px"}
				>
					<img src="/img/onboarding/activate-account.svg" width={'100%'} alt="activate-account"/>
				</Box>
				<Box
					textAlign={"center"}
					mb={"34px"}
					display={"flex"}
					justifyContent={"center"}
				>
					<Box>
						<Typography
							fontSize={30}
							fontWeight={600}
							lineHeight={"normal"}
							color={"#FFF"}
							mb={"12px"}
						>
							Activate Your Account
						</Typography>
						<Typography
							fontSize={14}
							fontWeight={500}
							lineHeight={"20px"}
							color={"#FFF"}
							sx={{
								opacity: "0.5",
							}}
						>
							TxtCart automatically texts your abandoned checkouts. Approve the
							billing cap on Shopify to begin making sales.
						</Typography>
					</Box>
				</Box>
				<Box
					p={"21px 20px"}
					sx={{
						border: "1px solid #162C4A",
						borderRadius: "12px",
					}}
					mb='19px'
				>
					<Typography
						fontSize={14}
						fontWeight={600}
						lineHeight={"32px"}
						color={"#FFF"}
						mb={"13px"}
					>
						Here’s what you can expect:
					</Typography>
					<Box display={"flex"} flexDirection={"column"} gap={"20px"} mb="19px">
						{mock?.map((item, index) => {
							return (
								<Box
									display={"flex"}
									key={index}
									gap={"12px"}
									alignItems={"center"}
								>
									<Box
									minWidth={'27px'}
										sx={{
											width: "27px",
											height: "27px",
											display: "flex",
											borderRadius: "14px",
											justifyContent: "center",
											alignItems: "center",
											background: "#0081FF",
											border: "1.688px solid #3C9EFF",
										}}
									>
										{item?.id}
									</Box>
									<Box>
										<Typography
											color={"#ECF6FF"}
											fontSize={"12px"}
											fontWeight={500}
											lineHeight={"normal"}
											sx={{
												opacity: "0.5",
											}}
										>
											{item?.title}
										</Typography>
										<Typography
											color={"#0081FF"}
											fontSize={"14px"}
											fontWeight={600}
											lineHeight={"24px"}
										>
											{item?.desc}
										</Typography>
									</Box>
								</Box>
							);
						})}
					</Box>
				</Box>
				<Button
					fullWidth
					sx={{
						padding: "11px 18px",
						border: "1px solid #0081FF",
						backgroundColor: "#0081FF",
						height: "40px",
						color: "#FFF",
						":hover": {
							color: "#0081FF",
						},
					}}
					onClick={() => handleSelect()}
					variant="outlined"
				>
					<Typography fontSize={"14px"} fontWeight={"600"}>
						Get Started Free
					</Typography>
				</Button>
			</Box>
		</MainContainer>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				padding: "20px",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "512px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				"::-webkit-scrollbar": {
					display: "none",
				},
				// height: "935px",
			}}
		>
			{children}
		</Box>
	);
};

const RecommendedTag = () => {
	return (
		<Box
			sx={{
				background: "#7CFAB8",
				width: "159px",
				height: "21px",
				borderRadius: "100px",
			}}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			gap={"7px"}
		>
			<Typography
				fontSize={"12px"}
				fontWeight={600}
				lineHeight={"normal"}
				color={"#18113C"}
				mt={"-2px"}
			>
				Recommended for you
			</Typography>
			<OnboardingToolTip text="" />
		</Box>
	);
};

const mock = [
	{
		id: "1",
		title: "Today:",
		desc: "Get started with a TxtCart Free Trial",
	},
	{
		id: "2",
		title: "In the coming weeks:",
		desc: "We automatically text your abandoned checkouts and recover your lost sales",
	},
	{
		id: "3",
		title: "When checkouts are recovered",
		desc: "You only pay 15% of revenue share",
	},
];
