import { Button } from "@mui/material";
import { useTheme } from "@mui/styles";
// import { Typography } from "antd";
import React from "react";

export default function SecondaryButton({ children, ...props }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	return (
		<Button
			size={"small"}
			sx={{
				height:'40px',
				backgroundColor: "#0081FF",
				padding: "11px 31px",
				color: mode === "light" ? "#FFF" : "#FFF",
				":hover": {
					color: mode === "light" ? "#0C0D17" : "#FFF",
				},
				fontSize: "14px",
				fontWeight: "600",
			}}
			variant="outlined"
			{...props}
		>
			{children}
		</Button>
	);
}
