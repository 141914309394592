import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { DataContext } from "context/DataContext";

export default function Loading() {
	// const theme = useTheme();
	const {  showLoading } = useContext(DataContext);
	// const { mode } = theme.palette;
	const theme = useTheme();
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	useEffect(()=>{
		if(showLoading){
			document.body.style.overflow = 'hidden';
		}else{
			document.body.style.overflow = 'visible';
		}
	},[showLoading])
	return (
		<>
			{showLoading && (
				<Box
				className='loading-a'
					sx={{
						background: theme?.palette?.background?.paper,
						width:'100vw',
						height:'100vh',
						display:'flex',
						position:'fixed',
						alignItems:'center',
						justifyContent:'center',
						transition:' opacity 0.3s ease-in',
						zIndex: 999,
					}}
				>
					<Box
						id="loading-animation"
						width={"300px"}
						position={"absolute"}
						// top={"50%"}
						left={belowSmall&&"55%"}
						sx={{
							transform: belowSmall
								? "translate(-57%, -50%)"
								: "translate(-50%, -40%)",
							display: "flex",
							justifyContent: "center",
						}}
					>
						{/* <img src={`/img/${mode=='light'?'light':'dark'}-loading.gif`} width={"250px"} /> */}
						<img src={`/img/loader-3.gif`} width={belowSmall ? "50%" : "75%"} alt="loading"/>
					</Box>
				</Box>
			)}
		</>
	);
}
