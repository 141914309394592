import { styled } from "@mui/material";
import { Input } from "antd";


const TextInput = styled(Input)(({ theme }) => ({
	height: "40px",
	fontSize: "14px",
	fontWeight: "500",
	background: theme.palette?.background,
	color: theme.palette?.text,
	border: `1px solid ${theme.palette?.card?.border}`,
	"::placeholder": {
		color: "#808698",
		fontSize: "14px",
		fontWeight: "500",
	},
}));
export default TextInput;
