/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "context/DataContext";
import AnalyzingSales from "./Modals/AnalyzingSales";
import { useLocation, useNavigate } from "react-router-dom";
import SelectPlan from "./Modals/SelectPlan";
import ConfirmStore from "./Modals/ConfirmStore";
import ComplianceSettings from "./Modals/ComplianceSettings";
import MobileNumber from "./Modals/MobileNumber";
import Example from "./Modals/Example";
import { Modal, Backdrop, Box, Fade } from "@mui/material";
import SelectPlanV2 from "./Modals/SelectPlanV2";
import SelectPlanV3 from "./Modals/SelectPlanV3";
import SelectPlanV4 from "./Modals/SelectPlanV4";
import AdditionalTerms from "./Modals/AdditionalTerms";
import AdditionalPolicy from "./Modals/AdditionalPolicy"
import WelcomeV2 from "./Modals/WelcomeV2";
import { txtCartFetch } from "utilifyFunctions";
// import Terms from "./Modals/Terms";

export default function Onboarding() {
	const navigate = useNavigate();
	const [onboarding, setOnboarding] = useState();
	const { setShowFullPageConteffi } = useContext(DataContext);
	const [additionalTerms, setAdditionalTerms] = useState(false);
	const [additionalPolicy,setAdditionalPolicy] = useState(false);
	const [ABTest, setABTest] = useState();
	const [revIncrease, setRevIncrease] = useState();
	const [shopifyPlan, setShopifyPlan] = useState();
	const [skus, setSkus] = useState(null);
	const [recommended, setRecommended] = useState("");
	const [domain, setDomain] = useState("");
	const [onboardingNumber, setOnboardingNumber] = useState("");
	const [testMessage,setTestMessage] = useState("");
	const [recipientPhone, setRecipientPhone] = useState("");

	async function getSKUs(plan, ab){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/sku");
		for(let i = 0; i < response.length; i++){
			response[i].keyFeatures.shift();
		}
		if(response){
			let basicPlans = ['Basic Shopify','Extended Trial','Open Learning', 'trial'];
			console.log('shopify plan', plan);
			if(plan === 'Shopify Plus' && ab === 'A'){
				response.shift();
				setRecommended("Pro");
			}
			else if(plan === 'Advanced Shopify' || (plan === 'Shopify Plus' && ab === 'B')){
				response.pop();
				response.shift();
				setRecommended("Pro");
			} 
			else if(basicPlans.includes(plan)){
				console.log('basic');
				response.pop();
				response.pop();
				setRecommended("Starter");
			}
			else{
				response.pop();
				setRecommended("Growth");
			}
			setSkus(response);
		}
	}

	async function getDomain(){
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/domain");
		setDomain(response?.root);
	}

	async function loadOnboarding(){
		console.log('load onboarding');
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/onboarding/load");
		if(response){
			setABTest(response?.ABTest);
			setRevIncrease(response?.estimatedRevIncrease);
			setShopifyPlan(response?.shopifyPlan);
			setOnboardingNumber(response?.onboardingNumber)
			let basicPlans = ['Basic Shopify','Extended Trial','Open Learning', 'trial'];
			console.log(response.shopifyPlan);
			if(response.onboarded){
				setOnboarding(4);
			}
			else if(response.txtCartPlan !== 'Onboarding'){
				setOnboarding(3);
			}
			else if(basicPlans.includes(response.shopifyPlan)){
				setOnboarding(1.1);
			}
			else{
				setOnboarding(1);
			}
		}
		getSKUs(response.shopifyPlan, response.ABTest);
	}

	useEffect(() => {
		getDomain();
		loadOnboarding();
	}, []);
	
	useEffect(() => {
		if (onboarding) {
			document.body.style.overflow = "hidden";
		}
	});

	const handleClose = () => {
		setOnboarding(false);
		setShowFullPageConteffi(true);
		navigate("/");
	};
	useEffect(() => {
		return () => {
			setTimeout(() => {
				setShowFullPageConteffi(false);
			}, 5000);
		};
	}, []);
	return (
		<React.Fragment>
			<Modal
				className="Onboarding-modal"
				open={true}
				BackdropComponent={Backdrop}
				BackdropProps={{
					sx: {
						backdropFilter: "blur(4px)",
						backgroundColor: "transparent",
						"::-webkit-scrollbar": {
							display: "none",
						},
					},
				}}
			>
				<Box>
					{onboarding === 1 && (
						<Box>
							<AnalyzingSales setOnboarding={setOnboarding} revIncrease={revIncrease} ABTest={ABTest} shopifyPlan={shopifyPlan}/>
						</Box>
					)}
					{onboarding === 1.1 && (
						<Box>
							<WelcomeV2 setOnboarding={setOnboarding} ABTest={ABTest}/>
						</Box>
					)}
					{onboarding === 2.1 && (
						<Fade in={onboarding === 2.1} timeout={500}>
							<Box>
								<SelectPlan setOnboarding={setOnboarding} skus={skus} recommended={recommended}/>
							</Box>
						</Fade>
					)}
					{onboarding === 2.2 && (
						<Fade in={onboarding === 2.2} timeout={500}>
							<Box>
								<SelectPlanV2 setOnboarding={setOnboarding} />
							</Box>
						</Fade>
					)}
					{onboarding === 2.3 && (
						<Fade in={onboarding === 2.3} timeout={500}>
							<Box>
								<SelectPlanV3 setOnboarding={setOnboarding} />
							</Box>
						</Fade>
					)}
					{onboarding === 2.4 && (
						<Fade in={onboarding === 2.4} timeout={500}>
							<Box>
								<SelectPlanV4 setOnboarding={setOnboarding} />
							</Box>
						</Fade>
					)}
					{onboarding === 3 && (
						<Fade in={onboarding === 3} timeout={500}>
							<Box>
								{" "}
								<ConfirmStore setOnboarding={setOnboarding} />
							</Box>
						</Fade>
					)}
					{onboarding === 4 && (
						<Fade in={onboarding === 4} timeout={500}>
							<Box>
								<ComplianceSettings
									setOnboarding={setOnboarding}
									setAdditionalTerms={setAdditionalTerms}
									setAdditionalPolicy={setAdditionalPolicy}
									domain={domain}
								/>
							</Box>
						</Fade>
					)}
					{onboarding === 5 && (
						<Fade in={onboarding === 5} timeout={500}>
							<Box>
								<MobileNumber
									setOnboarding={setOnboarding}
									handleClose={handleClose}
									onboardingNumber={onboardingNumber}
									setTestMessage={setTestMessage}
									setRecipientPhone={setRecipientPhone}
								/>
							</Box>
						</Fade>
					)}
					{onboarding === 6 && (
						<Fade in={onboarding === 6} timeout={500}>
							<Box>
								<Example handleClose={handleClose} message={testMessage} recipientPhone={recipientPhone}/>
							</Box>
						</Fade>
					)}
					{additionalTerms && (
						<AdditionalTerms handleClose={() => setAdditionalTerms(false)} />
					)}
					{additionalPolicy && (
						<AdditionalPolicy handleClose={() => setAdditionalPolicy(false)} />
					)}
				</Box>
			</Modal>
		</React.Fragment>
	);
}
