import React, { useRef } from "react";
import { Box } from "@mui/material";
import "./style.css";
import {  Typography, useMediaQuery, useTheme } from "@mui/material";

const images = [
  {
    id: 1,
    img: "/img/tutorial/cartRecoveryThumbnail.jpeg",
    desc: "Cart Recovery",
    url:"https://www.youtube.com/watch?v=1I_IZP6Y17k"
  },
  {
    id: 2,
    img: "/img/tutorial/campaignTutorialThumbnail.jpeg",
    desc: "SMS Campaigns",
    url: "https://www.youtube.com/watch?v=sYmTnWyolWU"
  },
  {
    id: 3,
    img: "/img/tutorial/campaignAnalyticsThumbnail.jpeg",
    desc: "SMS Analytics",
    url:"https://www.youtube.com/watch?v=qTpcfMBgqF0"
  }
];
export default function App({setVideoUrl,setShowVideoModal}) {
  const sliderRef = useRef(null);
  const scrollAmount = 680;

  const theme = useTheme();
  const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { pageHeading } = theme.palette;

  const handleScroll = (direction) => {
    const container = sliderRef.current;

    if (direction === "next") {
      container.scrollLeft += scrollAmount;
      if (
        container.scrollLeft >=
        container.scrollWidth - container.clientWidth
      ) {
        container.scrollLeft = 0;
      }
    } else if (direction === "prev") {
      if (container.scrollLeft === 0) {
        container.scrollLeft = container.scrollWidth;
      }
      container.scrollLeft -= scrollAmount;
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        marginBottom: "50px",
      }}
    >
      <Box
        className="lastBtn"
        onClick={() => handleScroll("prev")}
        sx={{
          position: "absolute",
          top: "50%",
          left: belowSmall ? "-25px" : "-30px",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 10,
        }}
      >
        <img src="/icons/last.svg" alt="Previous" />
      </Box>
      <Box
        ref={sliderRef}
        sx={{
          display: "flex",
          overflowX: "scroll",
          overflowY: "hidden",
          flexWrap: belowSmall ? "wrap" : "nowrap",
          gap: belowSmall ? "16px" : "0",
          scrollBehavior: "smooth",
          // Hide scroll bar
          scrollbarWidth: "none", // Firefox
          "&::-webkit-scrollbar": {
            display: "none", // WebKit browsers
          },
        }}
      >
        {[...images].map((image, index) => (
          <Box
            key={image?.id}
            sx={{
              flex: belowSmall ? "1 1 100%" : "0 0 25%",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setVideoUrl(image?.url);
              setShowVideoModal(true);
            }}
          >
            <img
              className="image"
              alt="sliderImage"
              src={image?.img}
              style={{
                border: "1px solid #E8EBF3",
                borderRadius: "8px",
                width: belowSmall ? "100%" : "260px",
              }}
            />
            <Typography
              fontSize={16}
              fontWeight={600}
              lineHeight={"normal"}
              color={pageHeading}
              mt={"14px"}
            >
              {image?.desc}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Next Button */}
      <Box
        className="nextBtn"
        onClick={() => handleScroll("next")}
        sx={{
          position: "absolute",
          top: "50%",
          right: belowSmall ? "-25px" : "-30px",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 10,
        }}
      >
        <img src="/icons/next.svg" alt="Next" />
      </Box>
    </Box>
  );
}

