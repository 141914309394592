/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	Select,
	MenuItem,
	Popover,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker, Input } from "antd";
import { Add } from "resources/Icons/Arrows";
import { DeleteSVG } from "resources/Icons";
import { countryCode, operatorOptions, segmentCriteria } from "./mock";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SecondaryButton from "components/Buttons/SecondaryButton";
import CustomDatePicker from "components/Inputs/DatePicker";
import { CalenderSvg } from "resources/Icons/Campaigns";

export default function CreateSegment({ open, handleClose }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [row, setRow] = useState([
		{ numberOfOrder: "Number of Orders", operator: "Greater Than", value: "" },
	]);
	const closeModal = () => {
		setRow([
			{
				numberOfOrder: "Number of Orders",
				operator: "Greater Than",
				value: "",
			},
		]);
		handleClose();
	};

	const handleRowChange = () => {
		if (row?.length === 4) {
			return;
		}
		setRow([
			...row,
			{
				numberOfOrder: "",
				operator: "",
				value: "",
			},
		]);
	};

	const handleInputChange = (index, name, value, isRulesValue) => {
		let tmp = [...row];
		if (isRulesValue) {
			tmp[index]["rules"][name] = value;
		} else {
			tmp[index][name] = value;
		}
		setRow([...row]);
	};
	const handleDeleteRow = (index, isRules) => {
		let tmp = [...row];
		if (isRules) {
			tmp[index].rules = null;
		} else {
			tmp.splice(index, 1);
		}
		setRow([...tmp]);
	};
	const handleAddRule = (index) => {
		let tmp = [...row];
		if (tmp[index].rules) {
			return;
		}
		tmp[index].rules = {
			numberOfOrder: "",
			operator: "",
			value: "",
		};
		setRow([...tmp]);
	};

	const [isEditing, setIsEditing] = useState(false);
	const [headingText, setHeadingText] = useState("Name this segment");
	const [originalText, setOriginalText] = useState("Name this segment");
	const handleEditClick = () => {
		setOriginalText(headingText);
		setIsEditing(true);
	};

	const handleSave = () => {
		setIsEditing(false);
		// You can add logic to save the headingText to your backend or state
	};

	return (
		<Modal
			open={open}
			id="create_segments"
			aria-labelledby="create_segments"
			aria-describedby="create-segments"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					mb={"12px"}
				>
					<Box display={"flex"} alignItems={"center"} gap={"12px"}>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"20px"}
							fontWeight={600}
							lineHeight={"normal"}
							contentEditable={isEditing}
							suppressContentEditableWarning
						>
							{headingText}
						</Typography>
						{isEditing ? (
							<>
								<IconButton onClick={handleSave} color="primary" size={"small"}>
									Save
								</IconButton>
							</>
						) : (
							<IconButton
								onClick={handleEditClick}
								style={{
									color: "grey",
								}}
							>
								<EditIcon />
							</IconButton>
						)}
					</Box>
					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={closeModal}
						id="pointerCursor"
						style={{
							marginTop: "-10px",
						}}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					mb={2}
				>
					<CustomTypography theme={theme}>
						Segment Size: <b>Pending</b>
					</CustomTypography>
				</Box>
				{row?.map((item, index) => {
					return (
						<React.Fragment key={index}>
							{row?.length > 1 && index != 0 && (
								<Box mb={"16px"} mt={"16px"}>
									<Box width={belowTablet ? "100%" : "29%"}>
										<CustomTypography
											theme={theme}
											color="#0081FF"
											fontWeight={600}
										>
											AND
										</CustomTypography>
									</Box>
								</Box>
							)}
							<Box
								p={"16px"}
								bgcolor={"rgba(232, 235, 243, 0.25)"}
								borderRadius={"4px"}
								border={`1px solid ${theme?.palette?.card?.border}`}
							>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										flexDirection: belowTablet ? "column" : "row",
										alignItems: "center",
										gap: "12px",
									}}
									mb={2}
								>
									<FixContainer>
										<CustomTypography theme={theme}>
											Segment Criteria
										</CustomTypography>
										<CustomSelect
											onChange={(e) => {
												handleInputChange(
													index,
													"numberOfOrder",
													e?.target?.value,
													false
												);
											}}
											value={item?.numberOfOrder}
										>
											{segmentCriteria?.map((item, index) => {
												return (
													<CustomMenuItem key={index} value={item?.name}>
														{item?.name}
													</CustomMenuItem>
												);
											})}
										</CustomSelect>
									</FixContainer>
									<FixContainer>
										<CustomTypography theme={theme}>Operator</CustomTypography>
										<CustomSelect
											onChange={(e) => {
												handleInputChange(
													index,
													"operator",
													e?.target?.value,
													false
												);
											}}
											value={item?.operator}
										>
											{operatorOptions?.map((item, index) => {
												return (
													<CustomMenuItem key={index} value={item?.name}>
														{item?.name}
													</CustomMenuItem>
												);
											})}
										</CustomSelect>
									</FixContainer>
									<FixContainer>
										<CustomTypography theme={theme}>Value</CustomTypography>
										{item?.numberOfOrder === "Customer Phone Country Code" ? (
											<CustomSelect
												onChange={(e) => {
													handleInputChange(
														index,
														"value",
														e?.target?.value,
														false
													);
												}}
												value={item?.value}
											>
												{countryCode?.map((item, index) => {
													return (
														<CustomMenuItem key={index} value={item?.name}>
															{item?.name}
														</CustomMenuItem>
													);
												})}
											</CustomSelect>
										) : item?.numberOfOrder === "Last Texted Date" ? (
											<DatePickerCustom
												value={item?.value}
												onChange={(date) => {
													handleInputChange(index, "value", date, false);
												}}
											/>
										) : (
											<CustomInput
												onChange={(e) => {
													handleInputChange(
														index,
														"value",
														e?.target?.value,
														false
													);
												}}
												value={item?.value}
											/>
										)}
									</FixContainer>

									<Box
										display={"flex"}
										gap={"12px"}
										width={belowTablet ? "100%" : "25%"}
										justifyContent={item?.rules && "end"}
									>
										{!item?.rules && (
											<AddRuleButton
												onClick={() => {
													handleAddRule(index);
												}}
											/>
										)}
										<Box>
											<CustomTypography theme={theme}>&nbsp; </CustomTypography>
											<DeleteButton
												onClick={() => handleDeleteRow(index, false)}
											>
												<DeleteSVG />
											</DeleteButton>
										</Box>
									</Box>
								</Box>
								{item?.rules && (
									<React.Fragment>
										<Box width={belowTablet ? "100%" : "29%"}>
											<CustomTypography
												theme={theme}
												color="#0081FF"
												fontWeight={600}
												mb="16px"
											>
												OR
											</CustomTypography>
										</Box>
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												flexDirection: belowTablet ? "column" : "row",
												alignItems: "center",
												gap: "12px",
											}}
											mb={2}
										>
											<FixContainer>
												<CustomTypography theme={theme}>
													Segment Criteria
												</CustomTypography>
												<CustomSelect
													onChange={(e) => {
														handleInputChange(
															index,
															"numberOfOrder",
															e?.target?.value,
															true
														);
													}}
													value={item?.rules?.numberOfOrder}
												>
													{segmentCriteria?.map((item, index) => {
														return (
															<CustomMenuItem key={index} value={item?.name}>
																{item?.name}
															</CustomMenuItem>
														);
													})}
												</CustomSelect>
											</FixContainer>
											<FixContainer>
												<CustomTypography theme={theme}>
													Operator
												</CustomTypography>
												<CustomSelect
													onChange={(e) => {
														handleInputChange(
															index,
															"operator",
															e?.target?.value,
															true
														);
													}}
													value={item?.rules?.operator}
												>
													{operatorOptions?.map((item, index) => {
														return (
															<CustomMenuItem key={index} value={item?.name}>
																{item?.name}
															</CustomMenuItem>
														);
													})}
												</CustomSelect>
											</FixContainer>
											<FixContainer>
												<CustomTypography theme={theme}>Value</CustomTypography>
												{item?.rules?.numberOfOrder ==
												"Customer Phone Country Code" ? (
													<CustomSelect
														onChange={(e) => {
															handleInputChange(
																index,
																"value",
																e?.target?.value,
																true
															);
														}}
														value={item?.rules?.value}
													>
														{countryCode?.map((item, index) => {
															return (
																<CustomMenuItem key={index} value={item?.name}>
																	{item?.name}
																</CustomMenuItem>
															);
														})}
													</CustomSelect>
												) : item?.rules?.numberOfOrder === "Last Texted Date" ? (
													<DatePickerCustom
														value={item?.rules?.value}
														onChange={(date) => {
															handleInputChange(index, "value", date, true);
														}}
													/>
												) : (
													<CustomInput
														onChange={(e) => {
															handleInputChange(
																index,
																"value",
																e?.target?.value,
																true
															);
														}}
														value={item?.rules?.value}
													/>
												)}
											</FixContainer>

											<Box
												display={"flex"}
												gap={"12px"}
												width={belowTablet ? "100%" : "25%"}
											>
												<AddRuleButton
													onClick={() => {
														handleAddRule(index);
													}}
												/>
												<Box>
													<CustomTypography theme={theme}>
														&nbsp;{" "}
													</CustomTypography>
													<DeleteButton
														onClick={() => handleDeleteRow(index, true)}
													>
														<DeleteSVG />
													</DeleteButton>
												</Box>
											</Box>
										</Box>
									</React.Fragment>
								)}
							</Box>
						</React.Fragment>
					);
				})}

				<Box display={"flex"} justifyContent={"space-between"} mt={3}>
					<Button
						sx={{
							padding: "11px 20px",
							border: `1px solid ${theme.palette?.card?.border}`,
							backgroundColor: "transparent",
							marginRight: "20px",
						}}
						disabled={row?.length === 4 && true}
						variant="outlined"
						onClick={() => {
							handleRowChange();
						}}
					>
						<Add />
						<Typography
							fontSize={"14px"}
							fontWeight={"600"}
							color={theme.palette?.pageHeading}
							lineHeight={"normal"}
							ml={"10px"}
						>
							Add Filter (optional)
						</Typography>
					</Button>

					<SecondaryButton>Save</SecondaryButton>
				</Box>
			</MainContainer>
		</Modal>
	);
}
const CustomMenuItem = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<MenuItem
			sx={{
				background: theme?.palette?.card?.background,
				color: "#808698",
				fontWeight: 400,
				fontSize: "14px",
			}}
			{...props}
		>
			{children}
		</MenuItem>
	);
};

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "800px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
				maxHeight: "90vh",
			}}
		>
			{children}
		</Box>
	);
};

const CustomTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			color={theme.palette?.pageHeading}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			mb={1}
			{...props}
		>
			{children}
		</Typography>
	);
};

const DeleteButton = ({ ...props }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	return (
		<Button
			sx={{
				padding: "6px",
				border: `1px solid ${theme.palette?.card?.border}`,
				backgroundColor: lightMode ? "#FFF" : " rgba(255, 255, 255, 0.10);",
				lineHeight: "normal",
				width: "32px",
				minWidth: "32px",
				height: "40px",
			}}
			variant="outlined"
			{...props}
		>
			<DeleteSVG />
		</Button>
	);
};

const AddRuleButton = ({ ...props }) => {
	const theme = useTheme();

	return (
		<Box
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			height={"40px"}
			width={"123px"}
			mt={"24px"}
			id="pointerCursor"
			borderRadius={"4px"}
			bgcolor={theme?.palette?.card?.background}
			border={`1px solid ${theme?.palette?.card?.border}`}
			{...props}
		>
			<Box
				display={"flex"}
				gap={"4px"}
				alignItems={"center"}
				justifyContent={"center"}
			>
				<Add />
				<Typography fontSize={"14px"} fontWeight={600} color={"#0081FF"}>
					ADD RULE
				</Typography>
			</Box>
		</Box>
	);
};

const CustomSelect = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<Select
			sx={{
				backgroundColor: theme?.palette?.card?.background,
				"& .MuiSvgIcon-root": { color: "#C1C1C1" },
				"&:focus": {
					backgroundColor: "transparent",
				},
				fontSize: "14px",
				fontWeight: "500",
				lineHeight: "normal",
			}}
			IconComponent={ExpandMoreIcon}
			style={{ width: "100%", height: "40px" }}
			{...props}
			MenuProps={{
				PaperProps: {
					style: {
						maxHeight: 200,
						maxWidth: 300,
						marginTop: 3,
						borderRadius: 8,
						border: `1px solid ${theme?.palette?.card?.border}`,
					},
				},
			}}
		>
			{children}
		</Select>
	);
};

const CustomInput = ({ ...props }) => {
	const theme = useTheme();
	return (
		<Input
			{...props}
			style={{
				width: "100%",
				height: "40px",
				fontSize: "14px",
				fontWeight: "500",
				backgroundColor: theme?.palette?.card?.background,

				color: theme?.palette?.pageHeading,
			}}
		/>
	);
};

const FixContainer = ({ children }) => {
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Box
			width={belowTablet ? "100%" : "24%"}
			minWidth={belowTablet ? "100%" : "24%"}
			maxWidth={belowTablet ? "100%" : "24%"}
		>
			{children}
		</Box>
	);
};

const DatePickerCustom = ({ onChange, value }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	return (
		<Box position={"relative"} mt={"-30px"}>
			<Box
				position={"relative"}
				sx={{
					top: "37px",
					zIndex: "9",
					left: "7px",
					width: "10px",
				}}
			>
				<CalenderSvg />
			</Box>
			<CustomDatePicker selected={value} onChange={onChange}>
				<Box className={"btn-container"}>
					<Button
						size={"small"}
						sx={{
							padding: "11px 30px",
							border:
								mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D",
							color: theme.palette?.pageHeading,
							marginRight: "10px",
							borderRadius: "8px",
							backgroundColor: theme.palette?.card?.background,
						}}
						variant="outlined"
					>
						<Typography fontSize={"14px"} fontWeight={"600"}>
							Reset
						</Typography>
					</Button>
					<Button
						size={"small"}
						sx={{
							height: "40px",
							backgroundColor: "#0081FF",
							padding: "11px 31px",
							color: mode === "light" ? "#FFF" : "#FFF",
							":hover": {
								color: mode === "light" ? "#0C0D17" : "#FFF",
							},
							fontSize: "14px",
							fontWeight: "600",
							borderRadius: "8px",
						}}
						variant="outlined"
					>
						Done
					</Button>
				</Box>
			</CustomDatePicker>
		</Box>
	);
};
