import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	MenuItem,
	Select,
} from "@mui/material";
import { Input} from "antd";
import { TextInput } from "components/Inputs";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import styled from "styled-components";

export default function CreateDiscountModal({ open, handleClose, handleDiscountChange }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [discountType, setDiscountType] = useState("");
	const [newCode, setNewCode] = useState("");
	const [newValue, setNewValue] = useState(0);
	
	const TextInputNew = styled(Input)(() => ({
		height: "40px",
		fontSize: "14px",
		fontWeight: "500",
		background: "transparent",
		color: lightMode ? "#000D31" : "#FFF",
		borderRadius: "3px 0px 0px 3px !important",
		border: `1px solid ${lightMode ? "#E8EBF3" : "#1E202D"}`,
		"::placeholder": {
			color: "#808698",
			fontSize: "14px",
			fontWeight: "500",
		},
		"& .ant-input": {},
	}));

	return (
		<Modal
			open={open}
			id="create-discount"
			aria-labelledby="create-discount"
			aria-describedby="create-discount"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					mb={"16px"}
				>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"20px"}
						fontWeight={600}
						lineHeight={"normal"}
					>
						Create Discount
					</Typography>
					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={handleClose}
						id="pointerCursor"
						style={{
							marginTop: "-10px",
						}}
					/>
				</Box>

				<Box mb={"12px"}>
					<CustomTypography theme={theme}>Code Name</CustomTypography>
					<TextInput placeholder="" value={newCode} onChange={e => setNewCode(e.target.value)}
					style={{		
						height: "40px",
					fontSize: "14px",
					fontWeight: "500",
					background: "transparent",
					color: lightMode ? "#000D31" : "#FFF",
					borderRadius: "3px 0px 0px 3px !important",
					border: `1px solid ${lightMode ? "#E8EBF3" : "#1E202D"}`,
					"::placeholder": {
						color: "#808698",
						fontSize: "14px",
						fontWeight: "500",
					},
					"& .ant-input": {}}}/>
				</Box>

				<Box mb={"19px"}>
					<CustomTypography theme={theme}>Discount Type</CustomTypography>
					<Select
						sx={{
							border: `1px solid ${lightMode ? "#E8EBF3" : "#1E202D"}`,
							"& .MuiSvgIcon-root": { color: "#C1C1C1" },
						}}
						IconComponent={GridExpandMoreIcon}
						style={{ width: "100%", height: "40px" }}
						value={"Percent Off"}
						disabled
					>
						<CustomMenuItem value={"Percent Off"}>Percent Off</CustomMenuItem>
						{/* <CustomMenuItem value={"Fixed Amount Off"}>
							Fixed Amount Off
						</CustomMenuItem>
						<CustomMenuItem value={"Free Shipping"}>
							Free Shipping
						</CustomMenuItem> */}
					</Select>
					{/* <TextInput placeholder="" /> */}
				</Box>
				{discountType !== "Free Shipping" && (
					<Box mb={"19px"}>
						<CustomTypography theme={theme}>Value</CustomTypography>
						<Box display={"flex"}>
							<Box width={"95%"}>
								<TextInput
									placeholder=""
									type="Number"
									width={"100%"}
									value={newValue}
									onChange={e => setNewValue(e.target.value)}
									style={{		
										height: "40px",
									fontSize: "14px",
									fontWeight: "500",
									background: "transparent",
									color: lightMode ? "#000D31" : "#FFF",
									borderRadius: "3px 0px 0px 3px !important",
									border: `1px solid ${lightMode ? "#E8EBF3" : "#1E202D"}`,
									"::placeholder": {
										color: "#808698",
										fontSize: "14px",
										fontWeight: "500",
									},
									"& .ant-input": {}}}
								/>
							</Box>
							<Box
								width={"15%"}
								border={`1px solid ${theme?.palette?.card?.border}`}
								borderLeft={"none"}
								sx={{
									borderRadius: "0px 3px 3px 0px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									background: "rgb(235, 235, 235,0.3)",
								}}
							>
								{discountType === "Fixed Amount Off" ? "Off" : "%"}
							</Box>
						</Box>
					</Box>
				)}

				<Box display={"flex"} justifyContent={"end"}>
					<Button
						size={"small"}
						sx={{
							backgroundColor: "#0081FF",
							padding: "11px 20px",
							color: "#FFF",
							":hover": {
								color: mode === "light" ? "#0C0D17" : "#FFF",
							},
						}}
						variant="outlined"
						onClick={() => handleDiscountChange(newCode,newValue)}
					>
						<Typography fontSize={"14px"} fontWeight={"600"}>
							Create Code
						</Typography>
					</Button>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const CustomMenuItem = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<MenuItem
			sx={{
				background: theme?.palette?.card?.background,
			}}
			{...props}
		>
			{children}
		</MenuItem>
	);
};

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "418px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				maxHeight: "90vh",
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};

const CustomTypography = ({ children }) => {
	return (
		<Typography
			color={"#808698"}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			mb={1}
		>
			{children}
		</Typography>
	);
};
