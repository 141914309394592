import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import {
	Area,
	AreaChart,
	Bar,
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

export default function OrderOverview({ showBarChart = true, data = [] }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const CustomYAxisTick = (props) => {
		var { x, y, payload } = props;
		// Format the tick value as desired
		// if (payload.value === "900" ) {
		// 	y = y - 7;
		// }
		const formattedValue = new Intl.NumberFormat("en-US").format(payload.value);

		return (
			<g transform={`translate(${x},${y - 7})`}>
				<text
					x={0}
					y={0}
					dy={10}
					textAnchor="end"
					opacity={0.5}
					fontSize={"14px"}
					fill={mode === "light" ? "#0A0A18" : "#EBECF2"}
					// transform="rotate(-45)"
				>
					{formattedValue}
				</text>
			</g>
		);
	};
	const RoundBar = (props) => {
		const { fill, x, y, width, height } = props;

		return (
			<path d={getRoundedPath(x, y, width, height)} stroke="none" fill={fill} />
		);
	};
	const getRoundedPath = (x, y, width, height) => {
		const radius = 2; // Set your desired radius for rounding the top corners
		const halfWidth = width / 2;

		return `M${x},${y + radius}
				L${x + halfWidth},${y}
				L${x + width},${y + radius}
				V${y + height}
				H${x}
				V${y + radius}
				Z`;
	};
	const CustomTick = (props) => {
		const { x, y, payload } = props;
		let getX = (value, x) => {
			return x - 20
		};

		return (
			<g>
				<g>
					<text
						x={getX(payload?.value, x)}
						y={y + 15}
						fontSize={"14px"}
						opacity={0.5}
						fill={mode === "light" ? "#0A0A18" : "#EBECF2"}
					>
						{payload.value}
					</text>
				</g>
			</g>
		);
	};
	return (
		<div style={{ width: "100%" }}>
			<ResponsiveContainer width="100%" height={500}>
				{showBarChart ? (
					<BarChart
						width={500}
						height={300}
						data={data}
						margin={{
							top: 10,
							right: 0,
							left: -15,
							bottom: 0,
						}}
					>
						<CartesianGrid
							strokeDasharray="1 0"
							vertical={false}
							color={mode === "light" ? "#EBEBEB" : "#303343"}
							opacity={mode === "light" ? 0.5 : 0.2}
						/>
						<XAxis dataKey="date" tick={<CustomTick />} tickLine={false} />
						<YAxis
							axisLine={false}
							tickLine={false}
							allowDecimals={false}
							tick={<CustomYAxisTick />}
						/>
						<Tooltip content={<CustomTooltip />} cursor={false} />
						<Bar
							shape={<RoundBar />}
							barSize={6}
							maxBarSize={6}
							dataKey="cartRecoveryCount"
							fill="#0081FF"
							activeBar={false}
						/>
						<Bar
							shape={<RoundBar />}
							barSize={6}
							maxBarSize={6}
							dataKey="campaignsCount"
							fill="#9963FE"
							activeBar={false}
						/>
						{/* <Bar
							shape={<RoundBar />}
							barSize={6}
							maxBarSize={6}
							dataKey="automation"
							fill="#FF5C00"
							activeBar={false}
						/>
						<Bar
							shape={<RoundBar />}
							barSize={6}
							maxBarSize={6}
							dataKey="flow"
							fill="#FF00A8"
							activeBar={false}
						/> */}
					</BarChart>
				) : (
					<AreaChart
						width={500}
						height={300}
						data={data}
						margin={{
							top: 10,
							right: 0,
							left: -15,
							bottom: 0,
						}}
					>
						<CartesianGrid
							strokeDasharray="1 0"
							vertical={false}
							color={mode === "light" ? "#EBEBEB" : "#303343"}
							opacity={mode === "light" ? 0.5 : 0.2}
						/>
						<XAxis dataKey="date" tick={<CustomTick />} tickLine={false} />
						<YAxis
							axisLine={false}
							tickLine={false}
							tick={<CustomYAxisTick />}
						/>
						<Tooltip content={<CustomTooltip />} cursor={false} />
						<defs>
							<linearGradient
								id="cartRecovery-gradient"
								x1="0"
								y1="1"
								x2="0"
								y2="0"
							>
								<stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
								<stop offset="100%" stopColor="#0081FF" />
							</linearGradient>
							<linearGradient
								id="campaigns-gradient"
								x1="0"
								y1="1"
								x2="0"
								y2="0"
							>
								<stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
								<stop offset="100%" stopColor="#9963FE" />
							</linearGradient>
							{/* <linearGradient id="flow-gradient" x1="0" y1="1" x2="0" y2="0">
								<stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
								<stop offset="100%" stopColor="#FF00A8" />
							</linearGradient>
							<linearGradient
								id="automation-gradient"
								x1="0"
								y1="1"
								x2="0"
								y2="0"
							>
								<stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
								<stop offset="100%" stopColor="#FF5C00" />
							</linearGradient> */}
						</defs>
						<Area
							id="cartRecoveryCount"
							dataKey="cartRecoveryCount"
							key={"cartRecoveryCount"}
							fill="url(#cartRecovery-gradient)"
							stroke="#0081FF"
							opacity={1}
							fillOpacity={0.2}
							strokeWidth={2}
							isAnimationActive={true}
						/>
						<Area
							id="campaignsCount"
							dataKey="campaignsCount"
							key={"campaignsCount"}
							fill="url(#campaigns-gradient)"
							stroke="#9963FE"
							opacity={1}
							fillOpacity={0.2}
							strokeWidth={2}
							isAnimationActive={true}
						/>
						{/* <Area
							id="flow"
							dataKey="flow"
							key={"flow"}
							fill="url(#flow-gradient)"
							stroke="#9963FE"
							opacity={1}
							fillOpacity={0.2}
							strokeWidth={2}
							isAnimationActive={true}
						/>
						<Area
							id="automation"
							dataKey="automation"
							key={"automation"}
							fill="url(#automation-gradient)"
							stroke="#9963FE"
							opacity={1}
							fillOpacity={0.2}
							strokeWidth={2}
							isAnimationActive={true}
						/> */}
					</AreaChart>
				)}
			</ResponsiveContainer>
		</div>
	);
}

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		const payloadLabelMap = {
			Sun: "Sunday",
			Mon: "Monday",
			Tue: "Tuesday",
			Wed: "Wednesday",
			Thu: "Thursday",
			Fri: "Friday",
			Sat: "Saturday",
			Jan: "January",
			Feb: "February",
			Mar: "March",
			Apr: "April",
			May: "May",
			Jun: "June",
			Jul: "July",
			Aug: "August",
			Sep: "September",
			Oct: "October",
			Nov: "November",
			Dec: "December",
		};
		const nameMap = {
			cartRecoveryCount: "Cart Recovery",
			campaignsCount: "Campaigns",
			flow: "Flows",
			automation: "Automations",
		};
		return (
			<Box
				className="insight-graph-tooltip"
				sx={{
					padding: "12px",
					background: "#FFF",
					width: "175px",
					opacity: "0.95",
					borderRadius: "12px",
					boxShadow: "rgb(174, 174, 174) 0px 0px 10px",
				}}
			>
				<Typography
					fontSize={12}
					fontWeight={600}
					mb={"4px"}
					color={"#9095A5"}
					lineHeight={"16px"}
				>
					{payloadLabelMap[label]}
				</Typography>

				{payload.map((item) => (
					<div
						key={item.dataKey}
						style={{
							borderColor: item.color,
							margin: "4px 0px",
						}}
						className="insight-graph-tooltip-text"
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<span
								className={`${item?.dataKey}`}
								style={{ marginRight: "10px ", marginTop: "3px" }}
							>
								&nbsp;
							</span>
							<Typography
								fontSize={14}
								fontWeight={600}
								color={"#9095A5"}
								lineHeight={"16px"}
								textTransform={"capitalize"}
							>
								{nameMap[item.dataKey]}:&nbsp;
							</Typography>
						</Box>
						<Typography
							fontSize={14}
							fontWeight={600}
							color={"#000D31"}
							lineHeight={"16px"}
						>
							{item?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						</Typography>
					</div>
				))}
			</Box>
		);
	}
};
