import React, { useState } from "react";
import { Dropdown } from "antd";
import InputEmoji from "react-input-emoji";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { LightningSVG } from "resources/Icons/Inbox/Index";
import QuickReply from "./QuickReply";
import { txtCartFetch } from "utilifyFunctions";

export default function Text({ setText, isActive, disabled, setRefreshChat, setMessages }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const textAreaHeight = 200;
	const [message, setMessage] = useState("");

	const [visible, setVisible] = useState(false);

	const handleVisibleChange = (flag) => {
		setVisible(flag);
	};
	const closeDropdown = () => {
		setVisible(false);
	};
	const handleChange = (str) => {
		// Replace newlines with </br> for display purposes
		setMessage(str.replace(/\n/g, "</br>"));
	};

	const handleQuickReplySelect = (reply) => {
		const finalMessage = message.length === 0 ? reply.replace(/\n/g, "</br>") : `${message} ${reply.replace(/\n/g, "</br>")}`;
		setMessage(finalMessage);
		closeDropdown();
	};

	const sendMessage = async () => {
		if (!message.trim()) return;

		// Replace <br> with \n when sending the message
		const formattedMessage = message.replace(/<\/br>/g, "\n");

		const body = {
			message: formattedMessage,
			chatId: isActive?.id,
			type: isActive?.type,
		};

		try {
			// Add the new message directly to the messages array for immediate UI update
			setMessages((prevMessages) => [
				...prevMessages,
				{ content: formattedMessage, sentBy: "txtCart", read: true, sentAt: new Date().toISOString() },
			]);
	
			// Clear input and reset local state
			setMessage("");
	
			// Send the message to the backend
			const response = await txtCartFetch(
				"POST",
				`${process.env.REACT_APP_BACKEND_DOMAIN}/dashboard/inbox/conversation/send`,
				body,
				true
			);

			if (response?.success) {
				setText("");
			} else {
				console.error("Failed to send message:", response);
			}
		} catch (error) {
			console.error("Submission error:", error);
		}
	};

	return (
		<Box
			className={`inbox-reply-box ${!(isActive?.override && !disabled) && "greyEffect"}`}
			width="100%"
			position="relative"
			bgcolor={!isActive?.override || disabled ? "rgba(237, 239, 245, 0.74)" : theme.palette.card.background}
			borderRadius={isMobile ? "0px" : "12px"}
			border={`1px solid ${theme.palette.card.border}`}
			padding="0px 0px 35px 0px"
		>
			<InputEmoji
				borderColor="#FFF"
				borderRadius={isMobile ? "0px" : "12px"}
				fontSize="14px"
				height={textAreaHeight}
				backgroundColor={theme.palette.card.background}
				value={message.replace(/<br>/g, "\n")}
				onChange={handleChange}
				theme="auto"
				shouldReturn={true}
				placeholder="Type your message..."
				onKeyDown={(e) => {
					if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
						sendMessage();
						e.preventDefault();
					}
				}}
				sx={{
					whiteSpace: "pre-wrap",
				}}
			/>

			<Box
				position="absolute"
				sx={{
					bottom: "8px",
					left: "45px",
				}}
			>
				<Dropdown
					overlay={<QuickReply closeDropdown={closeDropdown} onQuickReplySelect={handleQuickReplySelect} setVisible={setVisible} />}
					open={visible}
					onOpenChange={handleVisibleChange}
					placement="topLeft"
					trigger={["click"]}
				>
					<Box id="pointerCursor">
						<LightningSVG />
					</Box>
				</Dropdown>
			</Box>
			<Box
				position={"absolute"}
				sx={{
					bottom: "12px",
					right: "8px",
				}}
			>
				<Button
					size={"small"}
					sx={{
						height: "40px",
						backgroundColor: "#0081FF",
						padding: "11px 18px",
						color: mode === "light" ? "#FFF" : "#FFF",
						":hover": {
							color: mode === "light" ? "#FFF" : "#FFF",
							backgroundColor: "#0081FF",
						},
						fontSize: "14px",
						fontWeight: "600",
						gap: "6px",
					}}
					onClick={()=>sendMessage()}
					variant="outlined"
				>
					{/* <SendSVG /> */}
					<img src="/icons/send-01.svg" alt="send" />
					Send
				</Button>
			</Box>
		</Box>
	);
}
