import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
} from "@mui/material";
import { txtCartFetch } from "utilifyFunctions";
import OnboardingToolTip from "components/Tooltip/OnboardingToolTip";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";

export default function SelectPlan({skus, recommended}) {
	const navigate = useNavigate();
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [period, setPlanPeriod] = useState("monthly");
	const [recommendedPlan, setRecommendedPlan] = useState("");
	const [showLoading, setShowLoading] = useState(true);
	const periodSelectedStyle = {
		fontSize: "14px",
		fontWeight: "600",
		color: "#0081FF",
	};
	const periodNonSelectedStyle = {
		fontSize: "14px",
		fontWeight: "500",
		color: "#85858C",
	};
	const Icons = {
		Basic: "💡",
		Starter: "💰",
		Growth: "🚀",
		Pro: "🏆",
	};

	const [showCardForMobile, setShowCardForMobile] = useState("Pro");
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleSelect = async function(skuId){
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/billing/change?skuId="+skuId + "&cycle="+period);
			if(response){
				window.location.replace(response.redirect_url);
			}
	}

	useState(() => {
		setTimeout(() => {
			setShowLoading(false);
		}, 3000);
		setTimeout(() => {
			setRecommendedPlan(recommended);
		}, 3000);
	}, []);

	return (
		<MainContainer
			lightMode={lightMode}
			belowTablet={belowTablet}
			isMobile={isMobile}
		>
			<Box>
				{showLoading && <ProgressBar />}
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					mb={"10px"}
					mt={"14px"}
				>
					<img src="/img/onboarding/step2.svg" alt="step2"/>
				</Box>
				<Box textAlign={"center"} mb={"16px"}>
					<Typography
						fontSize={30}
						fontWeight={600}
						lineHeight={"normal"}
						color={"#FFF"}
						mb={"12px"}
					>
						Select A Plan
					</Typography>
					<Typography
						fontSize={14}
						fontWeight={500}
						lineHeight={"20px"}
						color={"#FFF"}
						sx={{
							opacity: "0.5",
						}}
					>
						$1,000 in commission free sales on your 14-day trial!
					</Typography>
				</Box>

				<Box display={"flex"} justifyContent={"center"} mb={"10px"}>
					<Box
						width={"210px"}
						sx={{
							display: "flex",
							borderRadius: "4px",
							gap: "5px",
							border: "1px solid rgba(235, 235, 235, 0.15);",
							padding: "4px",
						}}
						position={"relative"}
					>
						<Box
							id="pointerCursor"
							sx={{
								background:
									period === "monthly" ? "rgb(0, 129, 255,0.2)" : "transparent",
								borderRadius: "4px",
								padding: "6px 20px",
							}}
							onClick={() => {
								setPlanPeriod("monthly");
							}}
						>
							<Typography
								style={
									period === "monthly"
										? periodSelectedStyle
										: periodNonSelectedStyle
								}
							>
								Monthly
							</Typography>
						</Box>
						<Box
							id="pointerCursor"
							sx={{
								borderRadius: "4px",
								padding: "6px 20px",
								background:
									period === "Annually" ? "rgb(0, 129, 255,0.2)" : "transparent",
							}}
							onClick={() => {
								setPlanPeriod("Annually");
							}}
						>
							<Typography
								style={
									period === "Annually"
										? periodSelectedStyle
										: periodNonSelectedStyle
								}
							>
								Annually
							</Typography>
						</Box>
						<Box
							position={"absolute"}
							sx={{
								bottom: "-28px",
								right: "-24px",
							}}
						>
							<img src="/img/onboarding/arrow.svg" alt="arrow"/>
						</Box>
					</Box>
				</Box>
				<Box mb={"15px"} display={"flex"} justifyContent={"center"}>
					<Typography
						fontSize={"14px"}
						fontWeight={500}
						lineHeight={"normal"}
						color={"#0081FF"}
						ml={"104px"}
					>
						2 months free!
					</Typography>
				</Box>
				{isMobile && (
					<PlanSelectorMobile
						setShowCardForMobile={setShowCardForMobile}
						showCardForMobile={showCardForMobile}
					/>
				)}

				<Box display={isMobile ? "block" : "flex"} gap={"24px"} mb={"30px"}>
					{skus?.slice(0, 3)?.map((item, index) => {
						return (
							<Box
								width={"100%"}
								display={
									isMobile
										? showCardForMobile === item?.displayName
											? "block"
											: "none"
										: "block"
								}
								key={index}
								sx={{
									background: "#FFF",
									border: `1px solid ${
										recommendedPlan === item?.displayName ? "#7CFAB8" : "#E8EBF3"
									}`,
									borderRadius: "12px",
								}}
							>
								<Box
									sx={{
										padding: "12px",
										borderBottom: `1px solid #EBEBEB`,
									}}
								>
									<Box
										display={"flex"}
										gap={"9px"}
										alignItems={"center"}
										mb={"5px"}
									>
										<Typography
											fontSize={20}
											fontWeight={600}
											lineHeight={"normal"}
											color="#0A0A18"
										>
											{item?.displayName} {Icons[item?.displayName]}
										</Typography>
										{recommendedPlan === item?.displayName && <RecommendedTag />}
									</Box>

									<Typography
										fontSize={14}
										fontWeight={500}
										lineHeight={"normal"}
										color="#0A0A18"
										sx={{
											opacity: "0.5",
										}}
										mb={"8px"}
										minHeight={"40px"}
									>
										{item?.internalDescription}
									</Typography>
									<Box display={"flex"} alignItems={"baseline"} mb={"0px"}>
										<Typography
											fontSize={"32px"}
											fontWeight={"700"}
											color={"#18113C"}
											lineHeight={"normal"}
										>
												{period === "monthly"
													? "$"+item?.recurringFee
													: item.allowAnnual ? "$"+(item?.annualRecurring / 12).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
												: "Ineligible"}
										</Typography>
										<Typography
											ml={1}
											fontSize={"14px"}
											color={"#696E7E"}
											fontWeight={"400"}
											lineHeight={"22px"}
										>
											{item.allowAnnual ? "/ month" : ""}
										</Typography>
									</Box>
									<Box>
										<Typography
											fontSize={14}
											fontWeight={500}
											lineHeight={"normal"}
											color="#0A0A18"
											sx={{
												opacity: "0.5",
											}}
											mb={"8px"}
										>
											{(period === "Annually" && item?.allowAnnual) ?  ("$"+(item?.annualRecurring) + " billed annually") : <>&nbsp;</>}
										</Typography>
									</Box>
									<Box mb={"17px"}>
										<Typography
											fontSize={"14px"}
											fontWeight={500}
											lineHeight={"normal"}
											color={"#0081FF"}
										>
											{(period === "monthly" || item?.allowAnnual) ? ("+" + (item?.commissionPercent * 100).toFixed(1).toString() + "% of cart recovery") : <>&nbsp;</>}
										</Typography>
									</Box>
									<Box>
										<Button
											fullWidth
											sx={{
												padding: "11px 18px",
												border: "1px solid #0081FF",
												backgroundColor:
												recommendedPlan === item?.displayName ? "#0081FF" : "transparent",
												height: "40px",
												color: recommendedPlan === item?.displayName ? "#FFF" : "#0081FF",
												":hover": {
													color: "#0081FF",
												},
											}}
											onClick={() => {handleSelect(item?.skuId)}}
											variant="outlined"
										>
											<Typography fontSize={"14px"} fontWeight={"600"}>
												Start Free Trial
											</Typography>
										</Button>
									</Box>
								</Box>
								<Box p={"20px"}>
									<Typography
										color={"#18113C"}
										fontSize={14}
										fontWeight={600}
										lineHeight={"normal"}
										mb={"20px"}
									>
										{item?.displayHeading}
									</Typography>
									<Box>
										{item?.keyFeatures?.map((item, index) => {
											return (
												<Typography
													key={index}
													fontSize={"14px"}
													fontWeight={"400"}
													color={"#696E7E"}
													mb={2}
												>
													<img
														src="/arrow/tick.svg"
														style={{
															marginRight: "10px",
														}}
														alt="tick"
													/>
													{item}
												</Typography>
											);
										})}
									</Box>
								</Box>
							</Box>
						);
					})}
				</Box>
			</Box>
		</MainContainer>
	);
}

const MainContainer = ({ children, isMobile, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				padding: isMobile ? "20px 15px" : "20px 35px",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "1162px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				"::-webkit-scrollbar": {
					display: "none",
				},
				// height: "935px",
			}}
		>
			{children}
		</Box>
	);
};

const RecommendedTag = () => {
	return (
		<Box
			sx={{
				background: "#7CFAB8",
				width: "159px",
				height: "21px",
				borderRadius: "100px",
			}}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			gap={"7px"}
		>
			<Typography
				fontSize={"12px"}
				fontWeight={600}
				lineHeight={"normal"}
				color={"#18113C"}
				mt={"-2px"}
			>
				Recommended for you
			</Typography>
			<OnboardingToolTip text="" />
		</Box>
	);
};

const PlanSelectorMobile = ({ showCardForMobile, setShowCardForMobile }) => {
	const cardSelectedStyle = {
		fontSize: "14px",
		fontWeight: "600",
		color: "#FFF",
	};
	const cardNonSelectedStyle = {
		fontSize: "14px",
		fontWeight: "500",
		color: "#85858C",
	};
	return (
		<Box
			// width={"320px"}
			sx={{
				display: "flex",
				borderRadius: "4px",
				gap: "2px",
				border: "1px solid rgba(235, 235, 235, 0.15);",
				padding: "4px",
			}}
			position={"relative"}
			mb={"16px"}
		>
			{planName?.map((item, index) => {
				return (
					<Box
						key={index}
						width={"100%"}
						id="pointerCursor"
						sx={{
							background:
								showCardForMobile === item
									? "#0081FF"
									: "rgb(255, 255, 255,0.1)",
							borderRadius: "4px",
							padding: "6px 0",
							display: "flex",
							justifyContent: "center",
						}}
						onClick={() => {
							setShowCardForMobile(item);
						}}
					>
						<Typography
							style={
								showCardForMobile === item
									? cardSelectedStyle
									: cardNonSelectedStyle
							}
						>
							{item}
						</Typography>
					</Box>
				);
			})}
		</Box>
	);
};

const planName = ["Starter", "Growth", "Pro"];
