import React from "react";
import {
	Modal,
	Box,
	Backdrop,
	Typography,
	Button,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LockedModal({ mode, open, setShowVideoModal, message }) {
	const lightMode = mode === "light" ? true : false;
	const theme = useTheme();
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Modal
			open={open}
			// onClose={handleClose}
			id="LockedPage"
			BackdropComponent={Backdrop}
			BackdropProps={{
				sx: { backdropFilter: "blur(4px)", backgroundColor: "transparent" },
			}}
			aria-labelledby="locked-page"
			aria-describedby="locked-page-modal"
			sx={{
				"& .css-r8sld8-MuiModal-root": {
					position: "static !important",
				},
			}}
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box display={"flex"} flexDirection={"column"}>
					<Box display={"flex"} justifyContent={"center"}>
						<Box
							sx={{
								background: lightMode ? "#E6F3FF" : "rgba(230, 243, 255, 0.10)",
								width: "80px",
								height: "80px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								borderRadius: "100px",
							}}
							mb={2}
						>
							<img src="/icons/modal/lock.svg" alt="Icon" />
						</Box>
					</Box>

					<Typography
						color={lightMode ? "#000D31" : "#FFF"}
						fontSize={"24px"}
						fontWeight={600}
						mb={"12px"}
						textAlign={"center"}
					>
						Upgrade to Unlock
					</Typography>
					<Typography
						color={lightMode ? "#000D31" : "#FFF"}
						fontSize={"14px"}
						fontWeight={500}
						mb={3}
						sx={{
							// width: "90%",
							opacity: "0.5",
							textAlign: "center",
						}}
					>
						{message}
					</Typography>
					<Box
						display={"flex"}
						justifyContent={"space-around"}
						flexDirection={belowTablet ? "column" : "row"}
						alignItems={"center"}
					>
						<WatchVideoButton belowTablet={belowTablet} setShowVideoModal={setShowVideoModal}/>
						<GetFeatureButton lightMode={lightMode} />
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const WatchVideoButton = ({ lightMode, belowTablet, setShowVideoModal }) => {
	return (
		<Button
			size={"small"}
			sx={{
				width: "150px",
				marginBottom: belowTablet ? "10px" : "0px",
				height: belowTablet ? "100%" : "40px",
				backgroundColor: "transparent",
				border: "1px solid #0C84FE",
				padding: "5px 15px",
				color: "#FFF",
				":hover": {
					color: lightMode ? "#0081FF" : "#FFF",
				},
			}}
			variant="outlined"
			onClick={setShowVideoModal}
		>
			<img src="/icons/modal/play.svg" alt="play" />
			<Typography ml={1} color={"#0C84FE"} fontSize={"14px"} fontWeight={600}>
				Watch Video
			</Typography>
		</Button>
	);
};
const GetFeatureButton = ({ lightMode }) => {
	const navigate = useNavigate()
	return (
		<Button
			size={"small"}
			sx={{
				width: "150px",
				height: "40px",
				padding: "5px 15px",
				backgroundColor: "#0081FF",
				color: "#FFF",
				":hover": {
					color: lightMode ? "#0081FF" : "#FFF",
				},
			}}
			variant="outlined"
			onClick={()=>navigate("/settings?tab=billing")}
		>
			<Typography fontSize={"14px"} fontWeight={600}>
				Get Feature
			</Typography>
		</Button>
	);
};

const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "465px",
				backgroundColor: lightMode ? "#FFF" : "#0C0D17",
				padding: belowTablet ? "30px 20px" : "40px 64px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
			}}
		>
			{children}
		</Box>
	);
};
