import { Button, Typography } from "@mui/material";
import React from "react";

export default function NextButton({ mode, ...props }) {
	return (
		<Button
			size={"small"}
			sx={{
				backgroundColor: "#0081FF",
				padding: "11px 31px",
				color: mode === "light" ? "#FFF" : "#0C0D17",
				":hover": {
					color: mode === "light" ? "#0C0D17" : "#FFF",
				},
			}}
			variant="outlined"
			{...props}
		>
			<Typography fontSize={"14px"} fontWeight={"600"}>
				Continue
			</Typography>
		</Button>
	);
}
