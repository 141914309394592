/* eslint-disable no-unused-vars */
import React from "react";
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	ResponsiveContainer,
	Tooltip,
} from "recharts";

export default function FunnnelChart({ data, opacity, lightMode, domain }) {
	const getClasses = (opacity) => {
		if (opacity === "0.2") {
			return "funnel-chart-first";
		}
		if (opacity === "0.07") {
			return "funnel-chart-last";
		}
		return "";
	};
	return (
		<ResponsiveContainer
			width="100%"
			height="100%"
			className={getClasses(opacity)}
		>
			<AreaChart
				width={500}
				height={400}
				data={data}
				margin={{
					top: 10,
					right: 0,
					left: 0,
					bottom: 0,
				}}
			>
				<XAxis dataKey="name" hide={true} />
				<YAxis
					axisLine={false}
					hide={true}
					tickLine={false}
					tickMargin={1}
					type="number"
					domain={domain} // Set the domain to range from 0 to 10
				/>
				<defs>
					<linearGradient id="funnel-gradient" x1="0" y1="1" x2="0" y2="0">
						<stop offset="0%" stopColor="rgba(153, 99, 254, 0.00)" />
						<stop offset="100%" stopColor="#0C84FE" />
					</linearGradient>
				</defs>

				{/* <Tooltip /> */}
				<Area
					dataKey="uv"
					fill={lightMode ? "#0C84FE" : "url(#funnel-gradient)"}
					stroke="#0C84FE"
					opacity={1}
					fillOpacity={opacity ? opacity : 1}
					baseLine={8}
					strokeWidth={1}
					isAnimationActive={false}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
}
