import React, { useEffect, useState } from "react";
import {
	Typography,
	Box,
	useTheme,
	// Tooltip,
	useMediaQuery,
} from "@mui/material";
import ConfettiExplosion from "react-confetti-explosion";
import Tooltip from "../Tooltip";
import { formatValue } from "resources/helpers/valueFormat";
import { checkEmptyState } from "resources/helpers/checkEmptyState";

export default function Campaigns({ mode, title, value, desc, icon ,valueType}) {
	const getMode = useTheme();
	const theme = useTheme();
	const [emptyState, setEmptyState] = useState(false);

	const [confetti, setConfetti] = useState({
		show: false,
		coordinates: { x: 0, y: 0 },
	});
	const handleCardClick = (e) => {
		const x = e.clientX;
		const y = e.clientY;

		setConfetti({
			show: true,
			coordinates: { x, y },
		});

		setTimeout(() => {
			setConfetti({
				show: false,
				coordinates: { x: 0, y: 0 },
			});
		}, 1000); // Change the duration as needed
	};
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const lightMode = mode === "light" ? true : false;
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		if (empty) {
			setEmptyState(true);
		}
	}, []);
	return (
		<Box
			id="pointerCursor"
			onClick={handleCardClick}
			sx={{
				width: belowTablet ? "100%" : "32%",
				display: "flex",
				flexDirection: "column",
				padding: "20px",
				borderRadius: "12px",
				border: lightMode ? "1px solid #E8EBF3" : "1px solid #1E202D",
				background: getMode.palette?.card?.background,
				":hover": {
					boxShadow: lightMode
						? "5px 6px 5px 0px rgba(0, 0, 0, 0.1)"
						: "5px 6px 5px 0px rgb(40 40 40)",
				},
			}}
		>
			<Box
				display={"flex"}
				justifyContent="space-between"
				alignItems={"center"}
				mb={2}
			>
				<Box display={"flex"} gap={"8px"}>
					<Typography
						fontSize={"16px"}
						color={lightMode ? "#000D31" : "#EBECF2"}
						fontWeight={600}
						lineHeight={"normal"}
						sx={{
							opacity: "0.5",
						}}
					>
						{title}
					</Typography>
					<Box>
						{" "}
						<Tooltip
							text={desc}
							lightMode={lightMode}
							belowTablet={belowTablet}
						/>
					</Box>
				</Box>
				<Box>{icon}</Box>
			</Box>
			<Box padding={"0 50%"}>
				{confetti.show && (
					<ConfettiExplosion
						particleSize={7}
						particleCount={30}
						force={1}
						width={300}
						height={200}
					/>
				)}
			</Box>
			<Box sx={{ display: "flex" }} mb={"6px"}>
				<Typography
					fontSize={"30px"}
					color={lightMode ? "#000D31" : "#EBECF2"}
					fontWeight={600}
					lineHeight={"normal"}
				>
					{emptyState
						? formatValue(0, valueType)
						: formatValue(value, valueType)}
				</Typography>
			</Box>
		</Box>
	);
}