/* eslint-disable no-unused-vars */
import { Grid, Box, useTheme, Typography, useMediaQuery } from "@mui/material";

import React, { useState, useEffect } from "react";
import Antd from "components/Table/Antd";
import TableHeading from "components/Typography/TableHeading";
import ConversationModal from "components/Modal/Conversation";
import PageHeading from "components/Typography/PageHeading";
import { txtCartFetch } from "utilifyFunctions";
import EmptyTableData from "./Components/EmptyComponentTable";

export default function Conversations() {
	const theme = useTheme();
	const mode = theme?.palette?.mode;
	const lightMode = mode === "light" ? true : false;
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const [openModal, setOpenModal] = useState(false);
	const [recipient, setRecipient] = React.useState("");
	const [selectedRow, setSelectedRow] = useState(null);
	const [conversationRecords, setConversationRecords] = React.useState(null);
	const [modalContent, setModalContent] = useState([]);

	const handleCloseModal = () => {
		setSelectedRow(null);
		setModalContent([]);
		setOpenModal(false);
	};

	async function getConversations() {
		console.log('GET CONVERSATIONS');
		const response = await txtCartFetch("GET",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/cart/conversations?limit=500");
		console.log(response);
		if(response){
			for(let i = 0; i < response.length; i++){
				if(response[i].lastMessage){
					let messageDate = new Date(response[i].lastMessage);
					let day = messageDate.toLocaleDateString("default",{ weekday: 'short'});
					let month =  messageDate.toLocaleDateString("default",{month: "short"});
					response[i].date = day + ", " + month + " " + messageDate.getDate() + " " + messageDate.getFullYear();
				} else{
					response[i].date = "";
				}
			}
			console.log('RESPONSE');
			console.log(response);
			setConversationRecords(response);
		}
		return response?.revenue;
	}

	const handleOpenModal = (rowId,data) => {
		console.log(data);
		let modalContent = []

		for(let i = 0; i < data.messages.length; i++){
			modalContent.push({message: data.messages[i], txtCartSent: data.senderArray[i]});
		}
		console.log(modalContent);
		setSelectedRow(rowId);
		setModalContent(modalContent);
		setRecipient(data.firstName);
		setOpenModal(true);
	};

	useEffect(() => {
		getConversations();
		return;
	}, []);

	const TableColumns = [
		{
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			title: <TableHeading minWidth={"160px"}>Number</TableHeading>,
			sortable: true,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a.phoneNumber.replace("+","")) - parseInt(b.phoneNumber.replace("+","")),
			width: "27%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "firstName",
			key: "name",
			title: <TableHeading minWidth={"160px"}>Name</TableHeading>,
			width: "27%",
			showSorterTooltip: false,
			sorter: (a, b) =>a.firstName.localeCompare(b.firstName),
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "conversationLength",
			key: "_id",
			title: <TableHeading minWidth={"250px"}>Last Message</TableHeading>,
			width: "32%",
			render: (params,key) =>
				params?.id === 0 ? (
					<Typography
						fontSize={14}
						fontWeight={500}
						color={lightMode ? "#000D31" : "#FFF"}
					>
						{params}
					</Typography>
				) : (
					<Typography
						fontSize={14}
						fontWeight={500}
						color={lightMode ? "#000D31" : "#FFF"}
					>
						<strong
							style={{ color: "#2196F3", cursor: "pointer" }}
							onClick={() => handleOpenModal(params,key)}
						>
							{params} &nbsp;{" "}
						</strong>{" "}
						{key.date}
					</Typography>
				),
		},
		{
			key: "status",
			dataIndex: "status",
			title: <TableHeading minWidth={"160px"}>Status</TableHeading>,
			width: "160px",
			render: (params) =>
				params?.id === 0 ? (
					<Typography
						fontSize={14}
						fontWeight={500}
						color={lightMode ? "#000D31" : "#FFF"}
					>
						{params}
					</Typography>
				) : (
					<>{showStatus(params)}</>
				),
		},
	];

	const showStatus = (value) => {
		return mapStatus[value];
	};

	const mapStatus = {
		"Pending": (
			<span
				className={`conversation_status ${
					lightMode ? "Awaiting_Response" : "Awaiting_Response_Dark"
				}`}
			>
				Awaiting Response
			</span>
		),
		"Customer Replied": (
			<span
				className={`conversation_status ${
					lightMode ? "Customer_Replied" : "Customer_Replied_Dark"
				}`}
			>
				Customer Replied
			</span>
		),
		"Discount Offered": (
			<span
				className={`conversation_status ${
					lightMode ? "Discount_Offered" : "Discount_Offered_Dark"
				}`}
			>
				Discount Offered
			</span>
		),
		"Sold": (
			<span
				className={`conversation_status ${
					lightMode ? "Ordered" : "Ordered_Dark"
				}`}
			>
				Ordered
			</span>
		),
		"Recovered": (
			<span
				className={`conversation_status ${
					lightMode ? "Cart_Viewed" : "Cart_Viewed_Dark"
				}`}
			>
				Cart Viewed
			</span>
		),
	};
	
	return (
			<Grid container spacing={2} alignItems={"center"}>
				<Grid item container xs={12}>
					<PageHeading mb={2}>All Abandoned Cart Conversations</PageHeading>
					<Grid item xs={12}>
						<Box
							sx={{
								borderBottom: `1px solid ${theme.palette?.card?.border}`,
								borderBottomLeftRadius: "12px",
								borderBottomRightRadius: "12px",
							}}
						>
							<Antd mode={mode} 
								rows={conversationRecords} 
								columns={TableColumns} 
								EmptyComponentTable={<EmptyTableData/>}
								loading={conversationRecords ? false : true}
							/>
						</Box>
					</Grid>
				</Grid>
				<ConversationModal
					openModal={openModal}
					handleCloseModal={handleCloseModal}
					lightMode={lightMode}
					isSmall={belowSmall}
					modalContent={modalContent}
					recipient={recipient}
				/>
			</Grid>
	);
}
