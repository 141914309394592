import {
	Box,
	Grid,
	ListItemText,
	MenuItem,
	MenuList,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Analytics from "../Analytics/Campaigns/components/Analytics";
import Antd from "components/Table/Antd";
import { campaignsDataAnalyticsMock } from "resources/constant";
import TableHeading from "components/Typography/TableHeading";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import TableHeader from "../Analytics/Campaigns/components/TableHeader";
import GraphSection from "../Analytics/Campaigns/components/GraphSection";
import { compareDateStringsUsingMomemt } from "resources/utils/sorter";
import { checkEmptyState } from "resources/helpers/checkEmptyState";
import EmptyTableData from "../Analytics/Campaigns/components/EmptyComponentTable";
import VideoModal from "components/VideoModal";
import LockedModal from "components/Modal/LockedModal";

export default function CampaignsAnalyticsLocked() {
	const theme = useTheme();
	const [showVideoModal, setShowVideoModal] = useState(false);
	const mode = theme?.palette?.mode;
	const [tableData, setTableData] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [mainData, setMainData] = useState();
	const lightMode = mode === "light" ? true : false;
	const [emptyState, setEmptyState] = useState(false);

	const iconStyle = {
		transform: `rotate(${90}deg)`,
		transition: "transform 0.3s ease-in-out",
	};

	const columns = [
		{
			dataIndex: "campaignName",
			key: "campaignName",
			title: <TableHeading minWidth={"220px"}>Campaign Name</TableHeading>,
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "sentOn",
			key: "sentOn",
			title: <TableHeading minWidth={"150px"}>Sent On</TableHeading>,
			sortable: false,
			showSorterTooltip: false,
			sorter: (a, b) => compareDateStringsUsingMomemt(a?.sentOn, b?.sentOn),
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
					sx={{
						maxWidth: "110px",
					}}
				>
					{params}
				</Typography>
			),
		},
		{
			key: "status",
			dataIndex: "status",
			title: <TableHeading minWidth={"100px"}>Status</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => a?.status.localeCompare(b?.status),
			render: (params) => showStatus(params),
		},
		{
			dataIndex: "sent",
			key: "sent",
			title: <TableHeading minWidth={"100px"}>Sent</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a?.sent) - parseInt(b?.sent),
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					maxWidth={"70px"}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "replyRate",
			key: "replyRate",
			title: <TableHeading minWidth={"110px"}>Reply Rate</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a?.replyRate) - parseInt(b?.replyRate),
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					maxWidth={"70px"}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "ctr",
			key: "ctr",
			title: <TableHeading minWidth={"110px"}>CTR</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a?.ctr) - parseInt(b?.ctr),
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "revenue",
			key: "revenue",
			title: <TableHeading minWidth={"110px"}>Revenue</TableHeading>,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a?.revenue) - parseInt(b?.revenue),
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					${params}
				</Typography>
			),
		},
		{
			dataIndex: "roi",
			key: "roi",
			title: <TableHeading minWidth={"70px"}>ROI</TableHeading>,
			sortable: false,
			showSorterTooltip: false,
			sorter: (a, b) => parseInt(a?.roi) - parseInt(b?.roi),
			render: (params, record) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params} &nbsp;&nbsp;&nbsp;
					<Dropdown
						overlay={<ActionMenu record={record} />}
						placement="bottomRight"
						trigger={["click"]}
					>
						<MoreOutlined style={iconStyle} />
					</Dropdown>
				</Typography>
			),
		},
	];

	const showStatus = (value) => {
		return mapStatus[value];
	};

	const mapStatus = {
		Sent: (
			<span className={`campaigns_status ${lightMode ? "Sent" : "Sent_Dark"}`}>
				Sent
			</span>
		),
		Draft: (
			<span
				className={`campaigns_status ${lightMode ? "Draft" : "Draft_Dark"}`}
			>
				Draft
			</span>
		),
		Scheduled: (
			<span
				className={`campaigns_status ${
					lightMode ? "Scheduled" : "Scheduled_Dark"
				}`}
			>
				Scheduled
			</span>
		),
		Sending: (
			<span
				className={`campaigns_status ${lightMode ? "Sending" : "Sending_Dark"}`}
			>
				Sending
			</span>
		),
		Error: (
			<span
				className={`campaigns_status ${lightMode ? "Error" : "Error_Dark"}`}
			>
				Error
			</span>
		),
	};
	const handleFilterTableData = (type) => {
		if (emptyState) {
			return;
		}
		console.info(type);
		let result;
		let tempTableData = [...campaignsDataAnalyticsMock];
		if (type === "Template") {
			result = tempTableData?.filter((item) => {
				return item?.type === "template";
			});
		} else if (type === "Custom") {
			result = tempTableData?.filter((item) => {
				return item?.type === "custom";
			});
		} else {
			result = [...tempTableData];
		}
		setSearchValue("");
		setMainData([...result]);
	};
	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		let data;
		if (empty) {
			setEmptyState(true);
			data = [];
		}
		if (!empty) {
			data = [...campaignsDataAnalyticsMock];
		}
		setTimeout(() => {
			setMainData([...data]);
		}, 4000);
	}, []);

	useEffect(() => {
		console.log(searchValue, "searchValue");
		const filteredData = mainData?.filter((campaign) => {
			return searchValue
				? campaign.campaignName
						.toLowerCase()
						.includes(searchValue.toLowerCase())
				: true;
		});
		setTableData(filteredData || mainData); // Use mainData if filteredData is empty
	}, [searchValue, mainData]);
	return (
        <Box
        className="disableSection"
        sx={{
            position: "relative",
            height: "100vh",
            WebkitFilter: "blur(4px)",
        }}>
		<Grid container alignItems={"center"}>
			<Grid item xs={12} mb={"21px"}>
				<GraphSection />
			</Grid>
			<Grid item xs={12}>
				<Analytics />
			</Grid>
			<Grid item xs={12} mt={"24px"}>
				<Box
					sx={{
						borderBottom: `1px solid ${theme.palette?.card?.border}`,
						borderBottomLeftRadius: "12px",
						borderBottomRightRadius: "12px",
					}}
				>
					<Antd
						mode={mode}
						rows={tableData}
						columns={columns}
						loading={mainData ? false : true}
						EmptyComponentTable={<EmptyTableData />}
						TableHead={
							<TableHeader
								mode={mode}
								theme={theme}
								emptyState={emptyState}
								searchValue={searchValue}
								setSearchValue={setSearchValue}
								handleFilterTableData={handleFilterTableData}
							/>
						}
					/>
				</Box>
			</Grid>
		</Grid>
        <LockedModal setShowVideoModal={setShowVideoModal} message="Unlock powerful insights into your SMS data." open={true} mode={mode} showWatchVideo={true}/>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={"https://www.youtube.com/watch?v=qTpcfMBgqF0"}
				/>
        </Box>
	);
}

const ActionMenu = ({ record }) => {
	const theme = useTheme();
	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={`1px solid ${theme.palette?.card?.border}`}
			borderRadius={"8px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				<MenuItem key={"1"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme}>Edit</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem key={"2"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme}>Duplicate</MenuTypography>
					</ListItemText>
				</MenuItem>
				{record.status === "Scheduled" && (
					<MenuItem key={"4"} sx={{ py: 1 }}>
						<ListItemText>
							<MenuTypography theme={theme}>Cancel</MenuTypography>
						</ListItemText>
					</MenuItem>
				)}
				<MenuItem key={"3"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme} color="#FF465C">
							Delete
						</MenuTypography>
					</ListItemText>
				</MenuItem>
			</MenuList>
		</Box>
	);
};
const MenuTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			fontSize={"14px"}
			color={theme.palette?.mode === "light" ? "#0A0A18" : "#FFF"}
			lineHeight={"normal"}
			fontWeight={500}
			{...props}
		>
			{children}
		</Typography>
	);
};