/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Typography } from "@mui/material";
import { Popover, createTheme, useMediaQuery } from "@mui/material";

import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { DataContext } from "context/DataContext";
import { CutSVG } from "resources/Icons";
import { Mixpanel } from "utils/mixpanel";
import { PAGES } from "constants/mixPanelEvents";

export default function AlertBanner({ handleRemoveBanner, topText, bottomText, buttonText, buttonAction, buttonName=null }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const { setShowBillingModal } = useContext(DataContext);

	const buttonActionHandler = () => {
		if (buttonAction) {
			buttonAction();
		}
		if (buttonName) {
			Mixpanel.track(PAGES.HOME, buttonName);
		}
	}

	return (
		<Box
			bgcolor={"#FF004C"}
			sx={{ display: "flex", flexDirection: isSmall ? "column" : "row" }}
			py={"12px"}
			alignItems={"center"}
			justifyContent={isSmall ? "center" : "space-between"}
			width={"100%"}
			maxHeight={isSmall ? "unset" : "48px"}
		>
			{/* Left Spacer for Desktop */}
			{!isSmall && (
				<Box width={"250px"} sx={{ display: { sm: "none", md: "flex" } }}></Box>
			)}

			{/* Icon Section */}
			{!isSmall && (
				<Box px={2} mt={"5px"}>
					<img src="/icons/alert-2.svg" alt="Lock Icon" width={"75%"} />
				</Box>
			)}

			{/* Text Section */}
			<Box
				justifyContent={"center"}
				display={"flex"}
				flexDirection={"column"}
				textAlign={isSmall ? "center" : "start"}
				padding={isSmall ? "5px 35px" : "0px"}
				flex={1}
			>
				<Typography fontSize={"12px"} fontWeight={"600"} color={"#FFF"}>
					{topText}
				</Typography>
				<Typography fontSize={"12px"} fontWeight={"400"} color={"#FFF"}>
					{bottomText}
				</Typography>

				{/* Button for Small Screens */}
				{isSmall && buttonText && (
					<Box mt={2} display={"flex"} justifyContent={"space-evenly"}>
						<Button
							onClick={() => buttonActionHandler()}
							sx={{
								padding: "5px 25px",
								background: "#EBECF2",
								width: "91px",
								fontSize: "12px",
								fontWeight: "600",
								color: "#0C84FE",
								marginRight: "10px",
								":focus": {
									backgroundColor: "#EBECF2",
								},
								":hover": {
									backgroundColor: "#d6d6d9",
								},
							}}
						>
							{buttonText}
						</Button>
					</Box>
				)}
			</Box>

			{/* Button for Desktop */}
			{!isSmall && buttonText && (
				<Box
					paddingRight={"16px"}
				>
					<Button
						onClick={() => buttonActionHandler()}
						sx={{
							padding: "5px 25px",
							background: "#EBECF2",
							width: "91px",
							fontSize: "12px",
							fontWeight: "600",
							color: "#0C84FE",
							":focus": {
								backgroundColor: "#EBECF2",
							},
							":hover": {
								backgroundColor: "#d6d6d9",
							},
						}}
					>
						{buttonText}
					</Button>
				</Box>
			)}
		</Box>

	);
}
