import React from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
} from "@mui/material";

export default function ConfirmModal({ open, confirmDisagree, confirmAgree, text, confirmButtonText, cancelButtonText, width }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Modal
			open={open}
			id="confirm-modal"
			aria-labelledby="confirm-modal"
			aria-describedby="confirm-modal"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet} width={width}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					mb={"31px"}
				>
					<Typography
						color={theme.palette?.pageHeading}
						fontSize={"20px"}
						fontWeight={600}
						lineHeight={"normal"}
					>
						{text}
					</Typography>
                    {/* <Box position={'relative'}>

					<img
						src="/icons/cut.svg"
						alt="cut"
						onClick={confirmDisagree}
						id="pointerCursor"
						style={{
							marginTop: "-10px",
                            position:'absolute',
                            top:'-10px',
                            right:'-81px'
						}}
					/>
                    </Box> */}

				</Box>

				<Box display={"flex"} justifyContent={'center'} mb={'9px'}>
                    {/* <Box> */}

					<Button
						sx={{
							padding: "11px 20px",
							border: `1px solid ${theme.palette?.card?.border}`,
							backgroundColor: "transparent",
							marginRight: "8px",
						}}
						variant="outlined"
                        onClick={confirmDisagree}
					>
						<Typography
							fontSize={"14px"}
							fontWeight={"600"}
							color={theme.palette?.pageHeading}
							lineHeight={"normal"}
						>
							{cancelButtonText}
						</Typography>
					</Button>
					<Button
						size={"small"}
						sx={{
							backgroundColor: "#0081FF",
							padding: "11px 30px",
							color: "#FFF",
							":hover": {
								color: mode === "light" ? "#0C0D17" : "#FFF",
							},
						}}
						variant="outlined"
                        onClick={confirmAgree}
					>
						<Typography fontSize={"14px"} fontWeight={"600"}>
							{confirmButtonText}
						</Typography>
					</Button>
                    {/* </Box> */}

				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet, width }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : width ? width : "328px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "30px 20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};


