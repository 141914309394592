/* eslint-disable react/no-unknown-property */
import React from "react";

export default function Location() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			style={{ minWidth: "20px", minHeight: "20px" }}
		>
			<path
				d="M10.0007 10.8332C11.3814 10.8332 12.5007 9.71388 12.5007 8.33317C12.5007 6.95246 11.3814 5.83317 10.0007 5.83317C8.61994 5.83317 7.50065 6.95246 7.50065 8.33317C7.50065 9.71388 8.61994 10.8332 10.0007 10.8332Z"
				stroke="#0081FF"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.0007 18.3332C13.334 14.9998 16.6673 12.0151 16.6673 8.33317C16.6673 4.65127 13.6825 1.6665 10.0007 1.6665C6.31875 1.6665 3.33398 4.65127 3.33398 8.33317C3.33398 12.0151 6.66732 14.9998 10.0007 18.3332Z"
				stroke="#0081FF"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
