import {
	Avatar,
	Box,
	ListItemText,
	MenuItem,
	MenuList,
	Typography,
	useTheme,
} from "@mui/material";
import { Dropdown } from "antd";
import SettingsTable from "components/Table/SettingsTable";
import TableHeading from "components/Typography/TableHeading";
import React from "react";

export default function User() {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const columns = [
		{
			dataIndex: "name",
			key: "name",
			title: <TableHeading minWidth={"150px"}>Name</TableHeading>,
			sortable: false,
			width: "48%",
			render: (params, record) => (
				<Box display={"flex"} gap={"14px"}>
					<Box>
						<Avatar alt="Remy Sharp" src={record?.img} />
					</Box>
					<Box>
						<Typography
							fontSize={14}
							fontWeight={500}
							color={lightMode ? "#000D31" : "#FFF"}
							lineHeight={"normal"}
							mb={"10px"}
						>
							{params}
						</Typography>
						<Typography
							fontSize={12}
							fontWeight={500}
							color={"#808698"}
							lineHeight={"normal"}
						>
							{record?.type}
						</Typography>
					</Box>
				</Box>
			),
		},
		{
			dataIndex: "role",
			key: "role",
			title: <TableHeading minWidth={"200px"}>Roles</TableHeading>,
			sortable: false,
			width: "48%",
			render: (params) => (
				<span
					className={`users-roles-${lightMode ? params : params + "-dark"}`}
					style={{
						padding: "4px 14px",
						borderRadius: "4px",
						color: lightMode ? "#000D31" : "#FFF",
						fontWeight: "500",
						fontSize: "12px",
					}}
				>
					{params}
				</span>
			),
		},

		{
			key: "actions",
			dataIndex: "actions",
			title: (
				<TableHeading minWidth={"65px"} maxWidth="65px">
					Actions
				</TableHeading>
			),
			width: "65px",
			render: () => (
				<Box display={"flex"} justifyContent={"center"} mr={"10px"}>
					{/* <Button
						
						sx={{
							padding: "7px 18px",
							border: `1px solid ${theme.palette?.card?.border}`,
							backgroundColor: "transparent",
							marginRight: "20px",
						}}
						variant="outlined"
					>
						<Typography
							fontSize={"14px"}
							fontWeight={"600"}
							color={theme.palette?.pageHeading}
							lineHeight={"normal"}
						>
							View
						</Typography>
					</Button> */}
					<Dropdown
						overlay={<ActionMenu />}
						placement="bottomRight"
						trigger={["click"]}
					>
						<img src="/icons/more.svg" id="pointerCursor" alt="more" />
					</Dropdown>
				</Box>
			),
		},
	];

	const users = [
		{
			name: "blacklabel.llc@gmail.com",
			type: "Shopify Account",
			role: "Admin",
			img: "",
		},
		{
			name: "brady@txtcartapp.com",
			type: false,
			role: "Marketer",
			img: "",
		},
		{
			name: "roblox-bracket-racing,myshopify.com",
			type: "Shopify Account",
			role: "Admin",
			img: "",
		},
	];
	return (
		<Box
			// p={"20px"}
			sx={{
				borderRadius: "12px",
				border: `1px solid ${theme?.palette?.card?.border}`,
				backgroundColor: theme?.palette?.card?.background,
			}}
		>
			<Typography
				p={"20px 0px 0px 20px"}
				fontSize={"16px"}
				fontWeight={600}
				color={theme?.palette?.pageHeading}
			>
				Users
			</Typography>
			<SettingsTable columns={columns} rows={users} />
		</Box>
	);
}

const ActionMenu = () => {
	const theme = useTheme();
	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={`1px solid ${theme.palette?.card?.border}`}
			borderRadius={"8px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				<MenuItem key={"1"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme}>Edit</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem key={"3"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme} color="#FF465C">
							Delete
						</MenuTypography>
					</ListItemText>
				</MenuItem>
			</MenuList>
		</Box>
	);
};
const MenuTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			fontSize={"14px"}
			color={theme.palette?.mode === "light" ? "#0A0A18" : "#FFF"}
			lineHeight={"normal"}
			fontWeight={500}
			{...props}
		>
			{children}
		</Typography>
	);
};
