/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
} from "@mui/material";
import { planMock } from "resources/constant";
import OnboardingToolTip from "components/Tooltip/OnboardingToolTip";
import { useNavigate } from "react-router-dom";
import { txtCartFetch } from "utilifyFunctions";

export default function SelectPlanV3() {
	const navigate = useNavigate();
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const planData = [
		{
			name: "Basic",
			desc: "For new stores looking for a risk-free option to SMS",
			priceMonthly: "$0",
			priceAnnually: "$0",
			discount: "$290 billed Annually",
			features: {
				heading: "EVERYTHING IN BASIC PLUS",
				keyFeatures: ["SMS Cart Recovery", "Customer Chat Support"],
			},
		},
		{
			...planMock[0],
		},
	];
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [period, setPlanPeriod] = useState("Monthly");
	const [recommended, setRecommended] = useState("Starter");


	const handleSelect = async function(skuId){
		console.log(skuId);
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/billing/change?skuId=starter_29_15_02&cycle=monthly");
			console.log(response);
			if(response){
				window.location.replace(response.redirect_url);
			}
	}

	const Icons = {
		Starter: "💰",
		Basic: "💡",
	};
	const mapDescription = {
		Starter: "+15% of cart recovery",
		Basic: "+20% of cart recovery",
	};
	const mapPlanDesc = {
		Starter: "Free Plan Includes",
		Basic: "Free Plan Includes:",
		Pro: "Everything in Growth plus:",
	};

	return (
		<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
			<Box>
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					mb={"20px"}
					mt={"14px"}
				>
					<img src="/img/onboarding/emoji.svg" alt="emoji"/>
				</Box>
				<Box
					textAlign={"center"}
					mb={"40px"}
					display={"flex"}
					justifyContent={"center"}
				>
					<Box>
						<Typography
							fontSize={20}
							fontWeight={600}
							lineHeight={"26px"}
							color={"#FFF"}
							mb={"11px"}
						>
							You’re Almost Ready to Go!
						</Typography>
						<Typography
							fontSize={16}
							fontWeight={400}
							lineHeight={"20px"}
							color={"#969AA5"}
							maxWidth={"425px"}
						>
							Based on your order volume, we recommend{" "}
							<span style={{ color: "#FFF",fontWeight:'600' }}>Starter</span>. You will be billed{" "}
							<span style={{ color: "#FFF",fontWeight:'600' }}>$29/mo + 15%</span> of cart
							recovery revenue generated by TxtCart.
						</Typography>
					</Box>
				</Box>
				<Box display={"flex"} justifyContent={"center"} mb={"20px"}>
					<Box>
						<Typography
							fontSize={"16px"}
							fontWeight={"400"}
							color={"#969AA5"}
							lineHeight={"20px"}
							mb={"12px"}
						>
							<img
								src="/arrow/tick.svg"
								style={{
									marginRight: "8px",
								}}
								alt="tick"
							/>
							No Commitment, Cancel Anytime
						</Typography>
						<Typography
							fontSize={"16px"}
							fontWeight={"400"}
							color={"#969AA5"}
							lineHeight={"20px"}
							mb={"12px"}
						>
							<img
								src="/arrow/tick.svg"
								style={{
									marginRight: "10px",
								}}
								alt="tick"
							/>
							Simple and Transparent Pricing
						</Typography>
						<Typography
							fontSize={"16px"}
							fontWeight={"400"}
							color={"#969AA5"}
							lineHeight={"20px"}
							mb={"37px"}
						>
							<img
								src="/arrow/tick.svg"
								style={{
									marginRight: "10px",
								}}
								alt="tick"
							/>
							Secure Payments thought Shopify
						</Typography>
						<Button
							fullWidth
							// maxWidth="283px"
							sx={{
								padding: "11px 18px",
								border: "1px solid #0081FF",
								backgroundColor: "#0081FF",
								height: "40px",
								color: "#FFF",
								":hover": {
									color: "#0081FF",
								},
							}}
							onClick={() => handleSelect()}
							variant="outlined"
						>
							<Typography fontSize={"14px"} fontWeight={"600"}>
								Start Free Trial
							</Typography>
						</Button>
					</Box>
				</Box>
				

				<Box display={"flex"} justifyContent={"center"}>
					<Typography
						fontSize={16}
						fontWeight={400}
						lineHeight={"20px"}
						color={"#969AA5"}
					>
						By selecting these plans, you agree to our{" "}
						<span style={{ color: "#FFF" }} onClick={() => {
							window.open(
								"https://www.help.txtcartapp.com/en/articles/8001573-sms-terms-of-service-template",
								"_blank"
							);
						}}>Terms of Service.</span>
					</Typography>
				</Box>
			</Box>
		</MainContainer>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	return (
		<Box
			id="newmodal"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				padding: "70px 10px",
				transform: "translate(-50%, -50%)",
				width: belowTablet ? "90%" : "845px",
				backgroundColor: lightMode ? "#041328" : "#041328",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},
				maxHeight: "90vh",
				overflow: "hidden",
				overflowY: "scroll",
				"::-webkit-scrollbar": {
					display: "none",
				},
				// height: "935px",
			}}
		>
			{children}
		</Box>
	);
};

const RecommendedTag = () => {
	return (
		<Box
			sx={{
				background: "#7CFAB8",
				width: "159px",
				height: "21px",
				borderRadius: "100px",
			}}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			gap={"7px"}
		>
			<Typography
				fontSize={"12px"}
				fontWeight={600}
				lineHeight={"normal"}
				color={"#18113C"}
				mt={"-2px"}
			>
				Recommended for you
			</Typography>
			<OnboardingToolTip text="" />
		</Box>
	);
};
