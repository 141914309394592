import { useState } from "react";
import {
    Modal,
    Box,
    Typography,
    useMediaQuery,
    useTheme,
    Select,
    MenuItem,
    Button,
} from "@mui/material";
import { TextInput } from "components/Inputs";
import TextArea from "antd/es/input/TextArea";

export default function QuickReplyModal({ open, handleClose, newReply, setNewReply, onSave, onDelete, openDropdown }) {
    const theme = useTheme();
    const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
    const [error, setError] = useState("");

    const handleInputChange = (key, value) => {
        setNewReply((prev) => ({ ...prev, [key]: value }));
    };

    return (
        <Modal
            open={open}
            id="create-reply"
            aria-labelledby="create-reply"
            aria-describedby="create-reply"
        >
            <MainContainer belowTablet={belowTablet}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    mb={3}
                >
                    <Typography
                        color={theme.palette?.pageHeading}
                        fontSize={"20px"}
                        fontWeight={600}
                        lineHeight={"normal"}
                    >
                        {newReply.id ? "Edit the quick reply" : "Create a new quick reply"}
                    </Typography>

                    <img
                        src="/icons/cut.svg"
                        alt="close"
                        onClick={handleClose}
                        id="pointerCursor"
                        style={{
                            marginTop: "-10px",
                        }}
                    />
                </Box>
                <Box>
                    <Typography
                        color={theme.palette?.pageHeading}
                        fontSize={"14px"}
                        fontWeight={500}
                        lineHeight={"normal"}
                        mb={"8px"}
                    >
                        Reply Name
                    </Typography>
                    <TextInput
                        background={theme.palette?.background}
                        color={theme.palette?.pageHeading}
                        value={newReply.name}
                        onChange={(e) => handleInputChange("name", e.target.value)}
                        placeholder="Enter reply name"
                    />
                    <Typography
                        color={theme.palette?.pageHeading}
                        fontSize={"14px"}
                        fontWeight={500}
                        lineHeight={"normal"}
                        mb={"8px"}
                        mt={"16px"}
                    >
                        Add Message
                    </Typography>
                    <TextArea
                        value={newReply.message}
                        onChange={(e) => handleInputChange("message", e.target.value)}
                        placeholder="Type your message..."
                        autoSize={{ minRows: 5, maxRows: 5 }}
                    />
                    <Typography
                        color={theme.palette?.pageHeading}
                        fontSize={"14px"}
                        fontWeight={500}
                        lineHeight={"normal"}
                        mb={"8px"}
                        mt={"16px"}
                    >
                        Available for
                    </Typography>
                    <Select
                        value={newReply.availableFor}
                        onChange={(e) => handleInputChange("availableFor", e.target.value)}
                        sx={{ "& .MuiSvgIcon-root": { color: "#C1C1C1" } }}
                        style={{ width: "100%", height: "40px" }}
                    >
                        <MenuItem value="Everyone">Everyone</MenuItem>
                    </Select>
                </Box>
                <Box display="flex" justifyContent="space-between" mt="16px">
                    <Box>
                        {newReply.id && (
                            <Button
                                size="small"
                                sx={{
                                    padding: "11px 30px",
                                    height: "40px",
                                    border: `1px solid rgb(255, 0, 76)`,
                                    marginRight: "10px",
                                    color: "rgb(255, 0, 76)",
                                    backgroundColor: "rgba(255, 0, 76, 0.1)",
                                }}
                                variant="outlined"
                                onClick={async () => {
                                    const hasDeleted = await onDelete(newReply.id);
                                    if (hasDeleted) handleClose();
                                }}
                            >
                                Delete
                            </Button>
                        )}
                    </Box>
                    <Box display="flex">
                        <Button
                            size="small"
                            sx={{
                                padding: "11px 30px",
                                height: "40px",
                                border: `1px solid ${theme?.palette?.card?.border}`,
                                color: theme.palette?.pageHeading,
                                marginRight: "10px",
                                backgroundColor: theme.palette?.card?.background,
                            }}
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            sx={{
                                padding: "11px 30px",
                                height: "40px",
                                color: "#fff",
                                backgroundColor: "#0C84FE",
                            }}
                            onClick={async () => {
                                const isSaved = await onSave();
                                if (!isSaved) {
                                    setError(true);
                                    setTimeout(() => {
                                        setError(false);
                                    }, 3000);
                                } else {
                                    handleClose();
                                    openDropdown();
                                }
                            }}
                        >
                            {newReply.id ? "Update" : "Save"}
                        </Button>
                    </Box>
                </Box>
                {error && (
                    <Typography
                        color="red"
                        fontSize={"14px"}
                        fontWeight={"300px"}
                        sx={{
                            position: "absolute",
                            bottom: "10px",
                            left: "20px",
                        }}
                    >
                        Looks like you missed a few spots!
                    </Typography>
                )}
            </MainContainer>
        </Modal>
    );
}

const MainContainer = ({ children, belowTablet }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: belowTablet ? "90%" : "535px",
                backgroundColor: theme?.palette?.card?.background,
                padding: "20px 20px 25px 20px",
                borderRadius: "12px",
                outline: "none",
                border: `1px solid ${theme?.palette?.card?.border}`,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: 24,
                overflowY: "auto",
                height: "auto",
                minHeight: "475px",
            }}
        >
            {children}
        </Box>
    );
};
