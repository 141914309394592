import React from "react";
import { Box, Typography } from "@mui/material";
import { InboxSVG } from "resources/Icons/Inbox/Index";

export default function InboxEmptyState({ isMobile }) {
	return (
		<Box
			justifyContent="center"
			alignItems="center"
			height={`calc(100vh - 240px)`}
			textAlign="center"
			width={isMobile ? "100%" : "50%"}
			flex={1}
			display={isMobile ? "flex" : "flex"}
		>
			<Box>
				<Box display="flex" justifyContent="center" alignItems="center">
					<Box
						sx={{
							width: "88px",
							height: "88px",
							background: "#E6F3FF",
							borderRadius: "100px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						mb="16px"
					>
						<InboxSVG />
					</Box>
				</Box>
				<Box mb="12px">
					<Typography fontSize={24} fontWeight={600}>
						Inbox is empty
					</Typography>
				</Box>
				<Box mb="22px">
					<Typography fontSize={14} fontWeight={500} lineHeight="20px" sx={{ opacity: 0.5 }}>
						Nothing in your inbox yet, check back once customers reply to our texts
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
