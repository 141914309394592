import {
	Box,
	Typography,
	MenuItem,
	MenuList,
	ListItemText,
	Button,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import Antd from "components/Table/Antd";
import TableHeading from "components/Typography/TableHeading";
import React, { useEffect, useState } from "react";
import { Dropdown } from "antd";
import SegmentModal from "components/Modal/Segments";
import { ListsMock } from "resources/constant";
import { checkEmptyState } from "resources/helpers/checkEmptyState";
import EmptyTableData from "./EmptyComponentTable";

export default function TableSecton({ handleOpenModal }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const [open, setOpen] = useState(false);
	const [tableData, setTableData] = useState();

	const columns = [
		{
			dataIndex: "name",
			key: "name",
			title: <TableHeading minWidth={"150px"}>Name</TableHeading>,
			sortable: false,
			width: "22%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "type",
			key: "type",
			title: <TableHeading minWidth={"200px"}>Type</TableHeading>,
			sortable: false,
			width: "29%",
			render: () => (
				<Box display={"flex"} alignItems={"center"} gap={"12px"}>
					<span
						style={{
							backgroundColor: lightMode ? "#F0E9FF" : "#211A3A",
							padding: "4px 14px",
							borderRadius: "4px",
							color: lightMode ? "#622BCA" : "#FFF",
							fontWeight: "500",
							fontSize: "12px",
						}}
					>
						Custom List
					</span>
					{/* <Typography
						id="pointerCursor"
						fontSize={12}
						fontWeight={600}
						color={lightMode ? "#0C84FE" : "#0C84FE"}
						lineHeight={"normal"}
						onClick={() => {
							// setOpen(true);
						}}
					>
						View rules
					</Typography> */}
				</Box>
			),
		},
		{
			dataIndex: "contact",
			key: "contact",
			title: <TableHeading minWidth={"100px"}>Contacts</TableHeading>,
			sortable: false,
			width: "22%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},
		{
			dataIndex: "lastUpdated",
			key: "lastUpdated",
			title: <TableHeading minWidth={"250px"}>Last Updated</TableHeading>,
			sortable: false,
			width: "22%",
			render: (params) => (
				<Typography
					fontSize={14}
					fontWeight={500}
					color={lightMode ? "#000D31" : "#FFF"}
				>
					{params}
				</Typography>
			),
		},

		{
			key: "actions",
			dataIndex: "actions",
			title: (
				<TableHeading minWidth={"65px"} maxWidth="65px">
					Actions
				</TableHeading>
			),
			width: "65px",
			render: () => (
				<Box display={"flex"} justifyContent={"center"} mr={"10px"}>
					<Dropdown
						overlay={<ActionMenu />}
						placement="bottomRight"
						trigger={["click"]}
					>
						<Button
							height="10px"
							disableRipple
							sx={{
								height: "25px",
								":hover": {
									background: "transparent",
								},
							}}
						>
							<img src="/icons/more.svg" id="pointerCursor" alt="more" />
						</Button>
					</Dropdown>
				</Box>
			),
		},
	];

	useEffect(() => {
		const empty = checkEmptyState(window.location?.href);
		let data;
		if (!empty) {
			data = [...ListsMock];
		} else {
			data = [];
		}
		setTimeout(() => {
			setTableData([...data]);
		}, 5000);
	}, []);
	return (
		<Box>
			<Antd
				mode={mode}
				columns={columns}
				rows={tableData}
				loading={tableData ? false : true}
				EmptyComponentTable={
					<EmptyTableData handleOpenModal={handleOpenModal} />
				}
			/>
			<SegmentModal
				open={open}
				mode={mode}
				handleClose={() => setOpen(false)}
			/>
		</Box>
	);
}

const ActionMenu = () => {
	const theme = useTheme();
	return (
		<Box
			bgcolor={theme.palette?.card?.background}
			border={`1px solid ${theme.palette?.card?.border}`}
			borderRadius={"8px"}
		>
			<MenuList disablePadding sx={{ py: 0 }}>
				<MenuItem key={"1"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme}>Edit</MenuTypography>
					</ListItemText>
				</MenuItem>
				<MenuItem key={"3"} sx={{ py: 1 }}>
					<ListItemText>
						<MenuTypography theme={theme} color="#FF465C">
							Delete
						</MenuTypography>
					</ListItemText>
				</MenuItem>
			</MenuList>
		</Box>
	);
};
const MenuTypography = ({ children, theme, ...props }) => {
	return (
		<Typography
			fontSize={"14px"}
			color={theme.palette?.mode === "light" ? "#0A0A18" : "#FFF"}
			lineHeight={"normal"}
			fontWeight={500}
			{...props}
		>
			{children}
		</Typography>
	);
};