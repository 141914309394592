import React from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	Select,
	MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextInput } from "components/Inputs";

export default function AddPersonaModal({ open, handleClose }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode === "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Modal
			open={open}
			id="add-user"
			aria-labelledby="add-user"
			aria-describedby="add-user"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box
					p={"20px"}
					sx={{
						borderBottom: `1px solid ${theme?.palette?.card?.border}`,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						mb={"8px"}
					>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"20px"}
							fontWeight={600}
							lineHeight={"normal"}
						>
							Create Custom Persona
						</Typography>
						<img
							src="/icons/cut.svg"
							alt="cut"
							onClick={handleClose}
							id="pointerCursor"
							style={{
								marginTop: "-10px",
							}}
						/>
					</Box>
					<Box>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"14px"}
							fontWeight={500}
							lineHeight={"20px"}
							sx={{
								opacity: "0.5",
							}}
						>
							Give your brand&apos;s persona a custom name when texting customers
						</Typography>
					</Box>
				</Box>
				<Box p={"20px"}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: belowTablet ? "column" : "row",
							alignItems: "center",
							gap: "12px",
						}}
						mb={"12px"}
					>
						<Box width={"100%"}>
							<CustomTypography theme={theme}>Persona Name</CustomTypography>
							<TextInput placeholder="" />
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: belowTablet ? "column" : "row",
							alignItems: "center",
							gap: "12px",
						}}
						mb={2}
					>
						<Box width={"100%"}>
							<CustomTypography theme={theme}>Tone</CustomTypography>
							<Select
								sx={{ "& .MuiSvgIcon-root": { color: "#C1C1C1" } }}
								IconComponent={ExpandMoreIcon}
								style={{ width: "100%", height: "40px" }}
								onChange={(e) => {
									console.info(e.target.value);
								}}
							>
								<CustomMenuItem value={"Male"}>Male</CustomMenuItem>
								<CustomMenuItem value={"Female"}>Female</CustomMenuItem>
								<CustomMenuItem value={"Neutral"}>Neutral</CustomMenuItem>
							</Select>
						</Box>
					</Box>

					<Box display={"flex"} justifyContent={"end"}>
						<Button
							sx={{
								padding: "11px 20px",
								border: `1px solid ${theme.palette?.card?.border}`,
								backgroundColor: "transparent",
								marginRight: "20px",
								height: "40px",
							}}
							variant="outlined"
						>
							<Typography
								fontSize={"14px"}
								fontWeight={"600"}
								color={theme.palette?.pageHeading}
								lineHeight={"normal"}
							>
								Cancel
							</Typography>
						</Button>
						<Button
							size={"small"}
							sx={{
								backgroundColor: "#0081FF",
								padding: "11px 20px",
								color: "#FFF",
								height: "40px",
								":hover": {
									color: mode === "light" ? "#0C0D17" : "#FFF",
								},
							}}
							variant="outlined"
						>
							<Typography fontSize={"14px"} fontWeight={"600"}>
								Create
							</Typography>
						</Button>
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}
const CustomMenuItem = ({ children, ...props }) => {
	const theme = useTheme();
	return (
		<MenuItem
			sx={{
				background: theme?.palette?.card?.background,
			}}
			{...props}
		>
			{children}
		</MenuItem>
	);
};

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "568px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "0px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				maxHeight: "90vh",
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};

const CustomTypography = ({ children }) => {
	return (
		<Typography
			color={"#808698"}
			fontSize={"14px"}
			fontWeight={500}
			lineHeight={"normal"}
			mb={1}
		>
			{children}
		</Typography>
	);
};
