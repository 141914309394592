import { Button, Typography, useTheme } from "@mui/material";
import React from "react";

export default function UpgradeButton({children,...props}) {
    const theme=useTheme()
    const {mode}=theme.palette
	return (
		<Button
			size={"small"}
			sx={{
				padding: "11px 30px",
				border: mode === "light" ? "1px solid #0081FF" : "1px solid #0081FF",
				// color: mode === "light" ? "#000D31" : "#FFF",
                backgroundColor:'#0081FF',
				color:'#FFF',
				"&:hover": {
					color: mode === "light" ? "#0081FF" : "#0081FF",
				},
			}}
			variant="outlined"
			{...props}
		>
			<Typography fontSize={"14px"} fontWeight={"600"} lineHeight={'normal'}>
				{children}
			</Typography>
		</Button>
	);
}
